import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "antd/lib/form/Form";
import { useTranslation } from "react-i18next";

import { ConfirmActionModal, Modal } from "@bbdevcrew/bb_ui_kit_fe";
import CustomPromptsList from "./CustomPromptsList";
import CustomPromptsForm from "./CustomPromptsForm";

import {
  createPromptAction,
  deletePromptAction,
  getPromptsAction,
  getPromptByIdAction,
  updatePromptAction,
  resetStateAction,
  getPromptByIdResetAction,
} from "@store/aiHighlightsPrompts/actions";
import {
  aiHighlightPromptsSelector,
  aiHighlightCurrentPromptSelector,
  aiHighlightPromptByIdLoadingSelector,
  isSavingPromptSelector,
  savedPromptSelector,
} from "@store/aiHighlightsPrompts/selectors";

import { ICustomPromptsProps } from "./CustomPrompts.types";
import { IAIHighlightPrompt } from "@store/aiHighlightsPrompts/types";
import { AIInsightsPromptType } from "@store/aiInsights/types";

export const CustomPrompts: React.FC<ICustomPromptsProps> = ({ children, onSelectPrompt }) => {
  const dispatch = useDispatch();
  const [form] = useForm<IAIHighlightPrompt>();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [view, setView] = useState<"list" | "form">("list");
  const [deletePromptModalOpen, setDeletePromptModalOpen] = useState(false);
  const [currentPromptId, setCurrentPromptId] = useState<string>();

  const prompts = useSelector(aiHighlightPromptsSelector);
  const currentPrompt = useSelector(aiHighlightCurrentPromptSelector);
  const isLoadingPrompt = useSelector(aiHighlightPromptByIdLoadingSelector);
  const isSavingPrompt = useSelector(isSavingPromptSelector);
  const savedPrompt = useSelector(savedPromptSelector);

  const isCreatingPrompt = currentPromptId?.endsWith("-default");

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleAddClick = (promptType: AIInsightsPromptType) => {
    form.resetFields();
    setCurrentPromptId(`${promptType}-default`);
    dispatch(getPromptByIdAction(`${promptType}-default`));
    setView("form");
  };

  const handleEditClick = (prompt: IAIHighlightPrompt) => {
    form.resetFields();
    setCurrentPromptId(prompt.id);
    dispatch(getPromptByIdAction(prompt.id));
    setView("form");
  };

  const handleDeleteClick = (prompt: IAIHighlightPrompt) => {
    setCurrentPromptId(prompt.id);
    setDeletePromptModalOpen(true);
  };

  const handleDeleteConfirm = () => {
    if (currentPromptId) dispatch(deletePromptAction(currentPromptId));
    setDeletePromptModalOpen(false);
    setCurrentPromptId(undefined);
  };

  const handleSelectClick = (prompt: IAIHighlightPrompt) => {
    onSelectPrompt?.(prompt);
    setOpen(false);
  };

  const handleOk = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    form.submit();
  };

  const handleCancel = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (view === "form") {
      setView("list");
      form.resetFields();
      setCurrentPromptId(undefined);
    } else {
      setOpen(false);
    }
  };

  const handleFormFinish = (values: IAIHighlightPrompt) => {
    if (values.id) {
      dispatch(updatePromptAction(values));
    } else {
      dispatch(createPromptAction(values));
    }
  };

  useEffect(() => {
    if (currentPromptId && currentPrompt) {
      const { default_for, name, id, ...formValues } = currentPrompt;
      form.setFieldsValue({
        ...formValues,
        default_for: [],
      });
      if (!isCreatingPrompt) {
        form.setFieldsValue({
          name,
          id,
          default_for: default_for || [],
        });
      }
    }
  }, [currentPromptId, currentPrompt, form, isCreatingPrompt]);

  useEffect(() => {
    if (open) {
      dispatch(getPromptsAction());
      setView("list");
    }
  }, [open, dispatch]);

  useEffect(() => {
    if (savedPrompt) {
      setView("list");
      form.resetFields();
      setCurrentPromptId(undefined);
      dispatch(resetStateAction());
    }
  }, [savedPrompt, form, dispatch]);

  useEffect(() => {
    if (!currentPromptId && view === "list") {
      dispatch(getPromptByIdResetAction());
      dispatch(getPromptsAction());
    }
  }, [currentPromptId, view, dispatch]);

  return (
    <>
      {children(handleOpen)}
      <Modal
        width={600}
        onOk={handleOk}
        onCancel={handleCancel}
        title={
          view === "form"
            ? isCreatingPrompt
              ? t("components:customPrompts:labels:createPrompt")
              : t("components:customPrompts:labels:editPrompt")
            : undefined
        }
        hideHeader={view === "list"}
        hideFooter={view === "list"}
        hideCloseIcon
        noPadding
        open={open}
        cancelButtonProps={{ disabled: isSavingPrompt }}
        okButtonProps={{ loading: isSavingPrompt, disabled: isSavingPrompt }}
        confirmLabel={t("generic:save")}
        cancelLabel={t("generic:discard")}
      >
        {view === "list" && (
          <CustomPromptsList
            prompts={prompts}
            onAddClick={handleAddClick}
            onEditClick={handleEditClick}
            onDeleteClick={handleDeleteClick}
            onSelectClick={handleSelectClick}
          />
        )}
        {view === "form" && (
          <CustomPromptsForm form={form} loading={isLoadingPrompt} onFinish={handleFormFinish} />
        )}
      </Modal>
      <ConfirmActionModal
        open={deletePromptModalOpen}
        onCancel={() => {
          setDeletePromptModalOpen(false);
          setCurrentPromptId(undefined);
        }}
        onOk={handleDeleteConfirm}
        title={t("components:customPrompts:labels:deletePrompt")}
        message={t("components:customPrompts:labels:confirmDelete")}
      />
    </>
  );
};
