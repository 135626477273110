import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect, useCallback } from "react";

import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import AINLPTaggingModal from "./AINLPModal/AINLPModal";
import TaggingFlowSelectionStep from "./StepTaggingFlowSelection";

import s from "./WorkflowCreationModal.module.less";

import { meSelector } from "@store/me/selectors";
import { postWorkflowsAIModelsAction } from "@store/nlpWorkflows/actions";

import {
  WIDE_WORKFLOW_MODAL_WIDTH,
  MEDIUM_WORKFLOW_MODAL_WIDTH,
  DEFAULT_WORKFLOW_MODAL_WIDTH,
} from "../WorkflowsContent";
import { IWorkflowItem, WorkflowItemCategoryType } from "@store/workflows/types";
import { IWorkflowAIModelPayload } from "@store/nlpWorkflows/types";
import { IWorkflowCreationModalProps } from "./WorkflowCreationModal.types";

const WorkflowCreationModal: React.FC<IWorkflowCreationModalProps> = ({
  item,
  workflowType,
  setWorkflowCreationModalWidth,
}) => {
  const dispatch = useDispatch();

  const [currentStep, setCurrentStep] = useState<number | null>(0);
  const [formData, setFormData] = useState<IWorkflowItem>();
  const [showAINLPTaggingStep, setShowAINLPTaggingStep] = useState(false);
  const [showTaggingFlow, setShowTaggingFlow] = useState(false);
  const [isTaggingFlowEditMode, setIsTaggingFlowEditMode] = useState(false);
  const [selectedTaggingFlow, setSelectedTaggingFlow] = useState<string>("default");
  const [selectedWorkflowType, setSelectedWorkflowType] = useState<
    WorkflowItemCategoryType | undefined
  >(workflowType);
  const [workflowId, setWorkflowId] = useState<string | undefined>();

  const me = useSelector(meSelector);

  const isBBBackofficeUser = me?.is_bb_backoffice_user || me?.is_bb_user;

  const postWorkflowsAIModels = useCallback(
    (payload: IWorkflowAIModelPayload) => dispatch(postWorkflowsAIModelsAction(payload)),
    [dispatch],
  );

  useEffect(() => {
    if (showTaggingFlow && !item)
      postWorkflowsAIModels({
        step: "start",
      });
    // eslint-disable-next-line
  }, [showTaggingFlow, postWorkflowsAIModels]);

  useEffect(() => {
    if (currentStep === 0 && item) {
      if (item.category === "nlp_tag") {
        setShowTaggingFlow(true);
        postWorkflowsAIModels({
          existing_workflow_id: item.id,
          step: "start",
        });
        setIsTaggingFlowEditMode(true);
      } else setCurrentStep(1);
    } else if (currentStep === 0) {
      setFormData(undefined);
    }
  }, [item, currentStep, postWorkflowsAIModels]);

  useEffect(() => {
    setWorkflowCreationModalWidth(
      showTaggingFlow
        ? WIDE_WORKFLOW_MODAL_WIDTH
        : showAINLPTaggingStep
          ? MEDIUM_WORKFLOW_MODAL_WIDTH
          : DEFAULT_WORKFLOW_MODAL_WIDTH,
    );
  }, [showAINLPTaggingStep, showTaggingFlow, setWorkflowCreationModalWidth]);

  const onCreateWorkflowClick = () => {
    setShowAINLPTaggingStep(false);
    if (selectedTaggingFlow === "default") setCurrentStep(1);
    if (selectedTaggingFlow === "ai") setShowTaggingFlow(true);
  };

  const onGoBackClick = () => {
    if (selectedWorkflowType === "tag") {
      setShowTaggingFlow(false);
      setCurrentStep(null);
      if (isBBBackofficeUser) setShowAINLPTaggingStep(true);
    } else {
      setCurrentStep(0);
    }
  };

  return (
    <div className={s.bbWorkflowCreationModal} data-cy="workflow-creation-wrapper">
      {!item && currentStep === 0 && (
        <Step1
          setCurrentStep={setCurrentStep}
          selectedWorkflowType={selectedWorkflowType}
          setSelectedWorkflowType={setSelectedWorkflowType}
          setShowAINLPTaggingStep={setShowAINLPTaggingStep}
        />
      )}
      {item && currentStep === 1 ? (
        <Step2
          setCurrentStep={setCurrentStep}
          item={item}
          workflowType={item.category}
          setFormData={setFormData}
          savedFormData={formData}
          onGoBackClick={onGoBackClick}
        />
      ) : currentStep === 1 && selectedWorkflowType ? (
        <Step2
          setCurrentStep={setCurrentStep}
          item={item}
          workflowType={selectedWorkflowType}
          setFormData={setFormData}
          savedFormData={formData}
          onGoBackClick={onGoBackClick}
        />
      ) : null}
      {currentStep === 2 && formData && (
        <Step3 setCurrentStep={setCurrentStep} workflowData={formData} />
      )}

      {isBBBackofficeUser && showAINLPTaggingStep && (
        <TaggingFlowSelectionStep
          selectedTaggingFlow={selectedTaggingFlow}
          onCreateWorkflowClick={onCreateWorkflowClick}
          setSelectedTaggingFlow={setSelectedTaggingFlow}
        />
      )}

      {isBBBackofficeUser && showTaggingFlow && (
        <AINLPTaggingModal
          workflowId={workflowId}
          setWorkflowId={setWorkflowId}
          isEditMode={isTaggingFlowEditMode}
        />
      )}
    </div>
  );
};

export default WorkflowCreationModal;
