import React, { Fragment } from "react";
import classNames from "classnames/bind";

import { Step } from "./Step";

import s from "./Stepper.module.less";

import { defaultConfig, simpleConfig } from "./Stepper.helpers";
import { IStepperConfig, ITStep, StepStatusType } from "./Stepper.types";

interface IStepperProps {
  type?: "default" | "simple";
  size?: "sm" | "md";
  current: number;
  steps: ITStep[];
  config?: IStepperConfig;
  onStepClick?: (step: number) => void;
}

export const Stepper: React.FC<IStepperProps> = ({
  type = "default",
  size = "md",
  current,
  steps,
  config = type == "simple" ? simpleConfig : defaultConfig,
  onStepClick,
}) => {
  const getStepStatus = (stepIndex: number): StepStatusType => {
    if (stepIndex < current) return "finish";
    if (stepIndex > current) return "wait";

    return "process";
  };

  return (
    <div className={classNames(s.bbStepper, s[`bbStepper-${type}`])}>
      {steps.map((step, idx) => {
        const status = getStepStatus(idx);
        const stepConfig = config[status];

        return (
          <Fragment key={idx}>
            {stepConfig && (
              <Step
                id={idx}
                type={type}
                size={size}
                key={step.title}
                title={step.title}
                tooltip={step.tooltip}
                status={status}
                config={stepConfig}
                hasTail={idx < steps.length - 1}
                content={stepConfig?.icon || idx + 1}
                onClick={status === "finish" ? onStepClick : undefined}
              />
            )}
          </Fragment>
        );
      })}
    </div>
  );
};
