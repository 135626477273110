import nl2br from "@utils/nl2br";
import React, { FC } from "react";
import classNames from "classnames/bind";
import { useTranslation } from "react-i18next";

import { Button, PlusIcon } from "@bbdevcrew/bb_ui_kit_fe";
import { Confetti } from "@components/_common/Confetti/Confetti";

import s from "./AINLPModal.module.less";

import { WorkflowCategoryIconMap } from "../../menu/helpers";
import { DEFAULT_CONFETTI_CONFIG } from "@components/_common/AIOnboarding/AIOnboarding.helpers";

const AINLPSuccess: FC = () => {
  const { t } = useTranslation();
  const transBase = "pages:workflows:workflowCreation:taggingFlow:success";

  const categories = ["hide", "email_alert", "archive", "sentiment"];

  const onGoToCardClick = (category: string) =>
    (window.location.href =
      process.env.BASE_URL +
      `/control_panel/?section=control_panel-workflows&category=${category}`);

  return (
    <div className={s.bbAINLPSuccess}>
      <div className={s.bbAINLPSuccessConfetti}>
        <Confetti {...DEFAULT_CONFETTI_CONFIG} recycle={false} width={680} />
      </div>
      <div className={s.bbAINLPSuccessTitle}>{t(`${transBase}:title`)}</div>
      <div className={s.bbAINLPSuccessSubtitle}>{t(`${transBase}:subTitle`)}</div>
      <div className={s.bbAINLPSuccessCards}>
        {categories.map((category, idx) => {
          const Icon = WorkflowCategoryIconMap[category];

          return (
            <div className={s.bbAINLPSuccessCard} key={idx}>
              <div
                className={classNames(
                  s.bbAINLPSuccessCardTitle,
                  s[`bbAINLPSuccessCardTitle-${category}`],
                )}
              >
                <div
                  className={classNames(
                    s.bbAINLPSuccessCardTypeIcon,
                    s[`bbAINLPSuccessCardTypeIcon-${category}`],
                  )}
                >
                  <Icon />
                </div>
                <span>{nl2br(t(`${transBase}:${category}`))}</span>
              </div>
              <div className={s.bbAINLPSuccessCardAction}>
                <Button
                  _type="secondary"
                  _size="md"
                  onClick={() => onGoToCardClick(category)}
                  className={s.bbAINLPSuccessCardBtn}
                >
                  <PlusIcon />
                  {t(`${transBase}:actionBtn`)}
                </Button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AINLPSuccess;
