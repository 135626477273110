import { ajax } from "rxjs/ajax";
import { Observable } from "rxjs";
import { isOfType } from "typesafe-actions";
import { StateObservable } from "redux-observable";
import { switchMap, map, filter, catchError, mergeMap } from "rxjs/operators";

import { getInboxStatsFailure, getInboxStatsSuccess } from "./actions";
import { setCareModuleInboxStatsAction } from "../modules/actions";

import { RootState } from "../";
import { GET_INBOX_STATS } from "./actionTypes";
import { getCurrentModuleName, repliesStats } from "@utils/paths";
import { InboxStatsActions, IRepliesStatsResponse } from "./types";
import { getHeaders } from "@utils/headers";
import { handleError } from "@bbdevcrew/bb_ui_kit_fe";
import { getSubFeatureName } from "@utils/feature";

export const getInboxStatsEpic = (
  action$: Observable<InboxStatsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_INBOX_STATS)),
    switchMap(a => {
      return ajax
        .post<IRepliesStatsResponse>(
          repliesStats(
            a.payload.featureName || getCurrentModuleName(),
            a.payload.subfeatureName || getSubFeatureName(),
          ),
          {
            filters: a.payload.filters,
          },
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          mergeMap(data => [getInboxStatsSuccess(data), setCareModuleInboxStatsAction(data)]),
          catchError(e => handleError(e, getInboxStatsFailure)),
        );
    }),
  );
