import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Action } from "..";
import { ClearableTrigger, Input, SelectStyledMulti } from "@bbdevcrew/bb_ui_kit_fe";

import { getUsersAction } from "@store/users/actions";

import {
  getUsersPendingSelector,
  getUsersSuccessfulSelector,
  usersSelector,
} from "@store/users/selectors";

import s from "./AssignWorkflowAction.module.less";

import { handleMultiselectState } from "@bbdevcrew/bb_ui_kit_fe";

import { IClearableTriggerProps } from "@bbdevcrew/bb_ui_kit_fe";
import { IFormComponent } from "@components/control-panel/workflows/types";

export const AssignWorkflowAction: React.FC<IFormComponent<string[]>> = ({ value, onChange }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [assignedUsersIds, setAssignedUsersIds] = useState(value || []);

  const users = useSelector(usersSelector);
  const usersFetching = useSelector(getUsersPendingSelector);
  const usersFetched = useSelector(getUsersSuccessfulSelector);
  const usersOptions = users.map(user => ({
    id: user.id,
    value: user.id,
    label: `${user.first_name} ${user.last_name}`,
  }));

  useEffect(() => {
    if (!usersFetched && !usersFetching && !usersOptions?.length) dispatch(getUsersAction());
  }, [usersFetched, usersFetching, dispatch, usersOptions.length]);

  const TriggerWithTooltip = (tooltipProps: IClearableTriggerProps) => {
    if (!assignedUsersIds || assignedUsersIds.length === 0) {
      return ClearableTrigger(tooltipProps);
    }

    const tooltipElement = assignedUsersIds.map(currentAssignedUserId => (
      <span key={currentAssignedUserId} style={{ display: "block" }}>
        {currentAssignedUserId}
      </span>
    ));

    return ClearableTrigger({
      tooltip: tooltipElement,
      onClear: () => setAssignedUsersIds([]),
      ...tooltipProps,
      value: assignedUsersIds,
    });
  };

  const onInternalChange = (selectedValue: string) => {
    const newState = handleMultiselectState(usersOptions, assignedUsersIds, selectedValue);

    setAssignedUsersIds(newState);
    onChange?.(newState);
  };

  return (
    <Action type="assign" className={s.bbAssignAction}>
      <SelectStyledMulti
        _size="sm"
        id="assigned_users"
        value={assignedUsersIds}
        options={usersOptions}
        onChange={onInternalChange}
        InputVariation={Input}
        dropdownPlacement="topRight"
        TriggerVariation={TriggerWithTooltip}
        excludePlaceholder={t("generic:exclude")}
        excludedPlaceholder={t("generic:excluded")}
        placeholder={t("generic:select")}
      />
    </Action>
  );
};
