import { createReducer } from "typesafe-actions";
import produce from "immer";

import * as actions from "./actions";
import { IAIHighlightPromptsState, AIHighlightPromptsAction, IAIHighlightPrompt } from "./types";

const initialState: IAIHighlightPromptsState = {
  prompts: [],
  currentPrompt: null,
  getPrompts: {
    fetching: false,
    fetched: false,
    fetchFail: false,
  },
  getPromptById: {
    fetching: false,
    fetched: false,
    fetchFail: false,
  },
  createPrompt: {
    fetching: false,
    fetched: false,
    fetchFail: false,
  },
  updatePrompt: {
    fetching: false,
    fetched: false,
    fetchFail: false,
  },
  deletePrompt: {
    fetching: false,
    fetched: false,
    fetchFail: false,
  },
  setDefaultPrompt: {
    fetching: false,
    fetched: false,
    fetchFail: false,
  },
};

export const aiHighlightsPromptsReducer = createReducer<
  IAIHighlightPromptsState,
  AIHighlightPromptsAction
>(initialState)
  .handleAction(actions.getPromptsAction, (state: IAIHighlightPromptsState) =>
    produce(state, (draft: IAIHighlightPromptsState) => {
      draft.getPrompts.fetching = true;
      draft.getPrompts.fetched = false;
      draft.getPrompts.fetchFail = false;
    }),
  )
  .handleAction(
    actions.getPromptsSuccessAction,
    (state: IAIHighlightPromptsState, action: ReturnType<typeof actions.getPromptsSuccessAction>) =>
      produce(state, (draft: IAIHighlightPromptsState) => {
        draft.prompts = action.payload.items;
        draft.getPrompts.fetching = false;
        draft.getPrompts.fetched = true;
        draft.getPrompts.fetchFail = false;
      }),
  )
  .handleAction(actions.getPromptsFailureAction, (state: IAIHighlightPromptsState) =>
    produce(state, (draft: IAIHighlightPromptsState) => {
      draft.prompts = [];
      draft.getPrompts.fetching = false;
      draft.getPrompts.fetched = false;
      draft.getPrompts.fetchFail = true;
    }),
  )
  .handleAction(actions.getPromptByIdAction, (state: IAIHighlightPromptsState) =>
    produce(state, (draft: IAIHighlightPromptsState) => {
      draft.getPromptById.fetching = true;
      draft.getPromptById.fetched = false;
      draft.getPromptById.fetchFail = false;
    }),
  )
  .handleAction(
    actions.getPromptByIdSuccessAction,
    (
      state: IAIHighlightPromptsState,
      action: ReturnType<typeof actions.getPromptByIdSuccessAction>,
    ) =>
      produce(state, (draft: IAIHighlightPromptsState) => {
        draft.currentPrompt = action.payload;
        draft.getPromptById.fetching = false;
        draft.getPromptById.fetched = true;
        draft.getPromptById.fetchFail = false;
      }),
  )
  .handleAction(actions.getPromptByIdFailureAction, (state: IAIHighlightPromptsState) =>
    produce(state, (draft: IAIHighlightPromptsState) => {
      draft.currentPrompt = null;
      draft.getPromptById.fetching = false;
      draft.getPromptById.fetched = false;
      draft.getPromptById.fetchFail = true;
    }),
  )
  .handleAction(actions.getPromptByIdResetAction, (state: IAIHighlightPromptsState) =>
    produce(state, (draft: IAIHighlightPromptsState) => {
      draft.currentPrompt = null;
    }),
  )
  .handleAction(
    actions.createPromptAction,
    (state: IAIHighlightPromptsState, action: ReturnType<typeof actions.createPromptAction>) =>
      produce(state, (draft: IAIHighlightPromptsState) => {
        draft.prompts.push(action.payload);
        draft.createPrompt.fetching = true;
        draft.createPrompt.fetched = false;
        draft.createPrompt.fetchFail = false;
      }),
  )
  .handleAction(actions.createPromptSuccessAction, (state: IAIHighlightPromptsState) =>
    produce(state, (draft: IAIHighlightPromptsState) => {
      draft.createPrompt.fetching = false;
      draft.createPrompt.fetched = true;
      draft.createPrompt.fetchFail = false;
    }),
  )
  .handleAction(actions.createPromptFailureAction, (state: IAIHighlightPromptsState) =>
    produce(state, (draft: IAIHighlightPromptsState) => {
      draft.createPrompt.fetching = false;
      draft.createPrompt.fetched = false;
      draft.createPrompt.fetchFail = true;
    }),
  )
  .handleAction(actions.updatePromptAction, (state: IAIHighlightPromptsState) =>
    produce(state, (draft: IAIHighlightPromptsState) => {
      draft.updatePrompt.fetching = true;
      draft.updatePrompt.fetched = false;
      draft.updatePrompt.fetchFail = false;
    }),
  )
  .handleAction(actions.updatePromptSuccessAction, (state: IAIHighlightPromptsState) =>
    produce(state, (draft: IAIHighlightPromptsState) => {
      draft.updatePrompt.fetching = false;
      draft.updatePrompt.fetched = true;
      draft.updatePrompt.fetchFail = false;
    }),
  )
  .handleAction(actions.updatePromptFailureAction, (state: IAIHighlightPromptsState) =>
    produce(state, (draft: IAIHighlightPromptsState) => {
      draft.updatePrompt.fetching = false;
      draft.updatePrompt.fetched = false;
      draft.updatePrompt.fetchFail = true;
    }),
  )
  .handleAction(actions.deletePromptAction, (state: IAIHighlightPromptsState) =>
    produce(state, (draft: IAIHighlightPromptsState) => {
      draft.deletePrompt.fetching = true;
      draft.deletePrompt.fetched = false;
      draft.deletePrompt.fetchFail = false;
    }),
  )
  .handleAction(
    actions.deletePromptSuccessAction,
    (
      state: IAIHighlightPromptsState,
      action: ReturnType<typeof actions.deletePromptSuccessAction>,
    ) =>
      produce(state, (draft: IAIHighlightPromptsState) => {
        if (draft.prompts) {
          draft.prompts = draft.prompts.filter(
            (prompt: IAIHighlightPrompt) => prompt.id !== action.payload,
          );
        }
        draft.deletePrompt.fetching = false;
        draft.deletePrompt.fetched = true;
        draft.deletePrompt.fetchFail = false;
      }),
  )
  .handleAction(actions.deletePromptFailureAction, (state: IAIHighlightPromptsState) =>
    produce(state, (draft: IAIHighlightPromptsState) => {
      draft.deletePrompt.fetching = false;
      draft.deletePrompt.fetched = false;
      draft.deletePrompt.fetchFail = true;
    }),
  )
  .handleAction(actions.resetStateAction, () => initialState);
