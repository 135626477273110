import cn from "classnames";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Card, RadioGroup } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./WorkflowCreationModal.module.less";

import { WorkflowCategoryIconMap } from "../menu/helpers";

import { IWorkflowTypeCardProps } from "./types";

import { UnlockIcon } from "@bbdevcrew/bb_ui_kit_fe";

const cx = cn.bind(s);

const WorkflowTypeCard: FC<IWorkflowTypeCardProps> = ({ type, selected, showUpgrade }) => {
  const { t } = useTranslation();

  const tStep1 = "pages:workflows:workflowCreation:step1";

  const Icon = WorkflowCategoryIconMap[type];

  return (
    <Card
      className={cx(s.bbWorkflowTypeCard, {
        [s.bbWorkflowTypeCardSelected]: selected,
      })}
    >
      <RadioGroup.Button
        _size="md"
        value={type}
        labelClassName={s.bbWorkflowTypeCardRadioLabel}
        data-cy={`workflow-type-card-${type}`}
        disabled={showUpgrade}
      >
        <div className={s.bbWorkflowType}>
          <div
            className={cx(s.bbWorkflowTypeContent, {
              [s.bbWorkflowTypeContentLocked]: showUpgrade,
            })}
          >
            {Icon && (
              <div className={cx(s.bbWorkflowTypeIcon, s[`bbWorkflowTypeIcon-${type}`])}>
                <Icon />
              </div>
            )}
            <div className={s.bbWorkflowTypeTextWrapper}>
              <div className={s.bbWorkflowTypeTitle}>{t(`${tStep1}:${type}:title`)}</div>
            </div>
          </div>
          {showUpgrade && (
            <div className={s.bbWorkflowTypeLockChip}>
              <UnlockIcon />
              {t("header:upgradeBtn:short")}
            </div>
          )}
        </div>
      </RadioGroup.Button>
    </Card>
  );
};

export default WorkflowTypeCard;
