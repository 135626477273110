import classNames from "classnames/bind";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import CustomTagsDropdown from "@components/_common/CommentCard/customTags/CustomTagsDropdown";
import { Button, ChevronDownIcon } from "@bbdevcrew/bb_ui_kit_fe";

import { customTagsSelector, getCustomTagsSuccessfulSelector } from "@store/customTags/selectors";
import { getCustomTagsAction } from "@store/customTags/actions";

import s from "./TagWorkflowAction.module.less";

import { ICustomTag } from "@bbdevcrew/bb_ui_kit_fe";
import { IFormComponent } from "@components/control-panel/workflows/types";

export const CustomTagWorkflowAction: React.FC<IFormComponent<string[]>> = ({
  id,
  value,
  onChange,
  dropdownPlacement,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [tag, setTag] = useState<ICustomTag>();
  const [initialized, setInitialized] = useState(false);

  const customTags = useSelector(customTagsSelector);
  const fetchedCustomTags = useSelector(getCustomTagsSuccessfulSelector);

  useEffect(() => {
    dispatch(getCustomTagsAction());
  }, [dispatch]);

  useEffect(() => {
    if (fetchedCustomTags && !!value?.length && !initialized) {
      setInitialized(true);
      const initializedValue = value[0].split("::")[1];

      if (initializedValue) {
        setTag(customTags.find(customTag => customTag.id === initializedValue));
      }
    }
    // eslint-disable-next-line
  }, [fetchedCustomTags, value]);

  return (
    <CustomTagsDropdown
      id={id}
      onSelectTag={(_tag, dropdownId, creationMode) => {
        if (creationMode && id === dropdownId) {
          setTag(_tag);
          const updatedId = `custom_tag::${_tag.id}`;

          if (!value || updatedId !== value[0]) onChange?.([updatedId]);
        } else if (!creationMode && !dropdownId) {
          setTag(_tag);

          const updatedId = `custom_tag::${_tag.id}`;
          if (_tag.id && _tag.id !== tag?.id) onChange?.([updatedId]);
        }
      }}
      _dropdownPlacement={dropdownPlacement || "topLeft"}
      wrapperClassName={s.bbWorkflowItemFormTagSelectWrapper}
      trigger={
        <Button className={s.bbWorkflowItemFormTagSelect} type="button">
          <span
            className={classNames(s.bbWorkflowItemFormTagSelectLabel, {
              [s.bbWorkflowItemFormTagSelectPlaceholder]: !(tag && tag?.label),
            })}
          >
            {(tag && tag?.label) || t("pages:workflows:form:actions:tag:placeholder")}
          </span>
          <i className={s.bbWorkflowItemFormTagSelectChevron}>
            <ChevronDownIcon />
          </i>
        </Button>
      }
    />
  );
};
