import React from "react";
import dayjs from "dayjs";

import { getColumnInfo } from "./info/Info";
import { getColumnBullet } from "./bullet/Bullet";
import {
  NSSTooltipContent,
  ReachesTooltipContent,
  ReactionsTooltipContent,
  EngagementRateTooltipContent,
  SharesTooltipContent,
  ImpressionsTooltipContent,
  ERByImpressionsTooltipContent,
} from "./tooltips/Tooltips";

import s from "./PostsTable.module.less";

import {
  EyeIcon,
  EyeOffIcon,
  ISimpleTableColumn,
  IFilters,
  AppFilterType,
  IDynamicFilter,
  IPost,
} from "@bbdevcrew/bb_ui_kit_fe";
import { i18nextInstance } from "../../../../App";

import {
  ThumbsUpIcon,
  TagIcon,
  PictureIcon,
  CalendarIcon,
  HeartIcon,
  PostIcon,
  CommentIcon,
  ShareIcon,
  TargetIcon,
  AdIcon,
  OrganicIcon,
  MagnetIcon,
} from "@bbdevcrew/bb_ui_kit_fe";

const TOOLTIP_WIDTH = 300;
export const PAGE_SIZE_OPTIONS = [10, 20, 25, 50, 100];

const getColumnPrefix = (content: React.ReactNode) => (
  <span className={s.bbColumnPrefix}>{content}</span>
);

export const getPostsTableColumns = (): ISimpleTableColumn[] => {
  return [
    {
      name: i18nextInstance.t("components:posts:table:tableHeader:columns:post"),
      id_name: "post",
      colSpan: 3,
      isSortable: false,
      prefix: getColumnPrefix(<PostIcon />),
      className: s.bbColumnStickyMain,
    },
    {
      name: i18nextInstance.t("components:posts:table:tableHeader:columns:date"),
      id_name: "created_time",
      colSpan: 1.25,
      prefix: getColumnPrefix(<CalendarIcon />),
    },
    {
      name: i18nextInstance.t("components:posts:table:tableHeader:columns:format"),
      id_name: "media_type",
      colSpan: 1,
      isSortable: false,
      prefix: getColumnPrefix(<PictureIcon />),
    },
    {
      name: i18nextInstance.t("components:posts:table:tableHeader:columns:nss"),
      id_name: "nss_score",
      colSpan: 1,
      prefix: getColumnPrefix(<HeartIcon />),
      suffix: getColumnInfo(<NSSTooltipContent />, TOOLTIP_WIDTH),
    },
    {
      name: i18nextInstance.t("components:posts:table:tableHeader:columns:impressionsRate"),
      id_name: "engagement_rate_impressions",
      colSpan: 1.25,
      prefix: getColumnPrefix(<MagnetIcon />),
      suffix: getColumnInfo(<ERByImpressionsTooltipContent />, TOOLTIP_WIDTH),
    },
    {
      name: i18nextInstance.t("components:posts:table:tableHeader:columns:erByReach"),
      id_name: "engagement_rate",
      colSpan: 1.25,
      prefix: getColumnPrefix(<MagnetIcon />),
      suffix: getColumnInfo(<EngagementRateTooltipContent />, TOOLTIP_WIDTH),
    },
    {
      name: i18nextInstance.t("components:posts:table:tableHeader:columns:userComments"),
      id_name: "total_comments",
      colSpan: 1,
      prefix: getColumnPrefix(<CommentIcon />),
    },
    {
      name: i18nextInstance.t("components:posts:table:tableHeader:columns:reactions"),
      id_name: "total_reactions",
      colSpan: 1.25,
      prefix: getColumnPrefix(<ThumbsUpIcon />),
      suffix: getColumnInfo(<ReactionsTooltipContent />, TOOLTIP_WIDTH),
    },
    {
      name: i18nextInstance.t("components:posts:table:tableHeader:columns:impressions"),
      id_name: "total_views",
      colSpan: 1.25,
      prefix: getColumnPrefix(<EyeIcon />),
      suffix: getColumnInfo(<ImpressionsTooltipContent />, TOOLTIP_WIDTH),
    },
    {
      name: i18nextInstance.t("components:posts:table:tableHeader:columns:reach"),
      id_name: "total_reaches",
      colSpan: 1,
      suffix: getColumnInfo(<ReachesTooltipContent />, TOOLTIP_WIDTH),
      prefix: getColumnPrefix(<AdIcon />),
    },
    {
      name: i18nextInstance.t("components:posts:table:tableHeader:columns:shares"),
      id_name: "total_shares",
      colSpan: 1,
      prefix: getColumnPrefix(<ShareIcon />),
      suffix: getColumnInfo(<SharesTooltipContent />, TOOLTIP_WIDTH),
    },
    {
      name: i18nextInstance.t("components:posts:table:tableHeader:columns:positiveComments"),
      id_name: "positive_comments",
      colSpan: 1,
      prefix: getColumnBullet("success"),
    },
    {
      name: i18nextInstance.t("components:posts:table:tableHeader:columns:negativeComments"),
      id_name: "negative_comments",
      colSpan: 1,
      prefix: getColumnBullet("danger"),
    },
    {
      name: i18nextInstance.t("components:posts:table:tableHeader:columns:neutralComments"),
      id_name: "neutral_comments",
      colSpan: 1,
      prefix: getColumnBullet("neutral"),
    },
    {
      name: i18nextInstance.t("components:posts:table:tableHeader:columns:userTagComments"),
      id_name: "user_tags_comments",
      colSpan: 1,
      prefix: getColumnBullet("user_tag"),
    },
    {
      name: i18nextInstance.t("components:posts:table:tableHeader:columns:hiddenComments"),
      id_name: "hidden_comments",
      colSpan: 1.25,
      prefix: getColumnPrefix(<EyeOffIcon />),
    },
    {
      name: i18nextInstance.t("components:posts:table:tableHeader:columns:brandComments"),
      id_name: "brand_comments",
      colSpan: 1.25,
      prefix: getColumnPrefix(<CommentIcon />),
    },
    {
      name: i18nextInstance.t("components:posts:table:tableHeader:columns:targeting"),
      id_name: "targetings",
      colSpan: 1,
      isSortable: false,
      prefix: getColumnPrefix(<TargetIcon />),
    },
    {
      name: i18nextInstance.t("components:posts:table:tableHeader:columns:postType"),
      id_name: "post_type",
      colSpan: 1,
      prefix: getColumnPrefix(<OrganicIcon />),
    },
    {
      name: i18nextInstance.t("components:posts:table:tableHeader:columns:postTags"),
      id_name: "post_tags",
      colSpan: 1.25,
      isSortable: false,
      prefix: getColumnPrefix(<TagIcon />),
    },
  ];
};

export const isMetricNotAvailable = (metric?: string | number) => metric === "N/A";
export const isMetricNotAvailableYet = (metric?: string | number) => metric === "N/AY";

export const isPostFilterAllowed = (type: AppFilterType, name: string) => {
  const EXCLUDED_ASSET_FILTERS = [
    "visibility_type",
    "message_types",
    "message_statuses",
    "listen_sources",
    "author_followers",
  ];

  return !EXCLUDED_ASSET_FILTERS.includes(name) && type !== "comment";
};

// eslint-disable-next-line
const isNotAllowedFilterKey = (key: string, defaultFilters: IDynamicFilter<any>[]) => {
  return (
    key !== "start_time" &&
    key !== "end_time" &&
    !isPostFilterAllowed(defaultFilters.find(f => f.name === key)?.type || "comment", key)
  );
};

export const getPostsPageAllowedFilters = (
  filters: IFilters,
  defaultFilters: IDynamicFilter<any>[], // eslint-disable-line
): IFilters => {
  const filtersData = { ...filters };

  delete filtersData.keyword_query_in_translation;

  Object.keys(filtersData).forEach(key => {
    if (isNotAllowedFilterKey(key, defaultFilters)) {
      delete filtersData[key as keyof IFilters];
    }
  });

  if (filtersData.exclusion_filters) {
    Object.keys(filtersData.exclusion_filters).forEach(key => {
      if (isNotAllowedFilterKey(key, defaultFilters)) {
        delete filtersData.exclusion_filters?.[key as keyof IFilters];
      }
    });

    filtersData.exclusion_filters = Object.keys(filtersData.exclusion_filters).length
      ? filtersData.exclusion_filters
      : undefined;
  }

  return filtersData;
};

export const getPostDateTime = (post: IPost) => {
  return dayjs(post?.created_time_pretty).format("D MMM YYYY h:mm A");
};
