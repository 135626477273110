import { switchMap, map, filter, catchError } from "rxjs/operators";
import { Observable } from "rxjs";
import { ajax } from "rxjs/ajax";
import { isActionOf } from "typesafe-actions";
import { StateObservable } from "redux-observable";

import * as actions from "./actions";
import { RootState } from "..";
import { ModulesActions, IGetModuleSectionsResponse } from "./types";

import { modules } from "@utils/paths";

import { getHeaders } from "@utils/headers";
import { handleError } from "@bbdevcrew/bb_ui_kit_fe";
import { getCareModuleStructure } from "./helpers";
import { meSelector } from "@store/me/selectors";
import { isSmartInboxSelector } from "./selectors";

const processModuleData = (
  data: IGetModuleSectionsResponse,
  moduleId: string,
  state$: StateObservable<RootState>,
) => {
  const isCareModule = moduleId === "care";
  const me = meSelector(state$.value);
  const isSafetyFullyManaged = !!me?.client?.is_moderation_managed;
  const isSmartInbox = isSmartInboxSelector(state$.value);
  const inboxType = isSmartInbox ? "smart-inbox" : "inbox";

  if (isCareModule) {
    const items = getCareModuleStructure(data.items, inboxType, isSafetyFullyManaged);
    return actions.getModuleSectionsListSuccessAction(items, moduleId);
  }

  return actions.getModuleSectionsListSuccessAction(data.items, moduleId);
};

export const getModules = (
  action$: Observable<ModulesActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.getModuleSectionsListAction)),
    switchMap(a => {
      return ajax
        .get<IGetModuleSectionsResponse>(
          `${modules}/${a.payload.id}`,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => processModuleData(data, a.payload.id, state$)),
          catchError(e =>
            handleError(e, () => actions.getModuleSectionsListFailureAction(a.payload.id)),
          ),
        );
    }),
  );

export const postModules = (
  action$: Observable<ModulesActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.postModuleSectionsListAction)),
    switchMap(a => {
      return ajax
        .post<IGetModuleSectionsResponse>(
          modules,
          a.payload,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => processModuleData(data, a.payload.id, state$)),
          catchError(e =>
            handleError(e, () => actions.getModuleSectionsListFailureAction(a.payload.module)),
          ),
        );
    }),
  );
