import { RootState } from "..";

export const fromNLPWorkflows = (state: RootState) => state.nlpWorkflows;

export const workflowAIModelSelector = (state: RootState) =>
  fromNLPWorkflows(state).workflowAIModel;

export const fetchingWorkflowAIModelSelector = (state: RootState) =>
  fromNLPWorkflows(state).fetchingWorkflowAIModel;
export const fetchedWorkflowAIModelSelector = (state: RootState) =>
  fromNLPWorkflows(state).fetchedWorkflowAIModel;
export const fetchingWorkflowAIModelFailedSelector = (state: RootState) =>
  fromNLPWorkflows(state).fetchingWorkflowAIModelFailed;

export const postingWorkflowAIModelSelector = (state: RootState) =>
  fromNLPWorkflows(state).postingWorkflowAIModel;
export const postedWorkflowAIModelSelector = (state: RootState) =>
  fromNLPWorkflows(state).postedWorkflowAIModel;
export const postingWorkflowAIModelFailedSelector = (state: RootState) =>
  fromNLPWorkflows(state).postingWorkflowAIModelFailed;

export const createdAIModelTaskSelector = (state: RootState) =>
  fromNLPWorkflows(state).createdAIModelTask;
export const creatingWorkflowAIModelTaskSelector = (state: RootState) =>
  fromNLPWorkflows(state).creatingWorkflowAIModelTask;
export const createdWorkflowAIModelTaskSelector = (state: RootState) =>
  fromNLPWorkflows(state).createdWorkflowAIModelTask;
export const creatingWorkflowAIModelTaskFailedSelector = (state: RootState) =>
  fromNLPWorkflows(state).creatingWorkflowAIModelTaskFailed;

export const editingWorkflowAIModelTaskSelector = (state: RootState) =>
  fromNLPWorkflows(state).updatingWorkflowAIModelTask;
export const editedWorkflowAIModelTaskSelector = (state: RootState) =>
  fromNLPWorkflows(state).updatedWorkflowAIModelTask;
export const editingWorkflowAIModelTaskFailedSelector = (state: RootState) =>
  fromNLPWorkflows(state).updatingWorkflowAIModelTaskFailed;

export const creatingWorkflowAIModelTaskExampleSelector = (state: RootState) =>
  fromNLPWorkflows(state).creatingWorkflowAIModelTaskExample;
export const createdWorkflowAIModelTaskExampleSelector = (state: RootState) =>
  fromNLPWorkflows(state).createdWorkflowAIModelTaskExample;
export const creatingWorkflowAIModelTaskExampleFailedSelector = (state: RootState) =>
  fromNLPWorkflows(state).creatingWorkflowAIModelTaskExampleFailed;

export const updatingWorkflowAIModelTaskExampleSelector = (state: RootState) =>
  fromNLPWorkflows(state).updatingWorkflowAIModelTaskExample;
export const updatedWorkflowAIModelTaskExampleSelector = (state: RootState) =>
  fromNLPWorkflows(state).updatedWorkflowAIModelTaskExample;
export const updatingWorkflowAIModelTaskExampleFailedSelector = (state: RootState) =>
  fromNLPWorkflows(state).updatingWorkflowAIModelTaskExampleFailed;

export const deletingWorkflowAIModelTaskExampleSelector = (state: RootState) =>
  fromNLPWorkflows(state).deletingWorkflowAIModelTaskExample;
export const deletedWorkflowAIModelTaskExampleSelector = (state: RootState) =>
  fromNLPWorkflows(state).deletedWorkflowAIModelTaskExample;
export const deletingWorkflowAIModelTaskExampleFailedSelector = (state: RootState) =>
  fromNLPWorkflows(state).deletingWorkflowAIModelTaskExampleFailed;

export const testingWorkflowAIModelSelector = (state: RootState) =>
  fromNLPWorkflows(state).testingWorkflowAIModel;
export const testedWorkflowAIModelSelector = (state: RootState) =>
  fromNLPWorkflows(state).testedWorkflowAIModel;
export const testingWorkflowAIModelFailedSelector = (state: RootState) =>
  fromNLPWorkflows(state).testingWorkflowAIModelFailed;
export const testingWorkflowAIModelResultSelector = (state: RootState) =>
  fromNLPWorkflows(state).testingWorkflowAIModelResult;
