import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Grid } from "antd";
import { Stepper } from "../stepper/Stepper";
import { IStepperConfig, ITStep } from "../stepper/Stepper.types";

import s from "./SignUpHeader.module.less";

import Logo from "@assets/LogoNoBeta.svg";

const { useBreakpoint } = Grid;

interface ISignUpHeaderProps {
  current: number;
  steps: ITStep[];
  logoBadge?: JSX.Element | null;
  stepperConfig?: IStepperConfig;
}

const SignUpHeader: FC<ISignUpHeaderProps> = ({
  current,
  logoBadge = null,
  steps,
  stepperConfig,
}) => {
  const { t } = useTranslation();
  const screens = useBreakpoint();

  return (
    <div className={s.bbSignUpHeader}>
      {screens.lg ? (
        <>
          <div className={s.bbSignUpHeaderLogo}>
            <Logo />
            {logoBadge}
          </div>
          <div className={s.bbSignUpStepper}>
            <Stepper current={current} steps={steps} config={stepperConfig} />
          </div>
        </>
      ) : (
        <div className={s.bbMobileStepIndicator}>
          {t("pages:signup:stepper:stepOutOf", {
            count: current + 1,
          })}
        </div>
      )}
    </div>
  );
};

export default SignUpHeader;
