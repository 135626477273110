import classNames from "classnames/bind";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { SelectStyled } from "@bbdevcrew/bb_ui_kit_fe";
import { CustomTagWorkflowAction } from "./CustomTagWorkflowAction";

import { getCommentTagsAction } from "@store/commentTags/actions";
import { commentTagsSelector } from "@store/commentTags/selectors";

import s from "./TagWorkflowAction.module.less";

import { useSearch } from "@bbdevcrew/bb_ui_kit_fe";
import { IFormComponent } from "@components/control-panel/workflows/types";
import { getTagActionTypeOptions } from "./TagWorkflowAction.helpers";
import { bbTagToOption } from "@components/_common/CommentCard/brandBastionTags/BBTags.helpers";

export const BBTagWorkflowAction: FC<IFormComponent<string[]>> = ({ id, value, onChange }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const ref = useRef<HTMLSpanElement>(null);

  const [tagType, setTagType] = useState<string>();

  const clientTags = useSelector(commentTagsSelector).filter(
    tag => tag.key !== "custom_tags" && tag.key !== "ai_tags",
  );

  const getClientTags = useCallback(() => dispatch(getCommentTagsAction()), [dispatch]);

  const optionsFormatted = useMemo(() => {
    return clientTags.map(tag => bbTagToOption(tag, true));
  }, [clientTags]);

  const getDropdownPosition = () => {
    const middlehalf = window.innerHeight / 2;
    const rect = ref.current?.getBoundingClientRect();

    if (rect?.top && rect.top > middlehalf) return "topLeft";
    else if (rect?.top && rect.top < middlehalf) return "bottomLeft";
    return undefined;
  };

  useEffect(() => {
    getClientTags();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTagType(value?.[0]?.includes("managed_tag") ? "bb_tag" : "custom_tag");
    // eslint-disable-next-line
  }, []);

  const onTypeChange = (type: string) => {
    setTagType(type);
    onChange?.([]);
  };

  const { options, SearchInput, searchTerm, setSearchTerm } = useSearch(
    optionsFormatted,
    t("generic:search"),
  );

  return (
    <div className={s.bbTagAction}>
      <SelectStyled
        options={getTagActionTypeOptions()}
        onChange={onTypeChange}
        value={tagType}
        dropdownMatchSelectWidth={true}
        wrapperClassName={s.bbTagActionTypeSelect}
      />
      <span ref={ref}>{t("generic:as")}</span>
      {tagType === "custom_tag" ? (
        <CustomTagWorkflowAction
          id={id}
          value={value}
          onChange={onChange}
          dropdownPlacement={getDropdownPosition()}
        />
      ) : tagType === "bb_tag" ? (
        <SelectStyled
          options={options}
          wrapperClassName={classNames(s.bbTagActionTypeSelect, s.bbBBTagActionTypeSelect, {
            [s.bbTagActionTypeSelectPlaceholder]: !value?.[0],
          })}
          dropdownClassName={s.bbBBTagsDropdown}
          onChange={val => val.includes("managed_tag") && onChange?.([val])}
          value={value?.[0]}
          highlightTerm={searchTerm}
          dropdownPlacement={getDropdownPosition() || "topRight"}
          placeholder={t("pages:workflows:form:triggers:time_frame:bbtags:placeholder")}
          onDropdownClose={() => setSearchTerm("")}
          DropdownHeader={
            <div className={s.bbTagsDropdownHeader}>
              <span>{t("pages:workflows:form:triggers:time_frame:bbtags:label")}</span>
              {SearchInput}
            </div>
          }
        />
      ) : null}
    </div>
  );
};
