import React from "react";
import { CheckIcon } from "@bbdevcrew/bb_ui_kit_fe";
import palette from "@bbdevcrew/bb_ui_kit_fe/dist/theme/scheme.module.less";

import s from "./TTSignup.module.less";
import { IStepperConfig } from "../stepper/Stepper.types";

export function isValidQueryParam<T>(
  queryParamsObj: Record<string, unknown>,
  propName: string,
): queryParamsObj is Record<string, T> {
  return !!queryParamsObj[propName] && typeof queryParamsObj[propName] === "string";
}

export const APP_ID = "7314050002651906050";

export const stepperConfig: IStepperConfig = {
  process: {
    backgroundColor: palette.colorBlack,
    borderColor: palette.colorBlack,
    color: palette.colorWhite,
    titleColor: palette.colorBlack,
    tailColors: [palette.colorPrimary600, palette.colorGrey200],
  },
  finish: {
    backgroundColor: palette.colorWhite,
    borderColor: palette.colorGrey200,
    color: palette.colorGrey700,
    titleColor: palette.colorGrey700,
    tailColors: [palette.colorGrey200],
    icon: (
      <div className={s.bbStepDefaultFinishIcon}>
        <CheckIcon />
      </div>
    ),
  },
  wait: {
    backgroundColor: palette.colorWhite,
    borderColor: palette.colorGrey200,
    color: palette.colorBlue900,
    titleColor: palette.colorBlue900,
    tailColors: [palette.colorGrey200],
  },
};
