import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { FC, useCallback, useEffect } from "react";

import { Form } from "antd";
import AINLPModalLoader from "../AINLPModalLoader";
import { Button, IFilters } from "@bbdevcrew/bb_ui_kit_fe";
import { ExpansionPanelBadge } from "../../../form/ExpansionPanel";
import { AllFiltersWorkflowTrigger } from "../../../form/triggers/allFiltersWorkflowTrigger";

import s from "./Step2.module.less";

import {
  postedWorkflowAIModelSelector,
  postingWorkflowAIModelSelector,
  workflowAIModelSelector,
} from "@store/nlpWorkflows/selectors";
import { setWorkflowFormDirtyAction } from "@store/workflows/actions";
import { postWorkflowsAIModelsAction } from "@store/nlpWorkflows/actions";

import { IStepProps } from "../AINLPModal.types";
import { IWorkflowAIModelPayload } from "@store/nlpWorkflows/types";

import { ChevronLeftIcon, ChevronRightIcon } from "@bbdevcrew/bb_ui_kit_fe";

const Step2: FC<IStepProps> = ({
  workflowId,
  setCurrentStep,
  previousStep,
  setPreviousStep,
  isEditMode,
  setShowUpdatingStepModal,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm<{ trigger: { filters: IFilters } }>();

  const transBase = "pages:workflows:workflowCreation:taggingFlow:rightPanel:step2";

  const workflowAIModel = useSelector(workflowAIModelSelector);
  const postingWorkflowAIModel = useSelector(postingWorkflowAIModelSelector);
  const postedWorkflowAIModel = useSelector(postedWorkflowAIModelSelector);

  const postWorkflowsAIModels = useCallback(
    (payload: IWorkflowAIModelPayload) => dispatch(postWorkflowsAIModelsAction(payload)),
    [dispatch],
  );

  useEffect(() => {
    if (postedWorkflowAIModel) setCurrentStep(2);
  }, [postedWorkflowAIModel, setCurrentStep]);

  useEffect(() => {
    if (previousStep > 1 || isEditMode) {
      form.setFields([
        {
          name: ["trigger", "filters"],
          value: workflowAIModel?.filters?.filters as IFilters,
        },
      ]);
    }
    // eslint-disable-next-line
  }, [previousStep, isEditMode]);

  const sendWorkflowAIModel = () => {
    const filters = form.getFieldsValue().trigger.filters;
    const payload = {
      step: "filters",
      workflow_id: workflowId,
      filters: {
        filters:
          filters.exclusion_filters &&
          Object.keys(filters.exclusion_filters).length === 0 &&
          Object.keys(filters).length === 1
            ? {}
            : filters,
      },
    } as IWorkflowAIModelPayload;

    postWorkflowsAIModels(payload);
  };

  const onFinish = () => {
    if (previousStep > 1) {
      setShowUpdatingStepModal(true, sendWorkflowAIModel);
    } else sendWorkflowAIModel();
  };

  const onGoBackClick = () => {
    form.resetFields();
    setPreviousStep(1);
    setCurrentStep(0);
  };

  return (
    <>
      {postingWorkflowAIModel ? (
        <AINLPModalLoader />
      ) : (
        <Form
          form={form}
          layout="vertical"
          requiredMark={false}
          onFinish={onFinish}
          onValuesChange={() => dispatch(setWorkflowFormDirtyAction(true))}
        >
          <div className={s.bbAIWorkflowStep2}>
            <Form.Item name={["trigger", "filters"]}>
              <AllFiltersWorkflowTrigger
                form={form}
                workflowType="tag"
                showViewMore={false}
                showExclusionToggle={false}
                isFiltersPanelInitiallyOpen={false}
                additionalFilters={["regex"]}
                initialFilterData={
                  previousStep === 2 ? workflowAIModel?.filters?.filters : undefined
                }
                initialExlusionFilterData={
                  previousStep === 2
                    ? workflowAIModel?.filters?.filters?.exclusion_filters
                    : undefined
                }
                hiddenFilters={["data_range_option", "is_post_based"]}
                mainFiltersPanelTitle={
                  <>
                    <div className={s.bbFiltersPanelCustomHeader}>
                      <ExpansionPanelBadge>
                        {t("components:filters:workflows:filters:badge")}
                      </ExpansionPanelBadge>
                      {t("components:filters:workflows:filters:title")}
                      <span className={s.bbFiltersPanelCustomHeaderOptional}>
                        {t(`${transBase}:optional`)}
                      </span>
                    </div>
                    <div className={s.bbFiltersPanelCustomHeaderSubtitle}>
                      {t(`${transBase}:filtersText`)}
                    </div>
                  </>
                }
                exclusionFiltersPanelTitle={
                  <>
                    <div className={s.bbFiltersPanelCustomHeader}>
                      <ExpansionPanelBadge danger>
                        {t("components:filters:workflows:excluded:badge")}
                      </ExpansionPanelBadge>
                      {t("components:filters:workflows:excluded:title")}
                      <span className={s.bbFiltersPanelCustomHeaderOptional}>
                        {t(`${transBase}:optional`)}
                      </span>
                    </div>
                    <div className={s.bbFiltersPanelCustomHeaderSubtitle}>
                      {t(`${transBase}:exclusionsText`)}
                    </div>
                  </>
                }
              />
            </Form.Item>
          </div>
          <Form.Item className={s.bbAIWorkflowStep2Footer}>
            <Button
              _type="on-surface"
              onClick={onGoBackClick}
              disabled={postingWorkflowAIModel}
              className={s.bbAIWorkflowStep2BackBtn}
              data-cy="ai-tagging-workflow-step2-backBtn"
            >
              <ChevronLeftIcon />
              {t(`${transBase}:backBtn`)}
            </Button>
            <Button
              type="submit"
              _type="primary"
              disabled={postingWorkflowAIModel}
              className={s.bbAIWorkflowStep2OkBtn}
              data-cy="ai-tagging-workflow-step2-okBtn"
            >
              {t(`${transBase}:okBtn`)}
              <ChevronRightIcon />
            </Button>
          </Form.Item>
        </Form>
      )}
    </>
  );
};

export default Step2;
