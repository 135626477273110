import { useSelector } from "react-redux";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Col, Row } from "antd";
import Header from "../_common/header";
import TopTags from "./topTags/TopTags";
import { Tooltip } from "@bbdevcrew/bb_ui_kit_fe";
import AnalysisTrends from "../_common/analysisTrends/AnalysisTrends";
import MostUsedEmojis from "@components/insights/_common/mostUsedEmojis/MostUsedEmojis";
import DownloadButton from "../../insights/downloadButton";
import GenerateAIButton from "../_common/generateAIButton";
import MobileFiltersButton from "@components/_common/AppFilter/mobileFiltersButton";

import { filterDataSelector } from "@store/filters/selectors";

import s from "./Topics.module.less";

import { useDashboardData } from "@utils/useDashboardData";
import { DashboardComponentType } from "@store/modules/types";
import { useAppFilters } from "@utils/appFilters";
import { IFilters } from "@bbdevcrew/bb_ui_kit_fe";
import { useYTSelected } from "@utils/useYTselected";

export const Topics: FC = () => {
  const { t } = useTranslation();
  const { updateSearchDataAndUrl } = useAppFilters();

  const components = useMemo<DashboardComponentType[]>(
    () => [
      "explorer_overview_top_emojis",
      "explorer_overview_top_keywords",
      "explorer_overview_top_keyword_phrases",
      "top_tags",
    ],
    [],
  );
  const { data, isFetching } = useDashboardData(components);

  const filters = useSelector(filterDataSelector);
  const isYTFilterSelected = useYTSelected(filters?.platform_types || []);

  const filterToSpecificItem = (item: IFilters) => {
    updateSearchDataAndUrl(item, "id");
  };

  return (
    <>
      <Header>
        <GenerateAIButton />
        <Tooltip
          title={isYTFilterSelected ? t("pages:insights:ytNoDownload") : ""}
          placement="bottom"
        >
          <span>
            <DownloadButton disabled={isYTFilterSelected} />
          </span>
        </Tooltip>
        <MobileFiltersButton />
      </Header>
      {filters ? (
        <Col xs={24} id="topics-section" className={s.bbTopics}>
          <Row gutter={[28, 28]}>
            <Col className={s.bbTopicsCol}>
              <AnalysisTrends
                filters={filters}
                loading={isFetching}
                wordCloudData={data?.explorer_overview_top_keywords?.items || []}
                keywordPhrasesData={data?.explorer_overview_top_keyword_phrases?.items || []}
                filterToSpecificItem={filterToSpecificItem}
              />
            </Col>
            <Col className={s.bbTopicsCol}>
              <MostUsedEmojis
                filters={filters}
                loading={isFetching}
                data={data?.explorer_overview_top_emojis?.items || []}
                filterToSpecificItem={filterToSpecificItem}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <TopTags data={data?.top_tags?.items || []} loading={isFetching} />
            </Col>
          </Row>
        </Col>
      ) : null}
    </>
  );
};
