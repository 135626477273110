import { RootState } from "..";

export const fromWorkflows = (state: RootState) => state.workflows;

export const workflowItemsSelector = (state: RootState) => fromWorkflows(state).workflowItems;
export const workflowCategoriesSelector = (state: RootState) =>
  fromWorkflows(state).workflowCategories;
export const workflowRiskOptionsSelector = (state: RootState) =>
  fromWorkflows(state).workflowRiskOptions;

export const workflowsFetchingSelector = (state: RootState) =>
  fromWorkflows(state).fetchingWorkflows;
export const workflowsFetchedSelector = (state: RootState) => fromWorkflows(state).fetchedWorkflows;
export const workflowsFetchingFailedSelector = (state: RootState) =>
  fromWorkflows(state).fetchingWorkflowsFailed;

export const workflowCreatingSelector = (state: RootState) => fromWorkflows(state).creatingWorkflow;
export const workflowCreatedSelector = (state: RootState) => fromWorkflows(state).createdWorkflow;
export const workflowCreatingFailedSelector = (state: RootState) =>
  fromWorkflows(state).creatingWorkflowFailed;

export const workflowEditingSelector = (state: RootState) => fromWorkflows(state).editingWorkflow;
export const workflowEditedSelector = (state: RootState) => fromWorkflows(state).editedWorkflow;
export const workflowEditingFailedSelector = (state: RootState) =>
  fromWorkflows(state).editingWorkflowFailed;

export const workflowDeletingSelector = (state: RootState) => fromWorkflows(state).deletingWorkflow;
export const workflowDeletedSelector = (state: RootState) => fromWorkflows(state).deletedWorkflow;
export const workflowDeletingFailedSelector = (state: RootState) =>
  fromWorkflows(state).deletingWorkflowFailed;

export const workflowEnablingSelector = (state: RootState) => fromWorkflows(state).enablingWorkflow;
export const workflowEnabledSelector = (state: RootState) => fromWorkflows(state).enabledWorkflow;
export const workflowEnablingFailedSelector = (state: RootState) =>
  fromWorkflows(state).enablingWorkflowFailed;

export const workflowDisablingSelector = (state: RootState) =>
  fromWorkflows(state).disablingWorkflow;
export const workflowDisabledSelector = (state: RootState) => fromWorkflows(state).disabledWorkflow;
export const workflowDisablingFailedSelector = (state: RootState) =>
  fromWorkflows(state).disablingWorkflowFailed;

export const workflowFormDirtySelector = (state: RootState) => fromWorkflows(state).formDirty;

export const workflowInitialFetchDoneSelector = (state: RootState) =>
  fromWorkflows(state).initialFetchDone;

export const workflowsAccessUsersSelector = (state: RootState) =>
  fromWorkflows(state).workflowsAccessUsers;
export const fetchingWorkflowsAccessUsersSelector = (state: RootState) =>
  fromWorkflows(state).fetchingWorkflowsAccessUsers;
export const fetchedWorkflowsAccessUsersSelector = (state: RootState) =>
  fromWorkflows(state).fetchedWorkflowsAccessUsers;
export const fetchingWorkflowsAccessUsersFailedSelector = (state: RootState) =>
  fromWorkflows(state).fetchingWorkflowsAccessUsersFailed;

export const workflowsSavedRepliesSelector = (state: RootState) =>
  fromWorkflows(state).workflowsSavedReplies;
export const workflowsSavedRepliesFetchingSelector = (state: RootState) =>
  fromWorkflows(state).fetchingWorkflowsSavedReplies;
export const workflowsSavedRepliesFetchedSelector = (state: RootState) =>
  fromWorkflows(state).fetchedWorkflowsSavedReplies;
export const workflowsSavedRepliesFetchingFailedSelector = (state: RootState) =>
  fromWorkflows(state).fetchingWorkflowsSavedRepliesFailed;

export const matchingCommentsSelector = (state: RootState) => fromWorkflows(state).matchingComments;
