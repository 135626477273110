import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { Form } from "antd";
import { Checkbox, Textarea, Input, FormErrorMessage, Tooltip } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./CustomPrompts.module.less";

import {
  ICustomPromptsFormProps,
  ICustomTextareaProps,
  IDefaultForOptionsProps,
} from "./CustomPrompts.types";
import { IAIHighlightPrompt } from "@store/aiHighlightsPrompts/types";
import { AIInsightsPromptType } from "@store/aiInsights/types";

import { HelpIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const CustomTextarea: React.FC<ICustomTextareaProps> = ({
  value,
  onChange,
  description,
  error,
  ...textareaProps
}) => {
  return (
    <>
      {description && (
        <p
          className={classNames(s.bbCustomPromptsFormDescription, {
            [s.bbCustomPromptsFormDescriptionError]: error,
          })}
        >
          {description}
        </p>
      )}
      <Textarea
        autoSize
        className={s.bbCustomPromptsFormTextarea}
        value={value}
        onChange={event => onChange?.(event.target.value)}
        {...textareaProps}
      />
    </>
  );
};

const DefaultForOptions: React.FC<IDefaultForOptionsProps> = ({ value = [], onChange }) => {
  const { t } = useTranslation();

  return (
    <div className={s.bbCustomPromptsFormDefaultForOptions}>
      {Object.values(AIInsightsPromptType).map(option => (
        <div className={s.bbCustomPromptsFormCheckbox} key={option}>
          <Checkbox
            key={option}
            checked={value.includes(option)}
            _size="sm"
            id={`default-for-${option}`}
            onChange={e => {
              onChange?.(e.target.checked ? [...value, option] : value.filter(v => v !== option));
            }}
          />
          <label htmlFor={`default-for-${option}`}>
            {t("components:customPrompts:labels:defaultCheckbox", {
              promptType: t(`components:customPrompts:labels:${option}`),
            })}
          </label>
        </div>
      ))}
    </div>
  );
};

export const CustomPromptsForm: React.FC<ICustomPromptsFormProps> = ({
  form,
  loading = false,
  onFinish,
}) => {
  const { t } = useTranslation();
  const [formErrors, setFormErrors] = React.useState<Record<string, boolean>>({});

  const handleFormValuesChange = useCallback((_: unknown, allValues: IAIHighlightPrompt) => {
    const errors: Record<string, boolean> = {};

    const fields = ["persona", "context", "format", "rules"] as const;
    fields.forEach(field => {
      errors[field] = !allValues[field];
    });

    setFormErrors(errors);
  }, []);

  const renderPromptTextarea = (
    name: keyof IAIHighlightPrompt,
    label: string,
    maxChars: number,
  ) => {
    return (
      <Form.Item
        name={name}
        label={
          <span className={s.bbCustomPromptsFormLabel}>
            {label}
            <span className={s.bbCustomPromptsFormLabelMaxCharacters}>
              {t("components:customPrompts:maxChars", { maxChars })}
            </span>
          </span>
        }
        rules={[
          {
            required: true,
            message: <FormErrorMessage>{t("generic:validation:required")}</FormErrorMessage>,
          },
          {
            max: maxChars,
            message: (
              <FormErrorMessage>
                {t("components:customPrompts:maxChars", { maxChars })}
              </FormErrorMessage>
            ),
          },
        ]}
        className={s.bbCustomPromptsFormPanel}
      >
        <CustomTextarea
          description={t(`components:customPrompts:form:${name}:description`)}
          error={formErrors[name]}
        />
      </Form.Item>
    );
  };

  return (
    <div className={s.bbCustomPromptsForm}>
      <Form
        disabled={loading}
        form={form}
        layout="vertical"
        requiredMark="optional"
        onFinish={onFinish}
        onValuesChange={handleFormValuesChange}
        className={classNames(s.bbCustomPromptsFormContent, {
          [s.bbCustomPromptsFormContentLoading]: loading,
        })}
      >
        <Form.Item name="id" className={s.bbCustomPromptsFormHidden}>
          <input type="hidden" name="id" />
        </Form.Item>
        <Form.Item
          name="name"
          label={t("components:customPrompts:labels:promptName")}
          rules={[
            {
              required: true,
              message: <FormErrorMessage>{t("generic:validation:required")}</FormErrorMessage>,
            },
          ]}
        >
          <Input autoFocus />
        </Form.Item>

        <Form.Item
          name="default_for"
          label={
            <span className={s.bbCustomPromptsFormCheckboxLabel}>
              {t("components:customPrompts:labels:setDefault")}
              <Tooltip title={t("components:customPrompts:tooltips:defaultPrompt")}>
                <i className={s.bbCustomPromptsFormCheckboxLabelIcon}>
                  <HelpIcon />
                </i>
              </Tooltip>
            </span>
          }
          requiredMark={false}
          className={s.bbCustomPromptsFormDefaultFor}
        >
          <DefaultForOptions />
        </Form.Item>

        <h4 className={s.bbCustomPromptsFormHeading}>
          {t("components:customPrompts:form:heading")}
        </h4>

        <Form.Item
          className={s.bbCustomPromptsFormPanel}
          label={
            <span className={s.bbCustomPromptsFormLabel}>
              {t("components:customPrompts:form:task:label")}
            </span>
          }
          requiredMark={false}
        >
          <p
            className={classNames(
              s.bbCustomPromptsFormDescription,
              s.bbCustomPromptsFormDescriptionStatic,
            )}
          >
            {t("components:customPrompts:form:task:description")}
          </p>
        </Form.Item>
        {renderPromptTextarea("persona", t("components:customPrompts:form:persona:label"), 500)}
        {renderPromptTextarea("context", t("components:customPrompts:form:context:label"), 500)}
        {renderPromptTextarea("format", t("components:customPrompts:form:format:label"), 5000)}
        {renderPromptTextarea("rules", t("components:customPrompts:form:rules:label"), 1500)}
      </Form>
    </div>
  );
};

export default CustomPromptsForm;
