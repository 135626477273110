import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import WorkflowActions from "./WorkflowActions";
import { Card, Toggle, ButtonPermissionGuard, ChipIcon } from "@bbdevcrew/bb_ui_kit_fe";

import {
  postShortenedSearchesSuccessfulSelector,
  shortenedSearchesExplorerLinkSelector,
} from "@store/shortenedSearches/selectors";

import { postShortenedSearchesAction } from "@store/shortenedSearches/actions";

import s from "./WorkflowItem.module.less";

import {
  IWorkflowItemProps,
  DESCRIPTION_MAX_LENGTH,
  SUPPORTED_WORKFLOW_CATEGORIES,
} from "./helpers";
import { cropText } from "@bbdevcrew/bb_ui_kit_fe";
import { getWorkflowItemTitle } from "../helpers";
import usePermissions from "@utils/usePermissions";
import { WorkflowCategoryIconMap } from "../menu/helpers";
import { IWorkflowEmailAlertAction } from "@store/workflows/types";
import { MagicSparklesAIIcon } from "@assets/index";

const WorkflowItem: FC<IWorkflowItemProps> = ({
  item,
  onEditClick,
  onDeleteClick,
  onWorkflowToggle,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const permissions = usePermissions();

  const [isActionsDropdownOpen, setIsActionsDropdownOpen] = useState(false);

  const canViewOrEdit = useMemo(
    () => SUPPORTED_WORKFLOW_CATEGORIES.includes(item.category) && item.is_editable,
    [item.category, item.is_editable],
  );

  const postShortenedSearchesFetched = useSelector(postShortenedSearchesSuccessfulSelector);
  const shortenedSearchesExplorerLink = useSelector(shortenedSearchesExplorerLinkSelector);

  useEffect(() => {
    if (postShortenedSearchesFetched) {
      window.location.href = shortenedSearchesExplorerLink;
    }
  }, [postShortenedSearchesFetched, shortenedSearchesExplorerLink]);

  const closeActionsDropdown = () => setIsActionsDropdownOpen(false);

  const onCardClick = () => {
    if (!isActionsDropdownOpen && canViewOrEdit) {
      onEditClick(closeActionsDropdown);
    }
  };

  const goToExplore = useCallback(
    () => dispatch(postShortenedSearchesAction({ filters: item?.trigger?.filters || {} })),
    // eslint-disable-next-line
    [dispatch],
  );

  const Icon = WorkflowCategoryIconMap[item.category];

  const getAdditionalIcon = (action: IWorkflowEmailAlertAction) => {
    const AdditionalIcon =
      WorkflowCategoryIconMap[action?.category as keyof typeof WorkflowCategoryIconMap];
    return <AdditionalIcon />;
  };

  return (
    <>
      <Card
        className={classNames(s.bbWorkflowItemContainer, {
          [s.bbCanViewOrEdit]: canViewOrEdit,
          [s.bbWorkflowItemContainerDisabled]: !item.enabled,
        })}
        data-cy="workflows-item"
        onClick={onCardClick}
      >
        <div className={s.bbWorkflowItem}>
          {item.show_enable_checkbox && (
            <div className={s.bbWorkflowItemToggle} onClick={event => event.stopPropagation()}>
              <ButtonPermissionGuard
                hasPermission={!!permissions["manageWorkflows"]}
                message={t("permissions:editWorkflows")}
              >
                <Toggle
                  id={`enabled-${item.id}`}
                  checked={item.enabled}
                  onChange={onWorkflowToggle}
                  data-cy="workflow-toggle"
                />
              </ButtonPermissionGuard>
            </div>
          )}
          <div className={s.bbWorkflowItemContent}>
            <div
              className={classNames(
                s.bbWorkflowItemHeader,
                s[`bbWorkflowItemHeader-${item.category}`],
              )}
            >
              <div
                className={classNames(
                  s.bbWorkflowItemCategoryIcon,
                  s[`bbWorkflowItemCategoryIcon-${item.category}`],
                )}
              >
                {Icon ? <Icon /> : null}
              </div>
              {item.additional_actions?.map((action, index) => (
                <div
                  key={index}
                  className={classNames(
                    s.bbWorkflowItemCategoryAdditionalIcon,
                    s[`bbWorkflowItemCategoryAdditionalIcon-${action?.category}`],
                  )}
                >
                  {getAdditionalIcon(action)}
                </div>
              ))}
            </div>
            <div
              className={classNames(
                s.bbWorkflowItemCategory,
                s[`bbWorkflowItemCategory-${item.category}`],
              )}
            >
              {getWorkflowItemTitle(item.category)}
            </div>
            {item.additional_actions?.map((action, index) => (
              <div className={s.bbAdditionalActionCategoryWrapper} key={index}>
                {" + "}
                <div
                  className={classNames(
                    s.bbWorkflowItemCategory,
                    s[`bbWorkflowItemCategory-${action.category}`],
                  )}
                >
                  {action?.category ? getWorkflowItemTitle(action.category) : null}
                </div>
              </div>
            ))}
            {item.category === "nlp_tag" && (
              <ChipIcon
                _size="xs"
                _type="grey"
                text={t("pages:workflows:workflowLabels:nlp_tag")}
                icon={<MagicSparklesAIIcon />}
                iconPosition="left"
                wrapperClassName={classNames(
                  s.bbWorkflowItemChipIcon,
                  s[`bbWorkflowItemChipIcon-ai_gradient`],
                )}
              />
            )}
            <div className={s.bbWorkflowItemTitle} data-cy="workflow-item-title">
              {item.title}
            </div>
            <div className={s.bbWorkflowItemDescription}>
              {item.description &&
                item.description.trim() !== "-" &&
                cropText(item.description, DESCRIPTION_MAX_LENGTH)}
            </div>
          </div>
          <div className={s.bbWorkflowItemLabelWrapper}>
            {canViewOrEdit && (
              <WorkflowActions
                onEditClick={() => {
                  onEditClick(closeActionsDropdown);
                }}
                onDeleteClick={() => {
                  onDeleteClick(closeActionsDropdown);
                }}
                onExploreClick={goToExplore}
                isActionsDropdownOpen={isActionsDropdownOpen}
                setIsActionsDropdownOpen={setIsActionsDropdownOpen}
                showExploreAction={
                  item.category === "email_alert" ||
                  (item.category === "hide" &&
                    !!item.additional_actions?.some(action => action.category === "email_alert"))
                }
              />
            )}
          </div>
        </div>
      </Card>
      {/* Temporarily disabled the details modal, https://brandbastion.atlassian.net/browse/DEV-11958 */}
      {/* {enableDetailModal && (
        <Modal
          responsive
          centered
          theme="light"
          title={item.title}
          hideFooter
          
          open={detailModalOpen}
          onCancel={() => setDetailModalOpen(false)}
          noPadding
          width={800}
        >
          <WorkflowItemDetails {...item} />
        </Modal>
      )} */}
    </>
  );
};

export default WorkflowItem;
