import cn from "classnames";
import { useTranslation } from "react-i18next";
import React, { FC } from "react";

import { Button, Card, ChevronRightIcon, RadioGroup } from "@bbdevcrew/bb_ui_kit_fe";

import { WorkflowItemCategoryType } from "@store/workflows/types";
import { IStepTaggingFlowSelectionProps, ITaggingFlowSelectionCardProps } from "./types";

import s from "./Step.module.less";

import { MagicGradientColorIcon } from "@assets/index";
import { FilterSlidersIcon } from "@bbdevcrew/bb_ui_kit_fe";
import CreateFiltersIllustration from "@assets/CreateFiltersIllustration.svg";
import CreateWithAIIllustration from "@assets/CreateWithAIIllustration.svg";

const cx = cn.bind(s);

const TaggingFlowSelectionCard: FC<ITaggingFlowSelectionCardProps> = ({
  value,
  icon,
  title,
  subTitle,
  description,
  illustration,
  selected,
}) => (
  <Card
    className={cx(s.bbTaggingFlowCard, {
      [s.bbTaggingFlowCardSelected]: selected,
    })}
  >
    <RadioGroup.Button _size="md" value={value} labelClassName={s.bbTaggingFlowBtn}>
      <div className={s.bbTaggingFlowCardTitleWrapper}>
        <div className={s.bbTaggingFlowCardIcon}>{icon}</div>
        <div className={s.bbTaggingFlowCardTitle}>{title}</div>
      </div>
      <div className={s.bbTaggingFlowCardSubtitle}>{subTitle}</div>
      <div className={s.bbTaggingFlowCardDescription}>{description}</div>
      <div className={s.bbTaggingFlowCardIllustration}>{illustration}</div>
    </RadioGroup.Button>
  </Card>
);

const StepTaggingFlowSelection: FC<IStepTaggingFlowSelectionProps> = ({
  selectedTaggingFlow,
  setSelectedTaggingFlow,
  onCreateWorkflowClick,
}) => {
  const { t } = useTranslation();
  const taggingFlowTransBase = "pages:workflows:workflowCreation:taggingFlowStep";

  const onWorkflowTypeClick = (value: WorkflowItemCategoryType) => {
    setSelectedTaggingFlow(value);
  };

  return (
    <div className={s.bbWorkflowCreationStepWide}>
      <div className={s.bbWorkflowModalTitle}>{t(`${taggingFlowTransBase}:title`)}</div>
      <RadioGroup
        value={selectedTaggingFlow}
        onChange={value => onWorkflowTypeClick(value as WorkflowItemCategoryType)}
        className={s.bbWorkflowTaggingFlowRadio}
      >
        <TaggingFlowSelectionCard
          value="default"
          selected={selectedTaggingFlow === "default"}
          icon={<FilterSlidersIcon />}
          illustration={<CreateFiltersIllustration />}
          title={t(`${taggingFlowTransBase}:defaultFlow:title`)}
          subTitle={t(`${taggingFlowTransBase}:defaultFlow:subTitle`)}
          description={
            <>
              {t(`${taggingFlowTransBase}:defaultFlow:description:title`)}
              <ul>
                <li>{t(`${taggingFlowTransBase}:defaultFlow:description:sentence1`)}</li>
                <li>{t(`${taggingFlowTransBase}:defaultFlow:description:sentence2`)}</li>
              </ul>
            </>
          }
        />
        <TaggingFlowSelectionCard
          value="ai"
          selected={selectedTaggingFlow === "ai"}
          icon={<MagicGradientColorIcon />}
          illustration={<CreateWithAIIllustration />}
          title={t(`${taggingFlowTransBase}:aiFlow:title`)}
          subTitle={t(`${taggingFlowTransBase}:aiFlow:subTitle`)}
          description={
            <>
              {t(`${taggingFlowTransBase}:aiFlow:description:title`)}
              <ul>
                <li>{t(`${taggingFlowTransBase}:aiFlow:description:sentence1`)}</li>
                <li>{t(`${taggingFlowTransBase}:aiFlow:description:sentence2`)}</li>
              </ul>
            </>
          }
        />
      </RadioGroup>
      <div className={s.bbWorkflowCreationStepFooter}>
        <Button
          _type="primary"
          onClick={onCreateWorkflowClick}
          className={s.bbWorkflowCreationStepBtn}
          data-cy="workflow-creation-step1-btn"
        >
          {t("pages:workflows:workflowCreation:step1:okBtn")}
          <ChevronRightIcon />
        </Button>
      </div>
    </div>
  );
};

export default StepTaggingFlowSelection;
