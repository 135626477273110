import loadable from "@loadable/component";

const Care = loadable(() => import("@components/care"));

const Calendar = loadable(() => import("@components/publish/calendar/CalendarPage"));

const Transparency = loadable(() => import("@components/managed/Transparency"));

export const CareSubFeatureComponents = {
  inbox: Care,
  "smart-inbox": Care,
};

export const PublishSubFeatureComponents = {
  calendar: Calendar,
};

export const ManagedSubFeatureComponents = {
  transparency: Transparency,
};

export const CleanPathAndNavigate = () => {
  // Remove trailing slashes, if they exist
  if (window.location.pathname.match("/.*/$")) {
    const newPathname = window.location.pathname.replace(/\/+$/, "");
    const newUrl =
      window.location.origin + newPathname + window.location.search + window.location.hash;
    window.location.assign(newUrl);
  } else {
    window.location.href = `${process.env.BASE_URL}/insights/overview`;
  }

  return null;
};
