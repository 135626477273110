import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";

import Step1 from "./step1/Step1";
import Step2 from "./step2/Step2";
import Step3 from "./step3/Step3";
import Step4 from "./step4/Step4";
import Step5 from "./step5/Step5";
import Step6 from "./step6/Step6";
import AINLPSuccess from "./AINLPSuccess";
import { Modal } from "@bbdevcrew/bb_ui_kit_fe";
import AINLPModalLoader from "./AINLPModalLoader";
import { Stepper } from "@components/login/stepper/Stepper";
import AINLPTaggingModalLeftPanel from "./AINLPModalLeftPanel";

import {
  workflowAIModelSelector,
  postedWorkflowAIModelSelector,
} from "@store/nlpWorkflows/selectors";
import { clearPostWorkflowsAIModelsAction } from "@store/nlpWorkflows/actions";

import s from "./AINLPModal.module.less";

import { IAINLPTaggingModalProps, IStepProps } from "./AINLPModal.types";

const AINLPTaggingModal: FC<IAINLPTaggingModalProps> = ({
  workflowId = "",
  setWorkflowId,
  isEditMode,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const showUpdatingStepModalCallbackRef = useRef<() => void>();

  const [currentAINLPStep, setCurrentAINLPStep] = useState(0);
  const [previousAINLPStep, setPreviousAINLPStep] = useState(0);
  const [showUpdatingStepModal, setShowUpdatingStepModal] = useState(false);

  const workflowAIModel = useSelector(workflowAIModelSelector);
  const postedWorkflowAIModel = useSelector(postedWorkflowAIModelSelector);

  const clearPostWorkflowsAIModels = useCallback(
    () => dispatch(clearPostWorkflowsAIModelsAction()),
    [dispatch],
  );

  useEffect(() => {
    if (postedWorkflowAIModel) {
      if (currentAINLPStep === 2) setTimeout(() => clearPostWorkflowsAIModels(), 500);
      else clearPostWorkflowsAIModels();
    }

    if (postedWorkflowAIModel && workflowAIModel?.id && !workflowId) {
      setWorkflowId(workflowAIModel?.id);
    }
    // eslint-disable-next-line
  }, [postedWorkflowAIModel, workflowAIModel, workflowId, clearPostWorkflowsAIModels]);

  const onShowUpdatingStepModal = (showModal: boolean, callback: () => void) => {
    setShowUpdatingStepModal(showModal);
    showUpdatingStepModalCallbackRef.current = callback;
  };

  const stepProps: IStepProps = {
    isEditMode: isEditMode,
    workflowId: workflowId,
    previousStep: previousAINLPStep,
    setCurrentStep: setCurrentAINLPStep,
    setPreviousStep: setPreviousAINLPStep,
    setShowUpdatingStepModal: onShowUpdatingStepModal,
  };

  return (
    <>
      <div className={s.bbWorkflowCreationStepper}>
        <Stepper
          size="sm"
          type="simple"
          current={currentAINLPStep}
          onStepClick={stepIndex => {
            setPreviousAINLPStep(currentAINLPStep);
            setCurrentAINLPStep(stepIndex);
          }}
          steps={Array.from({ length: 6 }, (_, i) => ({
            title: t(`pages:workflows:workflowCreation:taggingFlow:stepper:step${i + 1}`),
            tooltip: t("pages:workflows:workflowCreation:taggingFlow:stepper:editStepTooltip"),
          }))}
        />
      </div>
      <div className={s.bbAIWorkflowCreationContent}>
        {!workflowId ? (
          <div className={s.bbAIWorkflowCreationLoader}>
            <AINLPModalLoader />
          </div>
        ) : (
          <>
            {currentAINLPStep !== 6 && (
              <>
                <AINLPTaggingModalLeftPanel currentStep={currentAINLPStep} />
                <div className={s.bbAIWorkflowCreationRightPanel}>
                  {currentAINLPStep === 0 && <Step1 {...stepProps} />}
                  {currentAINLPStep === 1 && <Step2 {...stepProps} />}
                  {currentAINLPStep === 2 && <Step3 {...stepProps} />}
                  {currentAINLPStep === 3 && <Step4 {...stepProps} />}
                  {currentAINLPStep === 4 && <Step5 {...stepProps} />}
                  {currentAINLPStep === 5 && <Step6 {...stepProps} />}
                </div>
              </>
            )}
            {currentAINLPStep === 6 && <AINLPSuccess />}
          </>
        )}
      </div>
      <Modal
        responsive
        centered
        width={520}
        classes={{
          modal: s.bbChangeStepModalContent,
          body: s.bbChangeStepModalBody,
        }}
        open={showUpdatingStepModal}
        onOk={() => {
          setShowUpdatingStepModal(false);
          if (showUpdatingStepModalCallbackRef.current) showUpdatingStepModalCallbackRef.current();
          showUpdatingStepModalCallbackRef.current = undefined;
        }}
        onCancel={() => {
          setShowUpdatingStepModal(false);
          showUpdatingStepModalCallbackRef.current = undefined;
        }}
        title={t("pages:workflows:nlpWorkflowChangeStepModal:title")}
        confirmLabel={t("pages:workflows:nlpWorkflowChangeStepModal:confirm")}
        cancelLabel={t("pages:workflows:nlpWorkflowChangeStepModal:cancel")}
      >
        <p>{t("pages:workflows:nlpWorkflowChangeStepModal:description")}</p>
      </Modal>
    </>
  );
};

export default AINLPTaggingModal;
