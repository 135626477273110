import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Modal } from "@bbdevcrew/bb_ui_kit_fe";
import WorkflowCreationModal from "@components/control-panel/workflows/creationModal/WorkflowCreationModal"; // eslint-disable-line max-len

import s from "./AlertsTable.module.less";

import { DEFAULT_WORKFLOW_MODAL_WIDTH } from "@components/control-panel/workflows/WorkflowsContent";
import { useWorkflowModal } from "@utils/useWorkflowModal";

import { PlusIcon } from "@bbdevcrew/bb_ui_kit_fe";

const EmptyState: React.FC = () => {
  const { t } = useTranslation();

  const [workflowCreationModalWidth, setWorkflowCreationModalWidth] = useState(
    DEFAULT_WORKFLOW_MODAL_WIDTH,
  );

  const { workflowModalOpen, selectedWorkflow, openCreateModal, closeModal } = useWorkflowModal();

  return (
    <>
      <div className={s.bbEmptyState}>
        <h2 className={s.bbEmptyStateTitle}>{t("components:listen:alerts:emptyState:title")}</h2>
        <p className={s.bbEmptyStateDescription}>
          {t("components:listen:alerts:emptyState:description")}
        </p>
        <Button
          _size="md"
          _type="primary"
          className={s.bbEmptyStateButton}
          onClick={openCreateModal}
        >
          <PlusIcon />
          {t("components:listen:alerts:emptyState:buttonText")}
        </Button>
      </div>
      <Modal
        open={workflowModalOpen}
        width={workflowCreationModalWidth}
        onCancel={closeModal}
        centered
        noPadding
        responsive
        hideFooter
        hideHeader
      >
        <WorkflowCreationModal
          workflowType="email_alert"
          item={selectedWorkflow}
          setWorkflowCreationModalWidth={setWorkflowCreationModalWidth}
        />
      </Modal>
    </>
  );
};

export default EmptyState;
