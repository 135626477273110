import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, useState } from "react";

import { CommentsGridContainer } from "@containers/Comments/Comments";
import BatchModeButton from "@containers/BatchMode/BatchModeButton";
import BatchModeSelectAll from "@containers/BatchMode/BatchModeSelectAll";
import MobileFiltersButton from "@components/_common/AppFilter/mobileFiltersButton";
import { AlertDanger, InboxEmptyState, InboxCommentsHeader } from "@bbdevcrew/bb_ui_kit_fe";

import { filterDataSelector } from "@store/filters/selectors";
import { inboxStatsSelector } from "@store/inboxStats/selectors";
import { assignToMeLoadingSelector } from "@store/assign/selectors";
import { shortenedSearchesIdSelector } from "@store/shortenedSearches/selectors";

import { getInboxRepliesAction } from "@store/comments/actions";
import { getInboxStatsAction } from "@store/inboxStats/actions";

import s from "./Inbox.module.less";

import {
  IPostCommentBody,
  ReplyPlatformType,
  IGetInboxRepliesPayload,
} from "@store/comments/types";
import {
  IInboxStats,
  useInboxOptions,
  getInboxParentTabValue,
  getDefaultReplyPlatformTypeOptions,
} from "@bbdevcrew/bb_ui_kit_fe";
import { useInbox } from "@utils/useInbox";
import { useAppFilters } from "@utils/appFilters";
import { useBatchMode } from "@containers/BatchMode/BatchMode";
import { useCommentSortingOptions } from "@components/insights/_common/commentSortingDropdown";

import { AlertTriangleFilledIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const defaultInboxTab = "care_highlights";

const Inbox = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isBatchModeActive } = useBatchMode();
  const [searchParams, setSearchParams] = useSearchParams();
  const { updateSearchDataAndUrl } = useAppFilters();
  const { options: inboxOptions } = useInboxOptions();
  const { options: sortOptions } = useCommentSortingOptions();

  const queryParamsTab = searchParams.get("section");
  const openReply = searchParams.get("openReply");

  const [sortValue, setSortValue] = useState<string>("desc");
  const { statTabValue, setStatTabValue, assignToMeHandler } = useInbox(defaultInboxTab, sortValue);
  const [isTabToggled, setIsTabToggled] = useState(false);
  const [replyPlatformType, setReplyPlatformType] = useState<ReplyPlatformType>(
    getDefaultReplyPlatformTypeOptions(t)[0].value,
  );
  const [showErrorDisclaimer, setShowErrorDisclaimer] = useState(false);

  const filters = useSelector(filterDataSelector);
  const inboxStats = useSelector(inboxStatsSelector);
  const shortenedSearchesId = useSelector(shortenedSearchesIdSelector);
  const assignToMeLoading = useSelector(assignToMeLoadingSelector);

  const fetchReplies = useCallback(
    (body: IPostCommentBody | IGetInboxRepliesPayload) => {
      const sortVals = body.sort?.split("|");

      return getInboxRepliesAction({
        ...body,
        sort: sortVals && sortVals[0],
        sort_field: sortVals && sortVals[1],
        list_type: body.isReplyModal ? "all" : statTabValue,
      });
    },
    [statTabValue],
  );

  useEffect(() => {
    setShowErrorDisclaimer(!!inboxStats?.stats.errors);
  }, [inboxStats]);

  useEffect(() => {
    setStatTabValue((queryParamsTab || "care_highlights") as keyof IInboxStats);
  }, [queryParamsTab, setStatTabValue]);

  useEffect(() => {
    setIsTabToggled(prev => !prev);
  }, [statTabValue, setIsTabToggled]);

  useEffect(() => {
    if (filters) dispatch(getInboxStatsAction(filters));
  }, [dispatch, filters, statTabValue]);

  useEffect(() => {
    if (shortenedSearchesId && statTabValue) {
      setSearchParams({
        ...(openReply && { openReply }),
        section: statTabValue,
        id: shortenedSearchesId,
      });
    }
  }, [openReply, shortenedSearchesId, setSearchParams, statTabValue]);

  const onFilterAuthorId = (authorIds: string[]) =>
    updateSearchDataAndUrl({ author_ids: authorIds }, "id");

  const onCloseErrorDisclaimer = () => setShowErrorDisclaimer(false);

  return filters ? (
    <div className={s.bbInboxContentWrapper}>
      <InboxCommentsHeader
        sortValue={sortValue}
        statTabValue={statTabValue || defaultInboxTab}
        sortOptions={sortOptions}
        batchModeActions={
          <>
            {isBatchModeActive && <BatchModeSelectAll />}
            <BatchModeButton />
          </>
        }
        isBatchModeActive={isBatchModeActive}
        assignToMeLoading={assignToMeLoading}
        onClickAssignToMe={assignToMeHandler}
        stats={inboxStats?.stats || {}}
        onSortChange={val => setSortValue(val)}
        replyPlatformTypeValue={replyPlatformType}
        statTabParent={getInboxParentTabValue(inboxOptions, statTabValue || defaultInboxTab)}
        onReplyPlatformChange={val => setReplyPlatformType(val as ReplyPlatformType)}
      />

      <div className={s.bbInboxMobileFiltersButtonWrapper}>
        <MobileFiltersButton />
      </div>

      {showErrorDisclaimer && statTabValue === "errors" && (
        <div data-stonly-target={"app-global__inbox-errors-tab--error-disclaimer-box"}>
          <AlertDanger
            size="large"
            align="center"
            className={s.bbErrorDisclaimer}
            onClose={onCloseErrorDisclaimer}
            message={
              <div className={s.bbErrorDisclaimerMessage}>
                {t("components:errorsTabDisclaimer1")}
                <span className={s.bbErrorDisclaimerBox}>
                  <AlertTriangleFilledIcon />
                </span>
                {t("components:errorsTabDisclaimer2")}
              </div>
            }
          />
        </div>
      )}
      <div className={s.bbInboxCommentsGrid}>
        <CommentsGridContainer
          showStarAction
          showHideAction
          showDeleteAction
          showLikeAction
          showDetailsAction
          showLinkedInReactionAction
          showArchiveAction
          withPostPreview
          filters={filters}
          sortValue={sortValue}
          providedFetchAction={fetchReplies}
          key={`comment-grid-${statTabValue}-${isTabToggled}`}
          EmptyState={
            <InboxEmptyState
              tab={statTabValue || defaultInboxTab}
              assignToMeLoading={assignToMeLoading}
              stats={inboxStats?.stats || {}}
              onAssignClick={assignToMeHandler}
            />
          }
          replyPlatformType={statTabValue === "replied" ? replyPlatformType : undefined}
          providedFilterAuthorIdAction={(authorIds: string[]) => onFilterAuthorId(authorIds)}
        />
      </div>
    </div>
  ) : null;
};

export default Inbox;
