import { IWorkflowItem } from "@store/workflows/types";

export const WORKFLOW_DESCRIPTION_REGEX = /^[a-zA-Z0-9\s\-:;,.()]*$/;

export const getInitialValues = (item: IWorkflowItem) => {
  if (item.category === "assign") {
    return {
      ...item,
      trigger: {
        comment_tags: item.trigger?.filters?.tags,
      },
      action: item.assign,
    };
  }

  if (item.category === "email_alert") {
    return {
      ...item,
      trigger: {
        ...item.trigger,
        filters: {
          ...(item.trigger?.filters || {}),
          is_post_based: item.email_alert?.is_post_based,
        },
      },
      action: item.email_alert,
    };
  }

  return item;
};
