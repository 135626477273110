import React from "react";
import { useTranslation } from "react-i18next";

import { Form, FormInstance } from "antd";
import { CommentTagsTrigger } from "../CommentTagsTrigger";
import { FormErrorMessage } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./AssignWorkflowTrigger.module.less";

export const AssignWorkflowTrigger: React.FC<{ form: FormInstance }> = ({ form }) => {
  const { t } = useTranslation();

  const handleValueChange = () => form.validateFields(["trigger"]);

  return (
    <Form.Item
      name="trigger"
      className={s.bbAssignWorkflowItemTrigger}
      rules={[
        {
          validator: async () => {
            const triggerValues = form.getFieldsValue()?.trigger;

            if (!triggerValues?.comment_tags?.length) {
              return Promise.reject(
                <FormErrorMessage>
                  {t("pages:workflows:form:triggers:assign:required")}
                </FormErrorMessage>,
              );
            }

            return Promise.resolve();
          },
          validateTrigger: "onChange",
        },
      ]}
    >
      <label className={s.bbAssignWorkflowItemTriggerLabel}>
        {t("pages:workflows:form:triggers:assign:label")}
      </label>
      <Form.Item name={["trigger", "comment_tags"]} className={s.bbAssignWorkflowItemTrigger}>
        <CommentTagsTrigger onChange={handleValueChange} />
      </Form.Item>
    </Form.Item>
  );
};
