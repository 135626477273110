import { createReducer, ActionType } from "typesafe-actions";

import {
  createWorkflowAIModelsTaskExamplesSuccessAction,
  createWorkflowsAIModelTaskSuccessAction,
  editWorkflowsAIModelsTaskSuccessAction,
  getWorkflowsAIModelsSuccessAction,
  postWorkflowsAIModelsSuccessAction,
  testWorkflowAIModelSuccessAction,
} from "./actions";

import { INLPWorkflowsState } from "./types";
import {
  POST_WORKFLOW_AI_MODELS,
  POST_WORKFLOW_AI_MODELS_SUCCESS,
  POST_WORKFLOW_AI_MODELS_FAILURE,
  CLEAR_POST_WORKFLOW_AI_MODELS,
  EDIT_WORKFLOW_AI_MODELS_TASK,
  EDIT_WORKFLOW_AI_MODELS_TASK_SUCCESS,
  EDIT_WORKFLOW_AI_MODELS_TASK_FAILURE,
  CREATE_WORKFLOW_AI_MODELS_TASK,
  CREATE_WORKFLOW_AI_MODELS_TASK_SUCCESS,
  CREATE_WORKFLOW_AI_MODELS_TASK_FAILURE,
  GET_WORKFLOW_AI_MODELS,
  GET_WORKFLOW_AI_MODELS_SUCCESS,
  GET_WORKFLOW_AI_MODELS_FAILURE,
  DELETE_WORKFLOW_AI_MODELS_TASK,
  DELETE_WORKFLOW_AI_MODELS_TASK_SUCCESS,
  DELETE_WORKFLOW_AI_MODELS_TASK_FAILURE,
  CREATE_WORKFLOW_AI_MODELS_TASK_EXAMPLES,
  CREATE_WORKFLOW_AI_MODELS_TASK_EXAMPLES_SUCCESS,
  CREATE_WORKFLOW_AI_MODELS_TASK_EXAMPLES_FAILURE,
  CLEAR_CREATE_WORKFLOW_AI_MODELS_TASK_EXAMPLES,
  EDIT_WORKFLOW_AI_MODELS_TASK_EXAMPLE,
  EDIT_WORKFLOW_AI_MODELS_TASK_EXAMPLE_SUCCESS,
  EDIT_WORKFLOW_AI_MODELS_TASK_EXAMPLE_FAILURE,
  TEST_WORKFLOW_AI_MODEL,
  TEST_WORKFLOW_AI_MODEL_SUCCESS,
  TEST_WORKFLOW_AI_MODEL_FAILURE,
  DELETE_WORKFLOW_AI_MODELS_TASK_EXAMPLE,
  DELETE_WORKFLOW_AI_MODELS_TASK_EXAMPLE_SUCCESS,
  DELETE_WORKFLOW_AI_MODELS_TASK_EXAMPLE_FAILURE,
  CLEAR_CREATE_WORKFLOW_AI_MODELS_TASK,
  CLEAR_EDIT_WORKFLOW_AI_MODELS_TASK,
} from "./actionTypes";

const initialState: INLPWorkflowsState = {
  workflowAIModel: null,
  createdAIModelTask: null,
  testingWorkflowAIModelResult: null,

  fetchingWorkflowAIModel: false,
  fetchedWorkflowAIModel: false,
  fetchingWorkflowAIModelFailed: false,

  postingWorkflowAIModel: false,
  postedWorkflowAIModel: false,
  postingWorkflowAIModelFailed: false,

  creatingWorkflowAIModelTask: false,
  createdWorkflowAIModelTask: false,
  creatingWorkflowAIModelTaskFailed: false,

  updatingWorkflowAIModelTask: false,
  updatedWorkflowAIModelTask: false,
  updatingWorkflowAIModelTaskFailed: false,

  deletingWorkflowAIModelTask: false,
  deletedWorkflowAIModelTask: false,
  deletingWorkflowAIModelTaskFailed: false,

  creatingWorkflowAIModelTaskExample: false,
  createdWorkflowAIModelTaskExample: null,
  creatingWorkflowAIModelTaskExampleFailed: false,

  updatingWorkflowAIModelTaskExample: false,
  updatedWorkflowAIModelTaskExample: false,
  updatingWorkflowAIModelTaskExampleFailed: false,

  deletingWorkflowAIModelTaskExample: false,
  deletedWorkflowAIModelTaskExample: false,
  deletingWorkflowAIModelTaskExampleFailed: false,

  testingWorkflowAIModel: false,
  testedWorkflowAIModel: false,
  testingWorkflowAIModelFailed: false,
};

export const nlpWorkflowsReducer = createReducer<INLPWorkflowsState>(initialState, {
  // GET_WORKFLOW_AI_MODELS
  [GET_WORKFLOW_AI_MODELS]: (state: INLPWorkflowsState) => ({
    ...state,
    fetchingWorkflowAIModel: true,
    fetchedWorkflowAIModel: false,
    fetchingWorkflowAIModelFailed: false,
  }),
  [GET_WORKFLOW_AI_MODELS_SUCCESS]: (
    state: INLPWorkflowsState,
    action: ActionType<typeof getWorkflowsAIModelsSuccessAction>,
  ) => ({
    ...state,
    workflowAIModel: action.payload,
    fetchingWorkflowAIModel: false,
    fetchedWorkflowAIModel: true,
    fetchingWorkflowAIModelFailed: false,
  }),
  [GET_WORKFLOW_AI_MODELS_FAILURE]: (state: INLPWorkflowsState) => ({
    ...state,
    fetchingWorkflowAIModel: false,
    fetchedWorkflowAIModel: false,
    fetchingWorkflowAIModelFailed: true,
  }),

  // POST_WORKFLOW_AI_MODELS
  [POST_WORKFLOW_AI_MODELS]: (state: INLPWorkflowsState) => ({
    ...state,
    postingWorkflowAIModel: true,
    postedWorkflowAIModel: false,
    postingWorkflowAIModelFailed: false,
  }),
  [POST_WORKFLOW_AI_MODELS_SUCCESS]: (
    state: INLPWorkflowsState,
    action: ActionType<typeof postWorkflowsAIModelsSuccessAction>,
  ) => ({
    ...state,
    workflowAIModel: action.payload,
    postingWorkflowAIModel: false,
    postedWorkflowAIModel: true,
    postingWorkflowAIModelFailed: false,
  }),
  [POST_WORKFLOW_AI_MODELS_FAILURE]: (state: INLPWorkflowsState) => ({
    ...state,
    postingWorkflowAIModel: false,
    postedWorkflowAIModel: false,
    postingWorkflowAIModelFailed: true,
  }),
  [CLEAR_POST_WORKFLOW_AI_MODELS]: (state: INLPWorkflowsState) => ({
    ...state,
    postingWorkflowAIModel: false,
    postedWorkflowAIModel: false,
    postingWorkflowAIModelFailed: false,
  }),

  // CREATE_WORKFLOW_AI_MODELS_TASK
  [CREATE_WORKFLOW_AI_MODELS_TASK]: (state: INLPWorkflowsState) => ({
    ...state,
    createdAIModelTask: null,
    creatingWorkflowAIModelTask: true,
    createdWorkflowAIModelTask: false,
    creatingWorkflowAIModelTaskFailed: false,
  }),
  [CREATE_WORKFLOW_AI_MODELS_TASK_SUCCESS]: (
    state: INLPWorkflowsState,
    action: ActionType<typeof createWorkflowsAIModelTaskSuccessAction>,
  ) => ({
    ...state,
    createdAIModelTask: action.payload,
    creatingWorkflowAIModelTask: false,
    createdWorkflowAIModelTask: true,
    creatingWorkflowAIModelTaskFailed: false,
  }),
  [CREATE_WORKFLOW_AI_MODELS_TASK_FAILURE]: (state: INLPWorkflowsState) => ({
    ...state,
    createdAIModelTask: null,
    creatingWorkflowAIModelTask: false,
    createdWorkflowAIModelTask: false,
    creatingWorkflowAIModelTaskFailed: true,
  }),
  [CLEAR_CREATE_WORKFLOW_AI_MODELS_TASK]: (state: INLPWorkflowsState) => ({
    ...state,
    createdAIModelTask: null,
    creatingWorkflowAIModelTask: false,
    createdWorkflowAIModelTask: false,
    creatingWorkflowAIModelTaskFailed: false,
  }),

  // EDIT_WORKFLOW_AI_MODELS_TASK
  [EDIT_WORKFLOW_AI_MODELS_TASK]: (state: INLPWorkflowsState) => ({
    ...state,
    updatingWorkflowAIModelTask: true,
    updatedWorkflowAIModelTask: false,
    updatingWorkflowAIModelTaskFailed: false,
  }),
  [EDIT_WORKFLOW_AI_MODELS_TASK_SUCCESS]: (
    state: INLPWorkflowsState,
    action: ActionType<typeof editWorkflowsAIModelsTaskSuccessAction>,
  ) => ({
    ...state,
    workflowAIModel: action.payload,
    updatingWorkflowAIModelTask: false,
    updatedWorkflowAIModelTask: true,
    updatingWorkflowAIModelTaskFailed: false,
  }),
  [EDIT_WORKFLOW_AI_MODELS_TASK_FAILURE]: (state: INLPWorkflowsState) => ({
    ...state,
    updatingWorkflowAIModelTask: false,
    updatedWorkflowAIModelTask: false,
    updatingWorkflowAIModelTaskFailed: true,
  }),
  [CLEAR_EDIT_WORKFLOW_AI_MODELS_TASK]: (state: INLPWorkflowsState) => ({
    ...state,
    updatingWorkflowAIModelTask: false,
    updatedWorkflowAIModelTask: false,
    updatingWorkflowAIModelTaskFailed: false,
  }),

  // DELETE_WORKFLOW_AI_MODELS_TASK
  [DELETE_WORKFLOW_AI_MODELS_TASK]: (state: INLPWorkflowsState) => ({
    ...state,
    deletingWorkflowAIModelTask: true,
    deletedWorkflowAIModelTask: false,
    deletingWorkflowAIModelTaskFailed: false,
  }),
  [DELETE_WORKFLOW_AI_MODELS_TASK_SUCCESS]: (state: INLPWorkflowsState) => ({
    ...state,
    deletingWorkflowAIModelTask: false,
    deletedWorkflowAIModelTask: true,
    deletingWorkflowAIModelTaskFailed: false,
  }),
  [DELETE_WORKFLOW_AI_MODELS_TASK_FAILURE]: (state: INLPWorkflowsState) => ({
    ...state,
    deletingWorkflowAIModelTask: false,
    deletedWorkflowAIModelTask: false,
    deletingWorkflowAIModelTaskFailed: true,
  }),

  // CREATE_WORKFLOW_AI_MODELS_TASK_EXAMPLES
  [CREATE_WORKFLOW_AI_MODELS_TASK_EXAMPLES]: (state: INLPWorkflowsState) => ({
    ...state,
    creatingWorkflowAIModelTaskExample: true,
    createdWorkflowAIModelTaskExample: null,
    creatingWorkflowAIModelTaskExampleFailed: false,
  }),
  [CREATE_WORKFLOW_AI_MODELS_TASK_EXAMPLES_SUCCESS]: (
    state: INLPWorkflowsState,
    action: ActionType<typeof createWorkflowAIModelsTaskExamplesSuccessAction>,
  ) => ({
    ...state,
    creatingWorkflowAIModelTaskExample: false,
    createdWorkflowAIModelTaskExample: action.payload.id,
    creatingWorkflowAIModelTaskExampleFailed: false,
  }),
  [CREATE_WORKFLOW_AI_MODELS_TASK_EXAMPLES_FAILURE]: (state: INLPWorkflowsState) => ({
    ...state,
    creatingWorkflowAIModelTaskExample: false,
    createdWorkflowAIModelTaskExample: null,
    creatingWorkflowAIModelTaskExampleFailed: true,
  }),
  [CLEAR_CREATE_WORKFLOW_AI_MODELS_TASK_EXAMPLES]: (state: INLPWorkflowsState) => ({
    ...state,
    creatingWorkflowAIModelTaskExample: false,
    createdWorkflowAIModelTaskExample: null,
    creatingWorkflowAIModelTaskExampleFailed: false,
  }),

  // EDIT_WORKFLOW_AI_MODELS_TASK_EXAMPLE
  [EDIT_WORKFLOW_AI_MODELS_TASK_EXAMPLE]: (state: INLPWorkflowsState) => ({
    ...state,
    updatingWorkflowAIModelTaskExample: true,
    updatedWorkflowAIModelTaskExample: false,
    updatingWorkflowAIModelTaskExampleFailed: false,
  }),
  [EDIT_WORKFLOW_AI_MODELS_TASK_EXAMPLE_SUCCESS]: (state: INLPWorkflowsState) => ({
    ...state,
    updatingWorkflowAIModelTaskExample: false,
    updatedWorkflowAIModelTaskExample: true,
    updatingWorkflowAIModelTaskExampleFailed: false,
  }),
  [EDIT_WORKFLOW_AI_MODELS_TASK_EXAMPLE_FAILURE]: (state: INLPWorkflowsState) => ({
    ...state,
    updatingWorkflowAIModelTaskExample: false,
    updatedWorkflowAIModelTaskExample: false,
    updatingWorkflowAIModelTaskExampleFailed: true,
  }),

  // DELETE_WORKFLOW_AI_MODELS_TASK_EXAMPLE
  [DELETE_WORKFLOW_AI_MODELS_TASK_EXAMPLE]: (state: INLPWorkflowsState) => ({
    ...state,
    deletingWorkflowAIModelTaskExample: true,
    deletedWorkflowAIModelTaskExample: false,
    deletingWorkflowAIModelTaskExampleFailed: false,
  }),
  [DELETE_WORKFLOW_AI_MODELS_TASK_EXAMPLE_SUCCESS]: (state: INLPWorkflowsState) => ({
    ...state,
    deletingWorkflowAIModelTaskExample: false,
    deletedWorkflowAIModelTaskExample: true,
    deletingWorkflowAIModelTaskExampleFailed: false,
  }),
  [DELETE_WORKFLOW_AI_MODELS_TASK_EXAMPLE_FAILURE]: (state: INLPWorkflowsState) => ({
    ...state,
    deletingWorkflowAIModelTaskExample: false,
    deletedWorkflowAIModelTaskExample: false,
    deletingWorkflowAIModelTaskExampleFailed: true,
  }),

  // TEST_WORKFLOW_AI_MODEL
  [TEST_WORKFLOW_AI_MODEL]: (state: INLPWorkflowsState) => ({
    ...state,
    testingWorkflowAIModel: true,
    testedWorkflowAIModel: false,
    testingWorkflowAIModelFailed: false,
  }),
  [TEST_WORKFLOW_AI_MODEL_SUCCESS]: (
    state: INLPWorkflowsState,
    action: ActionType<typeof testWorkflowAIModelSuccessAction>,
  ) => ({
    ...state,
    testingWorkflowAIModel: false,
    testingWorkflowAIModelResult: action.payload.items,
    testedWorkflowAIModel: true,
    testingWorkflowAIModelFailed: false,
  }),
  [TEST_WORKFLOW_AI_MODEL_FAILURE]: (state: INLPWorkflowsState) => ({
    ...state,
    testingWorkflowAIModel: false,
    testedWorkflowAIModel: false,
    testingWorkflowAIModelFailed: true,
  }),
});
