import {
  GET_COMPANY_SETTINGS,
  GET_COMPANY_SETTINGS_SUCCESS,
  GET_COMPANY_SETTINGS_FAILURE,
  UPDATE_FEATURE_VISIBILITY_FLAGS,
  UPDATE_FEATURE_VISIBILITY_FLAGS_SUCCESS,
  UPDATE_FEATURE_VISIBILITY_FLAGS_FAILURE,
  UPDATE_INTEGRATIONS_FLAGS,
  UPDATE_INTEGRATIONS_FLAGS_SUCCESS,
  UPDATE_INTEGRATIONS_FLAGS_FAILURE,
  UPDATE_NUMERIC_FLAGS_FAILURE,
  UPDATE_NUMERIC_FLAGS_SUCCESS,
  UPDATE_NUMERIC_FLAGS,
  RESET_UPDATED_DYNAMIC_FLAGS,
} from "./actionTypes";
import { createAction } from "typesafe-actions";
import { IFeatureVisibilityFlag, ICompanySettings, IIntegrationFlag, IDynamicFlag } from "./types";

export const getCompanySettingsAction = createAction(GET_COMPANY_SETTINGS)<void>();
export const getCompanySettingsSuccessAction = createAction(
  GET_COMPANY_SETTINGS_SUCCESS,
)<ICompanySettings>();
export const getCompanySettingsFailureAction = createAction(GET_COMPANY_SETTINGS_FAILURE)<void>();

export const updateFeatureVisibilityFlagsAction = createAction(UPDATE_FEATURE_VISIBILITY_FLAGS)<
  IFeatureVisibilityFlag[]
>();
export const updateFeatureVisibilityFlagsSuccessAction = createAction(
  UPDATE_FEATURE_VISIBILITY_FLAGS_SUCCESS,
)<void>();
export const updateFeatureVisibilityFlagsFailureAction = createAction(
  UPDATE_FEATURE_VISIBILITY_FLAGS_FAILURE,
)<string>();

export const updateIntegrationsFlagsAction =
  createAction(UPDATE_INTEGRATIONS_FLAGS)<IIntegrationFlag[]>();
export const updateIntegrationsFlagsSuccessAction = createAction(
  UPDATE_INTEGRATIONS_FLAGS_SUCCESS,
)<void>();
export const updateIntegrationsFlagsFailureAction = createAction(
  UPDATE_INTEGRATIONS_FLAGS_FAILURE,
)<string>();

export const updateDynamicFlagsAction = createAction(UPDATE_NUMERIC_FLAGS)<IDynamicFlag[]>();
export const updateDynamicFlagsSuccessAction = createAction(UPDATE_NUMERIC_FLAGS_SUCCESS)<void>();
export const updateDynamicFlagsFailureAction = createAction(UPDATE_NUMERIC_FLAGS_FAILURE)<string>();
export const resetUpdatedDynamicFlagsAction = createAction(RESET_UPDATED_DYNAMIC_FLAGS)<void>();
