export const GET_WORKFLOW_AI_MODELS = "GET_WORKFLOW_AI_MODELS";
export const GET_WORKFLOW_AI_MODELS_SUCCESS = "GET_WORKFLOW_AI_MODELS_SUCCESS";
export const GET_WORKFLOW_AI_MODELS_FAILURE = "GET_WORKFLOW_AI_MODELS_FAILURE";

export const POST_WORKFLOW_AI_MODELS = "POST_WORKFLOW_AI_MODELS";
export const POST_WORKFLOW_AI_MODELS_SUCCESS = "POST_WORKFLOW_AI_MODELS_SUCCESS";
export const POST_WORKFLOW_AI_MODELS_FAILURE = "POST_WORKFLOW_AI_MODELS_FAILURE";
export const CLEAR_POST_WORKFLOW_AI_MODELS = "CLEAR_POST_WORKFLOW_AI_MODELS";

export const CREATE_WORKFLOW_AI_MODELS_TASK = "CREATE_WORKFLOW_AI_MODELS_TASK";
export const CREATE_WORKFLOW_AI_MODELS_TASK_SUCCESS = "CREATE_WORKFLOW_AI_MODELS_TASK_SUCCESS";
export const CREATE_WORKFLOW_AI_MODELS_TASK_FAILURE = "CREATE_WORKFLOW_AI_MODELS_TASK_FAILURE";
export const CLEAR_CREATE_WORKFLOW_AI_MODELS_TASK = "CLEAR_CREATE_WORKFLOW_AI_MODELS_TASK";

export const EDIT_WORKFLOW_AI_MODELS_TASK = "EDIT_WORKFLOW_AI_MODELS_TASK";
export const EDIT_WORKFLOW_AI_MODELS_TASK_SUCCESS = "EDIT_WORKFLOW_AI_MODELS_TASK_SUCCESS";
export const EDIT_WORKFLOW_AI_MODELS_TASK_FAILURE = "EDIT_WORKFLOW_AI_MODELS_TASK_FAILURE";
export const CLEAR_EDIT_WORKFLOW_AI_MODELS_TASK = "CLEAR_EDIT_WORKFLOW_AI_MODELS_TASK";

export const DELETE_WORKFLOW_AI_MODELS_TASK = "DELETE_WORKFLOW_AI_MODELS_TASK";
export const DELETE_WORKFLOW_AI_MODELS_TASK_SUCCESS = "DELETE_WORKFLOW_AI_MODELS_TASK_SUCCESS";
export const DELETE_WORKFLOW_AI_MODELS_TASK_FAILURE = "DELETE_WORKFLOW_AI_MODELS_TASK_FAILURE";

export const CREATE_WORKFLOW_AI_MODELS_TASK_EXAMPLES = "CREATE_WORKFLOW_AI_MODELS_TASK_EXAMPLES";
export const CREATE_WORKFLOW_AI_MODELS_TASK_EXAMPLES_SUCCESS =
  "CREATE_WORKFLOW_AI_MODELS_TASK_EXAMPLES_SUCCESS";
export const CREATE_WORKFLOW_AI_MODELS_TASK_EXAMPLES_FAILURE =
  "CREATE_WORKFLOW_AI_MODELS_TASK_EXAMPLES_FAILURE";
export const CLEAR_CREATE_WORKFLOW_AI_MODELS_TASK_EXAMPLES =
  "CLEAR_CREATE_WORKFLOW_AI_MODELS_TASK_EXAMPLES";

export const EDIT_WORKFLOW_AI_MODELS_TASK_EXAMPLE = "EDIT_WORKFLOW_AI_MODELS_TASK_EXAMPLE";
export const EDIT_WORKFLOW_AI_MODELS_TASK_EXAMPLE_SUCCESS =
  "EDIT_WORKFLOW_AI_MODELS_TASK_EXAMPLE_SUCCESS";
export const EDIT_WORKFLOW_AI_MODELS_TASK_EXAMPLE_FAILURE =
  "EDIT_WORKFLOW_AI_MODELS_TASK_EXAMPLE_FAILURE";

export const DELETE_WORKFLOW_AI_MODELS_TASK_EXAMPLE = "DELETE_WORKFLOW_AI_MODELS_TASK_EXAMPLE";
export const DELETE_WORKFLOW_AI_MODELS_TASK_EXAMPLE_SUCCESS =
  "DELETE_WORKFLOW_AI_MODELS_TASK_EXAMPLE_SUCCESS";
export const DELETE_WORKFLOW_AI_MODELS_TASK_EXAMPLE_FAILURE =
  "DELETE_WORKFLOW_AI_MODELS_TASK_EXAMPLE_FAILURE";

export const TEST_WORKFLOW_AI_MODEL = "TEST_WORKFLOW_AI_MODEL";
export const TEST_WORKFLOW_AI_MODEL_SUCCESS = "TEST_WORKFLOW_AI_MODEL_SUCCESS";
export const TEST_WORKFLOW_AI_MODEL_FAILURE = "TEST_WORKFLOW_AI_MODEL_FAILURE";
