import React from "react";

import ReactConfetti from "react-confetti";

export interface IConfettiProps {
  colors?: string[];
  numberOfPieces?: number;
  recycle?: boolean;
  gravity?: number;
  initialVelocityX?: number;
  initialVelocityY?: number;
  drawShape?: (context: CanvasRenderingContext2D) => void;
  width?: number;
}

export const Confetti: React.FC<IConfettiProps> = props => {
  return <ReactConfetti {...props} />;
};
