import { createReducer } from "typesafe-actions";
import produce from "immer";

import * as actions from "./actions";

import {
  IAIHighlightsState,
  IAIHighlightsActions,
  WidgetStateEnum,
  HighlightTypeEnum,
} from "./types";

const emptyRequestState = {
  fetching: false,
  fetched: false,
  fetchFail: false,
  data: undefined,
};

const initialState: IAIHighlightsState = {
  data: {},
  initialFetchDone: {
    [HighlightTypeEnum.COMMENTS]: false,
    [HighlightTypeEnum.POSTS]: false,
  },
  widgetState: {
    [HighlightTypeEnum.COMMENTS]: WidgetStateEnum.COLLAPSED,
    [HighlightTypeEnum.POSTS]: WidgetStateEnum.COLLAPSED,
  },
  getAiHighlights: {
    [HighlightTypeEnum.COMMENTS]: { ...emptyRequestState },
    [HighlightTypeEnum.POSTS]: { ...emptyRequestState },
  },
};

export const aiHighlightsReducer = createReducer<IAIHighlightsState, IAIHighlightsActions>(
  initialState,
)
  .handleAction(actions.getAiHighlightsAction, (state, { payload }) =>
    produce(state, draft => {
      const { type } = payload;
      draft.getAiHighlights[type].fetching = true;
      draft.getAiHighlights[type].fetched = false;
      draft.getAiHighlights[type].fetchFail = false;
    }),
  )
  .handleAction(actions.getAiHighlightsSuccess, (state, { payload }) =>
    produce(state, draft => {
      const { type, data } = payload;
      draft.getAiHighlights[type].fetching = false;
      draft.getAiHighlights[type].fetched = true;
      draft.getAiHighlights[type].data = data;
      draft.data[type] = data;
      draft.initialFetchDone[type] = true;
    }),
  )
  .handleAction(actions.getAiHighlightsFailure, (state, { payload }) =>
    produce(state, draft => {
      const { type } = payload;
      draft.getAiHighlights[type].fetching = false;
      draft.getAiHighlights[type].fetchFail = true;
    }),
  )
  .handleAction(actions.resetAiHighlightsAction, (state, { payload }) =>
    produce(state, draft => {
      if (payload?.type) {
        const { type } = payload;
        const initialFetchDone = draft.initialFetchDone[type];

        draft.data[type] = undefined;
        draft.initialFetchDone[type] = initialFetchDone;
        draft.widgetState[type] = WidgetStateEnum.COLLAPSED;
        draft.getAiHighlights[type] = { ...emptyRequestState };
      } else {
        Object.assign(draft, initialState);
      }
    }),
  )
  .handleAction(actions.setWidgetStateAction, (state, { payload }) =>
    produce(state, draft => {
      const { type, state: widgetState } = payload;
      draft.widgetState[type] = widgetState;
    }),
  );
