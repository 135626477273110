import React from "react";
import { useTranslation } from "react-i18next";

import s from "./NoMatch.module.less";

import IllustrationSearch from "@assets/IllustrationSearch.svg";

export const NoMatch = () => {
  const { t } = useTranslation();

  return (
    <div className={s.bbNoMatch}>
      <IllustrationSearch />
      <h2 className={s.bbNoMatchTitle}>
        {t("pages:workflows:workflowCreation:step3:noMatch:title")}
      </h2>
      <p className={s.bbNoMatchDescription}>
        {t("pages:workflows:workflowCreation:step3:noMatch:description")}
      </p>
      <p className={s.bbNoMatchInfo}>{t("pages:workflows:workflowCreation:step3:noMatch:info")}</p>
    </div>
  );
};
