import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  ChevronDownIcon,
  MagicWandIcon,
  SettingsIcon,
  SimpleSelectSingle,
} from "@bbdevcrew/bb_ui_kit_fe";
import Markdown from "react-markdown";
import { CustomPrompts } from "@components/insights/overview/AIHighlights/CustomPrompts/CustomPrompts"; // eslint-disable-line

import { meSelector } from "@store/me/selectors";
import { aiHighlightPromptsSelector } from "@store/aiHighlightsPrompts/selectors";

import { sendThreadMessageAction } from "@store/aiInsights/actions";

import s from "./AIInsightsModal.module.less";

import { getUserInitials } from "@bbdevcrew/bb_ui_kit_fe";
import { AI_ICONS_ACTIONS_MAP } from "./AIInsightsModal.helpers";
import { IAIInsightsModalMessageProps } from "./AIInsightsModal.types";

import { CheckIcon } from "@bbdevcrew/bb_ui_kit_fe";

const cx = classNames.bind(s);

export const AIInsightsModalMessage: React.FC<IAIInsightsModalMessageProps> = ({
  message,
  isOwned = false,
  actions = [],
  id: aiModalId,
  loading,
  promptId,
  setPromptId,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [query, setQuery] = useState<string>("");

  const customPrompts = useSelector(aiHighlightPromptsSelector);
  const me = useSelector(meSelector);

  const customPromptsOptions = useMemo(() => {
    return customPrompts
      .filter(prompt => !prompt.id.endsWith("-default"))
      .filter(prompt => prompt.name.toLowerCase().includes(query.toLowerCase()))
      .map(prompt => ({
        label: prompt.name,
        value: prompt.id,
        tooltip: t("components:aiInsights:promptTooltip"),
      }));
  }, [customPrompts, query, t]);

  const sendMessages = useCallback(
    (id, nextMessage, message_arguments?) =>
      dispatch(
        sendThreadMessageAction({
          id,
          message: nextMessage,
          message_arguments,
        }),
      ),
    [dispatch],
  );

  const handleSelectPrompt = (actionType: string, nextPromptId: string) => {
    if (nextPromptId.endsWith("-default")) return;
    setPromptId?.(nextPromptId);
    sendMessages(aiModalId, actionType, { prompt_id: nextPromptId });
  };

  return (
    <>
      {!!message && (
        <div
          className={cx(s.bbAIModalMessage, {
            [s.bbAIModalRightMessage]: isOwned,
          })}
        >
          {isOwned && (
            <div className={s.bbAIModalMessageUser}>
              {getUserInitials(`${me?.first_name} ${me?.last_name}`)}
            </div>
          )}

          <div className={s.bbAIModalMessageContent}>
            {!isOwned && <span>{t("components:aiInsights:astra")}</span>}
            <div className={s.bbAIModalMessageText}>
              <Markdown>{message}</Markdown>
            </div>
          </div>
        </div>
      )}
      <div
        className={cx(s.bbAIModalActions, {
          [s.bbAIModalActionsActive]: actions.some(action => action.is_selected),
        })}
      >
        {actions.map(action => {
          if (action.type === "generate_analysis_custom_prompt") {
            if (action.is_selected) {
              return (
                <Button
                  _type="secondary"
                  className={cx(s.bbAIModalActionsButton, s.bbAIModalActionsButtonDisabled)}
                  key={action.id}
                  disabled={loading}
                >
                  <CheckIcon />
                  {customPrompts.find(p => p.id === promptId)?.name}
                </Button>
              );
            }

            return (
              <CustomPrompts
                onSelectPrompt={selectedPrompt =>
                  handleSelectPrompt(action.type, selectedPrompt.id)
                }
                key={action.id}
              >
                {handleOpen => (
                  <SimpleSelectSingle
                    allowSearch
                    showResultsBeforeSearch
                    onSearch={nextValue => {
                      setQuery(nextValue || "");
                    }}
                    options={customPromptsOptions}
                    value={promptId}
                    trigger={() => (
                      <Button
                        _type="primary"
                        className={cx(s.bbAIModalActionsButton, s.bbAIModalActionsButtonDropdown)}
                        disabled={loading}
                      >
                        <MagicWandIcon /> {action.label} <ChevronDownIcon />
                      </Button>
                    )}
                    onChange={nextPromptId =>
                      handleSelectPrompt(action.type, nextPromptId as string)
                    }
                    footer={
                      <div className={s.bbCustomPromptsFooter}>
                        <Button
                          _type="link"
                          className={s.bbCustomPromptsFooterButton}
                          onClick={handleOpen}
                        >
                          <SettingsIcon /> {t("components:aiInsights:managePrompts")}
                        </Button>
                      </div>
                    }
                  />
                )}
              </CustomPrompts>
            );
          }

          return (
            <Button
              _type="secondary"
              className={cx(s.bbAIModalActionsButton, {
                [s.bbAIModalActionsButtonDisabled]: action.is_selected,
              })}
              key={action.id}
              disabled={loading}
              onClick={!action.is_selected ? () => sendMessages(aiModalId, action.type) : undefined}
            >
              {action.is_selected ? (
                <CheckIcon />
              ) : (
                AI_ICONS_ACTIONS_MAP[action.type as keyof typeof AI_ICONS_ACTIONS_MAP] || null
              )}
              {action.label}
            </Button>
          );
        })}
      </div>
    </>
  );
};
