import { TFunction } from "i18next";
import { ZendeskTicketStatus, ZendeskTicketPriority } from "@bbdevcrew/bb_ui_kit_fe";

const getStatusesByCurrentStatus = (t: TFunction) => {
  const NEW_STATUS_OPTION = {
    label: t("components:zendeskForm:status:options:new"),
    id: ZendeskTicketStatus.New,
  };
  const OPEN_STATUS_OPTION = {
    label: t("components:zendeskForm:status:options:open"),
    id: ZendeskTicketStatus.Open,
  };
  const PENDING_STATUS_OPTION = {
    label: t("components:zendeskForm:status:options:pending"),
    id: ZendeskTicketStatus.Pending,
  };
  const SOLVED_STATUS_OPTION = {
    label: t("components:zendeskForm:status:options:solved"),
    id: ZendeskTicketStatus.Solved,
  };
  const CLOSED_STATUS_OPTION = {
    label: t("components:zendeskForm:status:options:closed"),
    id: ZendeskTicketStatus.Closed,
  };

  return {
    [ZendeskTicketStatus.New]: [
      NEW_STATUS_OPTION,
      OPEN_STATUS_OPTION,
      PENDING_STATUS_OPTION,
      SOLVED_STATUS_OPTION,
    ],
    [ZendeskTicketStatus.Open]: [OPEN_STATUS_OPTION, PENDING_STATUS_OPTION, SOLVED_STATUS_OPTION],
    [ZendeskTicketStatus.Pending]: [
      PENDING_STATUS_OPTION,
      OPEN_STATUS_OPTION,
      SOLVED_STATUS_OPTION,
    ],
    [ZendeskTicketStatus.Solved]: [SOLVED_STATUS_OPTION, OPEN_STATUS_OPTION, CLOSED_STATUS_OPTION],
    [ZendeskTicketStatus.Closed]: [CLOSED_STATUS_OPTION],
  };
};

export const getStatusOptions = (currentStatus: ZendeskTicketStatus, t: TFunction) =>
  getStatusesByCurrentStatus(t)[currentStatus] || [];

export const getPriorityOptions = (t: TFunction) => [
  {
    label: t("components:zendeskForm:priority:options:urgent"),
    id: ZendeskTicketPriority.Urgent,
  },
  {
    label: t("components:zendeskForm:priority:options:high"),
    id: ZendeskTicketPriority.High,
  },
  {
    label: t("components:zendeskForm:priority:options:normal"),
    id: ZendeskTicketPriority.Normal,
  },
  {
    label: t("components:zendeskForm:priority:options:low"),
    id: ZendeskTicketPriority.Low,
  },
];
