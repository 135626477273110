import { createReducer, ActionType } from "typesafe-actions";
import produce from "immer";

import * as actions from "./actions";

import { DashboardComponentType, IAvailableSectionsType, IModulesState } from "./types";
import { IInboxStats, IModuleFeature } from "@bbdevcrew/bb_ui_kit_fe";

const initialState: IModulesState = {
  modules: {},

  fetchingModuleSectionsList: false,
  fetchedModuleSectionsList: false,
  fetchedModuleSectionsListFail: false,
};

export const modulesReducer = createReducer<IModulesState, ActionType<typeof actions>>(initialState)
  .handleAction(actions.getModuleSectionsListAction, state =>
    produce(state, draft => {
      draft.fetchingModuleSectionsList = true;
      draft.fetchedModuleSectionsList = false;
      draft.fetchedModuleSectionsListFail = false;
    }),
  )
  .handleAction(actions.getModuleSectionsListSuccessAction, (state, action) =>
    produce(state, draft => {
      draft.modules[action.payload.id as IAvailableSectionsType] = action.payload.items;
      draft.fetchingModuleSectionsList = false;
      draft.fetchedModuleSectionsList = true;
      draft.fetchedModuleSectionsListFail = false;
    }),
  )
  .handleAction(actions.getModuleSectionsListFailureAction, (state, action) =>
    produce(state, draft => {
      draft.modules[action.payload.id as IAvailableSectionsType] = [];
      draft.fetchingModuleSectionsList = false;
      draft.fetchedModuleSectionsList = false;
      draft.fetchedModuleSectionsListFail = true;
    }),
  )
  .handleAction(actions.resetModuleSectionsListAction, state =>
    produce(state, draft => {
      draft.modules = {};
      draft.fetchingModuleSectionsList = false;
      draft.fetchedModuleSectionsList = false;
      draft.fetchedModuleSectionsListFail = false;
    }),
  )
  .handleAction(actions.setCareModuleInboxStatsAction, (state, action) => {
    return produce(state, draft => {
      const careModule = state.modules.care;
      if (careModule) {
        const mapStats = (
          item: IModuleFeature<DashboardComponentType>,
        ): IModuleFeature<DashboardComponentType> => {
          const totalCount = Number(
            action.payload.inboxStats.stats?.[item.id as keyof IInboxStats] || 0,
          );
          return {
            ...item,
            counts: totalCount
              ? [
                  {
                    id: "total",
                    count: totalCount,
                  },
                ]
              : [],
            features: item.features?.map(mapStats),
            is_visible:
              item.features?.some(feature => feature.id === "errors") &&
              Number(action.payload.inboxStats.stats?.errors) === 0
                ? false
                : true,
          };
        };
        draft.modules.care = careModule.map(mapStats);
      }
    });
  });
