import classNames from "classnames/bind";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, useMemo } from "react";

import { Button, SelectStyled } from "@bbdevcrew/bb_ui_kit_fe";

import { getAutocompleteOptionsAction } from "@store/autocomplete/actions";
import { autocompleteOptionsSelector } from "@store/autocomplete/selectors";

import s from "./TagWorkflowAction.module.less";

import { postTagToOption } from "./TagWorkflowAction.helpers";
import { IFormComponent } from "@components/control-panel/workflows/types";
import { debounce, useSearch, IGetAutocompleteOptionsPayload } from "@bbdevcrew/bb_ui_kit_fe";

import { ChevronDownIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const CustomPostTagWorkflowAction: React.FC<IFormComponent<string[]>> = ({
  value,
  onChange,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const postTags = useSelector(autocompleteOptionsSelector).post_tags;

  const optionsFormatted = useMemo(() => {
    return postTags.map(postTagToOption);
  }, [postTags]);

  const { options, SearchInput, searchTerm, setSearchTerm } = useSearch(
    optionsFormatted,
    t("generic:search"),
  );

  // eslint-disable-next-line
  const getAutocompleteOptions = useCallback(
    debounce((payload: IGetAutocompleteOptionsPayload) => {
      dispatch(getAutocompleteOptionsAction(payload));
    }, 300),
    [dispatch],
  );

  useEffect(() => {
    getAutocompleteOptions({
      field: "post_tags",
      query: searchTerm,
    });
  }, [getAutocompleteOptions, searchTerm]);

  const getSelectedPostTagLabel = useCallback(
    (tagId: string) => {
      return postTags.find(tag => tag.id === tagId)?.label || "";
    },
    [postTags],
  );

  return (
    <SelectStyled
      options={options}
      wrapperClassName={s.bbTagActionTypeSelect}
      onChange={val => onChange?.([val])}
      value={value?.[0]}
      highlightTerm={searchTerm}
      dropdownPlacement="topRight"
      onDropdownClose={() => setSearchTerm("")}
      DropdownHeader={
        <div className={s.bbTagsDropdownHeader}>
          <span>{t("pages:workflows:form:triggers:time_frame:tags:label")}</span>
          {SearchInput}
        </div>
      }
      TriggerVariation={
        <Button className={s.bbWorkflowItemFormTagSelect} type="button">
          <span
            className={classNames(s.bbWorkflowItemFormTagSelectLabel, {
              [s.bbWorkflowItemFormTagSelectPlaceholder]: !(
                value?.[0] && getSelectedPostTagLabel(value?.[0])
              ),
            })}
          >
            {(value?.[0] && getSelectedPostTagLabel(value?.[0])) ||
              t("pages:workflows:form:actions:tag:placeholder")}
          </span>
          <i className={s.bbWorkflowItemFormTagSelectChevron}>
            <ChevronDownIcon />
          </i>
        </Button>
      }
    />
  );
};
