import { careAppFeature } from "@store/me/selectors";
import { RootState } from "..";

export const fromModules = (state: RootState) => state.modules;

export const getAllModulesSectionsSelector = (state: RootState) => fromModules(state).modules;

export const getModuleSectionsPendingSelector = (state: RootState) =>
  fromModules(state).fetchingModuleSectionsList;
export const getModuleSectionsSelectorSuccessful = (state: RootState) =>
  fromModules(state).fetchedModuleSectionsList;
export const getModuleSectionsSelectorFailure = (state: RootState) =>
  fromModules(state).fetchedModuleSectionsListFail;

export const isSmartInboxSelector = (state: RootState) => {
  const feature = careAppFeature(state);
  const subfeatures = feature?.subfeatures || [];
  return subfeatures.find(({ is_disabled }) => !is_disabled)?.id === "smart-inbox";
};
