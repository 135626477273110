import { TFunction } from "i18next";

export const CategoryDescriptionMinLimit = 100;
export const CategoryDescriptionMaxLimit = 5000;
export const ExampleDescriptionMaxLimit = 1000;
export const ExclusionsDescriptionMaxLimit = 5000;
export const TaskDescriptionMaxLimit = 5000;
export const ExampleMaxLimit = 280;
export const MessageMaxLimit = 500;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const filterObjectKeys = (obj: Record<string, any>, keysToRemove: string[]) => {
  return Object.fromEntries(Object.entries(obj).filter(([key]) => !keysToRemove.includes(key)));
};

export const getTagScoreOptions = (t: TFunction, shouldTag: boolean) => {
  const transBase = `pages:workflows:workflowCreation:taggingFlow:rightPanel:step5`;
  const prefix = shouldTag ? "tag" : "noTag";

  return Array.from({ length: 5 }, (_, i) => ({
    value: `${i}`,
    label: t(`${transBase}:${prefix}:${i}-label`),
    description: t(`${transBase}:${prefix}:${i}-description`),
  }));
};
