import { ActionType } from "typesafe-actions";

import * as actions from "./actions";
import { IRequestState } from "@store/types";

export type IAIHighlightsActions = ActionType<typeof actions>;

export enum WidgetStateEnum {
  COLLAPSED = "collapsed",
  EXPANDED = "expanded",
}

export enum HighlightTypeEnum {
  COMMENTS = "comments",
  POSTS = "posts",
}

export interface IAIHighlightsResponse {
  id: string;
  start_date: string;
  end_date: string;
  message: string;
  last_updated: string;
  limit: number;
  consumed: number;
}

export interface TypedAIHighlightsData {
  [HighlightTypeEnum.COMMENTS]?: IAIHighlightsResponse;
  [HighlightTypeEnum.POSTS]?: IAIHighlightsResponse;
}

export interface TypedAIHighlightsRequestState {
  [HighlightTypeEnum.COMMENTS]: IRequestState<IAIHighlightsResponse>;
  [HighlightTypeEnum.POSTS]: IRequestState<IAIHighlightsResponse>;
}

export interface TypedAIHighlightsWidgetState {
  [HighlightTypeEnum.COMMENTS]: WidgetStateEnum;
  [HighlightTypeEnum.POSTS]: WidgetStateEnum;
}

export interface TypedAIHighlightsInitialFetchDone {
  [HighlightTypeEnum.COMMENTS]: boolean;
  [HighlightTypeEnum.POSTS]: boolean;
}

export interface IAIHighlightsState {
  data: TypedAIHighlightsData;
  initialFetchDone: TypedAIHighlightsInitialFetchDone;
  widgetState: TypedAIHighlightsWidgetState;
  getAiHighlights: TypedAIHighlightsRequestState;
}
