import React, { FC } from "react";

import s from "./AINLPModal.module.less";

import { LoadingOutlined } from "@ant-design/icons";

const AINLPModalLoader: FC<{ text?: string }> = ({ text }) => {
  return (
    <div className={s.bbLoaderWrapper}>
      <LoadingOutlined spin className={s.bbLoader} />
      {text && <div className={s.bbLoaderText}>{text}</div>}
    </div>
  );
};

export default AINLPModalLoader;
