import classNames from "classnames";
import { useTranslation } from "react-i18next";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Form } from "antd";
import ExpansionPanel, { ExpansionPanelBadge } from "../../ExpansionPanel";
import { Filters, Button, IFormFilterRequest, Toggle } from "@bbdevcrew/bb_ui_kit_fe";

import { clientDataSelector } from "@store/me/selectors";
import { getAutocompleteOptionsAction } from "@store/autocomplete/actions";
import { autocompleteOptionsSelector } from "@store/autocomplete/selectors";

import s from "./AllFiltersWorkflowTrigger.module.less";

import {
  debounce,
  IFilters,
  IFormHandler,
  IGetAutocompleteOptionsPayload,
} from "@bbdevcrew/bb_ui_kit_fe";
import {
  filterHasNonEmptyValue,
  useWorkflowTriggerFilters,
} from "./AllFiltersWorkflowTrigger.helpers";
import { getFetchValuesFieldName } from "@utils/filters";
import { IAllFiltersWorkflowTrigger, IFiltersPanelProps } from "./AllFiltersWorkflowTrigger.types";

import { ChevronDownIcon, ChevronUpIcon } from "@bbdevcrew/bb_ui_kit_fe";

const FiltersPanel: FC<IFiltersPanelProps> = ({
  title,
  value,
  open,
  setOpen,
  filtersRef,
  workflowType,
  headerSuffix,
  filtersPrefix,
  filtersSuffix,
  hiddenFilters = [],
  additionalFilters = [],
  showViewMore,
  initialFilterData,
  hideCareReviewTag = true,
  hiddenPlatformTypes = [],
  onInternalChange,
  selectFieldsPlaceholder,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [filtersForm] = Form.useForm<IFormFilterRequest>();
  const { workflowTriggerFilters } = useWorkflowTriggerFilters(
    value,
    workflowType,
    hideCareReviewTag,
    hiddenFilters,
    hiddenPlatformTypes,
    additionalFilters,
    selectFieldsPlaceholder,
  );

  const [fullHeight, setFullHeight] = useState(showViewMore ? false : true);

  const clientData = useSelector(clientDataSelector);
  const autocompleteOptions = useSelector(autocompleteOptionsSelector);

  // eslint-disable-next-line
  const getAutocompleteOptions = useCallback(
    debounce((payload: IGetAutocompleteOptionsPayload) => {
      dispatch(getAutocompleteOptionsAction(payload));
    }, 300),
    [dispatch],
  );

  useEffect(() => {
    if (filtersRef?.current && value) {
      filtersRef.current.initializeFormValues(value);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <ExpansionPanel
      title={title}
      open={open}
      onToggle={() => setOpen(!open)}
      headerSuffix={headerSuffix}
    >
      <Filters
        ref={filtersRef}
        form={filtersForm}
        initialFilterData={initialFilterData}
        onChangeCallback={onInternalChange}
        filtersConfig={workflowTriggerFilters}
        onFilter={() => {}} // eslint-disable-line
        clientPlatformTypes={clientData?.platform_types || []}
        className={classNames(
          s.bbAppFiltersFormWrapper,
          s[`bbAppFiltersFormWrapper-${workflowType}`],
          {
            [s.bbAppFiltersFormWrapperFullHeight]: fullHeight,
          },
        )}
        autocompleteOptions={autocompleteOptions}
        getAutocompleteOptions={getAutocompleteOptions}
        sanitizeFieldName={getFetchValuesFieldName}
        disableExclusionOptions
      >
        {{
          prefix: filtersPrefix,
          suffix: filtersSuffix,
        }}
      </Filters>
      {showViewMore && (
        <Button
          className={s.bbViewMoreButton}
          _type="link"
          onClick={() => setFullHeight(!fullHeight)}
        >
          {t(`pages:workflows:form:triggers:email_alert:${fullHeight ? "viewLess" : "viewMore"}`)}
          {fullHeight ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </Button>
      )}
    </ExpansionPanel>
  );
};

export const AllFiltersWorkflowTrigger: FC<IAllFiltersWorkflowTrigger> = ({
  title,
  form,
  workflowType,
  hiddenFilters,
  hiddenPlatformTypes,
  additionalFilters,
  filtersPrefix,
  filtersSuffix,
  showViewMore = true,
  hideExclusionFilters = false,
  hideCareReviewTag,
  initialFilterData,
  initialExlusionFilterData,
  mainFiltersPanelTitle,
  exclusionFiltersPanelTitle,
  showExclusionToggle = true,
  isFiltersPanelInitiallyOpen = true,
}) => {
  const { t } = useTranslation();
  const filtersRef = useRef<IFormHandler>(null);
  const exclusionFiltersRef = useRef<IFormHandler>(null);
  const value = form.getFieldValue("trigger")?.filters || {};
  const excludedValues =
    form.getFieldValue("trigger")?.excluded ||
    form.getFieldValue("trigger")?.filters?.exclusion_filters ||
    {};
  const initialExludedValuesExist = filterHasNonEmptyValue(
    form.getFieldValue("trigger")?.filters?.exclusion_filters,
  );

  const [isFiltersPanelOpen, setIsFiltersPanelOpen] = useState(isFiltersPanelInitiallyOpen);
  const [isExclusionPanelOpen, setIsExclusionPanelOpen] = useState(initialExludedValuesExist);
  const [isExlusionToggleChecked, setIsExclusionToggleChecked] =
    useState(initialExludedValuesExist);

  const onInternalChange = (type: "filters" | "excluded") => (filters: IFilters) => {
    const currentValue = form.getFieldValue("trigger") || {};

    let newValue;

    if (type === "filters") {
      // Moderation start_time, end_time and timezone are not initially part of the filters trigger, they're part of the action
      // so we need to set them manually to be a part of the filters trigger, otherwise they will be lost
      const additionalHideFilters = {
        start_time: currentValue.filters?.start_time,
        end_time: currentValue.filters?.end_time,
        timezone: currentValue.filters?.timezone,
      };

      newValue = {
        ...filters,
        ...((currentValue.filters?.start_time || currentValue.filters?.end_time) &&
          additionalHideFilters),
      };
    } else {
      newValue = { ...currentValue.filters, exclusion_filters: filters };
    }

    form.setFields([
      {
        name: ["trigger", "filters"],
        value: newValue as IFilters,
      },
    ]);
  };

  const onChangeExclusionToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isToggleChecked = e.target.checked;

    setIsExclusionToggleChecked(isToggleChecked);

    const currentValue = form.getFieldValue("trigger") || {};

    if (!isToggleChecked) {
      form.setFields([
        {
          name: ["trigger", "filters"],
          value: {
            ...currentValue.filters,
            exclusion_filters: {},
          },
        },
      ]);

      exclusionFiltersRef.current?.resetFields();
      setIsExclusionPanelOpen(false);
    } else {
      setIsExclusionPanelOpen(true);
    }
  };

  return (
    <div className={s.bbWorkflowEmailFilters}>
      {!!title && <div className={s.bbWorkflowEmailFiltersTitle}>{title}</div>}
      <FiltersPanel
        hideCareReviewTag={hideCareReviewTag}
        filtersRef={filtersRef}
        title={
          mainFiltersPanelTitle || (
            <>
              <ExpansionPanelBadge>
                {t("components:filters:workflows:filters:badge")}
              </ExpansionPanelBadge>
              {t("components:filters:workflows:filters:title")}
            </>
          )
        }
        value={value}
        showViewMore={showViewMore}
        initialFilterData={initialFilterData}
        workflowType={workflowType}
        onInternalChange={onInternalChange("filters")}
        open={isFiltersPanelOpen}
        setOpen={setIsFiltersPanelOpen}
        hiddenFilters={hiddenFilters}
        filtersPrefix={filtersPrefix}
        filtersSuffix={filtersSuffix}
        additionalFilters={additionalFilters}
        hiddenPlatformTypes={hiddenPlatformTypes}
      />
      {!hideExclusionFilters && (
        <FiltersPanel
          hideCareReviewTag
          showViewMore={showViewMore}
          initialFilterData={initialExlusionFilterData}
          filtersRef={exclusionFiltersRef}
          title={
            exclusionFiltersPanelTitle || (
              <>
                <ExpansionPanelBadge danger>
                  {t("components:filters:workflows:excluded:badge")}
                </ExpansionPanelBadge>
                {t("components:filters:workflows:excluded:title")}
              </>
            )
          }
          headerSuffix={
            showExclusionToggle && (
              <Toggle
                id="exclusion_header_toggle"
                checked={isExlusionToggleChecked}
                onChange={onChangeExclusionToggle}
              />
            )
          }
          selectFieldsPlaceholder={t("generic:choose")}
          value={excludedValues}
          workflowType={workflowType}
          onInternalChange={onInternalChange("excluded")}
          open={isExclusionPanelOpen}
          setOpen={setIsExclusionPanelOpen}
          hiddenFilters={hiddenFilters}
          additionalFilters={additionalFilters}
          hiddenPlatformTypes={hiddenPlatformTypes}
        />
      )}
    </div>
  );
};
