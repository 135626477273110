import React, { useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  InternalNotes,
  ReplyModal as ReplyModalComponent,
  ReplyModalProvider,
  useReplyModal,
} from "@bbdevcrew/bb_ui_kit_fe";

import { CommentsGridContainer } from "@containers/Comments/Comments";
import { MessageList } from "@components/_common/MessageList/MessageList";

import CommentZendeskTicketDetails from "../CommentZendeskTicketDetails/CommentZendeskTicketDetails"; // eslint-disable-line
import { CommentDetails } from "../CommentDetail/CommentDetails";
import { PostDetails } from "../PostDetails/PostDetails";
import { InternalNotesContext } from "./InternalNotesContext";

import { internalNotesSelector } from "@store/internalNotes/selectors";

import {
  repliesSelector,
  postingReplySelector,
  postedReplySelector,
  repliesCursorSelector,
  fetchingRepliesSelector,
  fetchingThreadRepliesSelector,
  fetchedRepliesSelector,
  replyingThreadCommentSelector,
  repliedThreadCommentSelector,
  replyImageSelector,
  uploadingReplyImageSelector,
  fetchedRepliesAttachmentsSelector,
  fetchingRepliesAttachmentsSelector,
  getSavedReplyBBTagsSelector,
  getCreateSavedReplyCustomTagsSelector,
  fetchingSavedRepliesVariationsSelector,
  fetchedSavedRepliesVariationsSelector,
  savedRepliesVariationsSelector,
  getSavedRepliesSelector,
  searchedSavedRepliesSelector,
  accountPostsSelector,
  gettingAccountPostsHasMoreSelector,
  gettingAccountPostsPageSelector,
  gettingAccountPostsSelector,
  getAllDraftRepliesSelector,
} from "@store/replies/selectors";

import {
  getRepliesAction,
  getRepliesPageAction,
  postCommentReplyAction,
  clearRepliesCommentListAction,
  clearReplyingThreadCommentAction,
  getRepliesAttachmentsAction,
  postCommentReplyApprovalAction,
  blockUserAction,
  unblockUserAction,
  clearReplyImageAction,
  uploadReplyImageAction,
  putSavedReplyAction,
  postSavedReplyAction,
  postReplyBBTagAction,
  deleteReplyBBTagAction,
  getSavedRepliesVariationsAction,
  setSavedReplyCustomTagsAction,
  setSavedReplyBBTagsAction,
  clearSavedRepliesVariationsAction,
  deleteSavedRepliesAction,
  postSavedRepliesAction,
  getAccountPostsAction,
  setDraftReplyAction,
} from "@store/replies/actions";

import {
  commentsNavigationIdSelector,
  getCommentSelector,
  getCommentByIdFetchingSelector,
  fetchingCommentsAttachmentsSelector,
  fetchedCommentsAttachmentsSelector,
} from "@store/comments/selectors";

import {
  getPromptsAction,
  deletePromptAction,
  setPromptAsDefaultAction,
  getAIPromptSuggestionAction,
  clearAIPromptSuggestionAction,
  createPromptAction,
  updatePromptAction,
  clearPromptAction,
} from "@store/repliesAIPrompts/actions";

import {
  AIPromptsSelector,
  AIPromptSuggestionSelector,
  fetchedAIPromptSuggestionSelector,
  fetchingAIPromptSuggestionSelector,
  creatingAIPromptSelector,
  updatingAIPromptSelector,
  createdAIPromptSuccessfulSelector,
  updatedAIPromptSuccessfulSelector,
} from "@store/repliesAIPrompts/selectors";

import { getCommentByIdAction } from "@store/comments/actions";

import { openedAttachmentSelector } from "@store/attachment/selectors";

import { closeAttachmentAction, openAttachmentAction } from "@store/attachment/actions";

import {
  getAutocompleteOptionsSearchAction,
  updateAutocompleteOptionsAction,
  getAutocompleteOptionsAction,
} from "@store/autocomplete/actions";

import { autocompleteOptionsSelector } from "@store/autocomplete/selectors";

import { canManageBBTagsSelector, meSelector } from "@store/me/selectors";

import { deleteCustomTagFromReplyAction } from "@store/customTags/actions";

import s from "./ReplyModal.module.less";

import { useReplyModalView } from "./useReplyModalView";
import { useReplyingComment } from "@utils/comments";
import { IGetAccountPostsParams, IReplyAttachment } from "@store/replies/types";
import { IAIPromptCommentPayload } from "@store/repliesAIPrompts/types";
import {
  IAIPrompt,
  ICommentTag,
  ICustomTag,
  IAttachment,
  IPrivateAttachment,
  IGetAutocompleteOptionsPayload,
  IReplyModalProps,
} from "@bbdevcrew/bb_ui_kit_fe";
import { ISavedReplyPayload, SavedReplyType } from "@store/replies/types";

import { ActivityIcon, CommentIcon, PostIcon, ZendeskIcon } from "@bbdevcrew/bb_ui_kit_fe";

const ReplyModalWidthPanels: React.FC<IReplyModalProps> = props => {
  const { t } = useTranslation();
  const { replyData, currentView, expandedKey } = useReplyModal();

  const internalNotes = useSelector(internalNotesSelector);

  const { filters, listId, onClose } = props;
  const { replying_comment_id: commentId, zendesk_ticket: zendeskTicket } = replyData || {};

  const panels = useMemo(
    () => [
      {
        title: t("components:comments:details:title"),
        headerPrefix: <CommentIcon />,
        hidden: currentView !== "details" || !replyData?.post_preview?.id,
        activeKey: "details",
        children: (
          <CommentDetails
            filters={filters}
            replyData={replyData}
            listId={listId}
            onClose={onClose}
          />
        ),
      },
      {
        title: t("components:comments:postDetails:title"),
        headerPrefix: <PostIcon />,
        hidden: currentView !== "details" || !replyData?.post_preview?.id,
        activeKey: "post_details",
        children: (
          <PostDetails filters={filters} replyData={replyData} listId={listId} onClose={onClose} />
        ),
      },
      {
        title: (
          <div className={s.bbNotesTitle}>
            <span>{t("components:comments:activity:title")}</span>
            {expandedKey !== "internal_notes" && (
              <span className={s.bbNotesCount}>
                {internalNotes.length ? <div>{internalNotes.length}</div> : null}
              </span>
            )}
          </div>
        ),
        activeKey: "internal_notes",
        headerPrefix: <ActivityIcon />,
        hidden: currentView !== "details",
        className: s.bbNotes,
        children: (
          <InternalNotesContext>
            <InternalNotes
              commentId={commentId}
              collapsed={expandedKey !== "internal_notes"}
              count={internalNotes.length}
            />
          </InternalNotesContext>
        ),
      },
      {
        title: t("components:comments:zendeskTicket:title"),
        headerPrefix: <ZendeskIcon />,
        activeKey: "zendesk",
        hidden: !zendeskTicket,
        className: s.bbZendeskTicket,
        children: zendeskTicket ? <CommentZendeskTicketDetails ticket={zendeskTicket} /> : null,
      },
    ],
    [
      currentView,
      replyData,
      t,
      internalNotes,
      expandedKey,
      filters,
      listId,
      onClose,
      commentId,
      zendeskTicket,
    ],
  );

  return <ReplyModalComponent {...props} panels={panels} />;
};

const ReplyModal: React.FC<Omit<IReplyModalProps, "children">> = props => {
  const dispatch = useDispatch();

  const replyData = useSelector(repliesSelector);
  const isReplyPosting = useSelector(postingReplySelector);
  const repliesFetching = useSelector(fetchingRepliesSelector);
  const repliesCursorValue = useSelector(repliesCursorSelector);
  const repliesFetched = useSelector(fetchedRepliesSelector);
  const threadRepliesFetching = useSelector(fetchingThreadRepliesSelector);
  const isReplyPosted = useSelector(postedReplySelector);
  const commentsNavigationId = useSelector(commentsNavigationIdSelector);
  const aIPromptSuggestion = useSelector(AIPromptSuggestionSelector);
  const fetchingAIPromptSuggestion = useSelector(fetchingAIPromptSuggestionSelector);
  const fetchedAIPromptSuggestion = useSelector(fetchedAIPromptSuggestionSelector);
  const aIPrompts = useSelector(AIPromptsSelector);
  const replyImage = useSelector(replyImageSelector);
  const uploadingReplyImage = useSelector(uploadingReplyImageSelector);
  const replyingThreadComment = useSelector(replyingThreadCommentSelector);
  const repliedThreadComment = useSelector(repliedThreadCommentSelector);
  const fetchedRepliesAttachments = useSelector(fetchedRepliesAttachmentsSelector);
  const fetchingRepliesAttachments = useSelector(fetchingRepliesAttachmentsSelector);
  const savedReplyBBTags = useSelector(getSavedReplyBBTagsSelector);
  const createSavedReplyCustomTags = useSelector(getCreateSavedReplyCustomTagsSelector);
  const fetchingSavedRepliesVariations = useSelector(fetchingSavedRepliesVariationsSelector);
  const fetchedSavedRepliesVariations = useSelector(fetchedSavedRepliesVariationsSelector);
  const savedRepliesVariations = useSelector(savedRepliesVariationsSelector);
  const savedReplies = useSelector(getSavedRepliesSelector);
  const searchedSavedReplies = useSelector(searchedSavedRepliesSelector);
  const accountPosts = useSelector(accountPostsSelector);
  const gettingAccountPostsHasMore = useSelector(gettingAccountPostsHasMoreSelector);
  const gettingAccountPostsPage = useSelector(gettingAccountPostsPageSelector);
  const gettingAccountPosts = useSelector(gettingAccountPostsSelector);
  const selectedComment = useSelector(getCommentSelector);
  const getCommentByIdFetching = useSelector(getCommentByIdFetchingSelector);
  const fetchingCommentsAttachments = useSelector(fetchingCommentsAttachmentsSelector);
  const fetchedCommentsAttachments = useSelector(fetchedCommentsAttachmentsSelector);
  const creatingAIPrompt = useSelector(creatingAIPromptSelector);
  const updatingAIPrompt = useSelector(updatingAIPromptSelector);
  const createdAIPrompt = useSelector(createdAIPromptSuccessfulSelector);
  const updatedAIPrompt = useSelector(updatedAIPromptSuccessfulSelector);
  const openedAttachment = useSelector(openedAttachmentSelector);
  const autocompleteOptions = useSelector(autocompleteOptionsSelector);
  const canManageBBTags = useSelector(canManageBBTagsSelector);
  const me = useSelector(meSelector);
  const allDraftReplies = useSelector(getAllDraftRepliesSelector);

  const draftReply = useCallback(
    (commentId: string) => allDraftReplies[commentId] || "",
    [allDraftReplies],
  );

  const categories = replyData?.author_detail?.categories || [];
  const viewState = useReplyModalView(categories);
  const comment = useReplyingComment(replyData, props.listId);

  const fetchRepliesPage = useCallback(() => {
    if (!repliesFetching && !!repliesCursorValue) {
      dispatch(getRepliesPageAction(repliesCursorValue));
    }
  }, [dispatch, repliesFetching, repliesCursorValue]);

  const fetchReplies = useCallback(
    (commentId: string) => dispatch(getRepliesAction(commentId, commentsNavigationId)),
    [dispatch, commentsNavigationId],
  );

  const postReply = useCallback(
    (
      commentId: string,
      userTag: string,
      message: string,
      rawMessage: string,
      is_ignored: boolean,
      attachment?: IReplyAttachment,
      replyingAssetId?: string,
      isPrivateReply?: boolean,
      userIds?: string[],
    ) => {
      const separator = userTag ? " " : "";
      const body = {
        message: `${userTag}${separator}${message}`,
        raw_message: rawMessage,
        commentId,
        is_ignored,
        replyingAssetId,
        isPrivateReply,
        attachment,
      };

      if (userIds) {
        dispatch(
          postCommentReplyApprovalAction({
            ...body,
            user_ids: !!userIds.length ? userIds : undefined,
            can_anyone_approve: !userIds.length,
          }),
        );
      } else {
        dispatch(postCommentReplyAction(body));
      }
    },
    [dispatch],
  );

  const setDraftReply = useCallback(
    (commentId: string, message: string) => dispatch(setDraftReplyAction(commentId, message)),
    [dispatch],
  );

  const clearReplyComments = useCallback(
    () => dispatch(clearRepliesCommentListAction()),
    [dispatch],
  );

  const clearReplyingThreadComment = useCallback(
    () => dispatch(clearReplyingThreadCommentAction()),
    [dispatch],
  );

  const getRepliesAttachments = useCallback(
    () => dispatch(getRepliesAttachmentsAction()),
    [dispatch],
  );

  const blockUser = useCallback(
    (pageId: string, userId: string) => dispatch(blockUserAction(pageId, userId)),
    [dispatch],
  );

  const unblockUser = useCallback(
    (pageId: string, userId: string) => dispatch(unblockUserAction(pageId, userId)),
    [dispatch],
  );

  const getAIPromptSuggestion = useCallback(
    (payload: IAIPromptCommentPayload) => dispatch(getAIPromptSuggestionAction(payload)),
    [dispatch],
  );

  const fetchAIPrompts = useCallback(
    (query?: string) => dispatch(getPromptsAction(query)),
    [dispatch],
  );

  const deleteAIPrompt = useCallback((id: string) => dispatch(deletePromptAction(id)), [dispatch]);

  const setDefaultAIPrompt = useCallback(
    (id: string) => dispatch(setPromptAsDefaultAction(id)),
    [dispatch],
  );

  const clearAIPromptSuggestion = useCallback(
    () => dispatch(clearAIPromptSuggestionAction()),
    [dispatch],
  );

  const uploadReplyImage = useCallback(
    (file: File) => dispatch(uploadReplyImageAction(file)),
    [dispatch],
  );

  const clearReplyImage = useCallback(() => dispatch(clearReplyImageAction()), [dispatch]);

  const createPrompt = useCallback(
    (payload: IAIPrompt) => dispatch(createPromptAction(payload)),
    [dispatch],
  );

  const updatePrompt = useCallback(
    (payload: IAIPrompt) => dispatch(updatePromptAction(payload)),
    [dispatch],
  );

  const clearPrompt = useCallback(() => dispatch(clearPromptAction()), [dispatch]);

  const putSavedReply = useCallback(
    (payload: ISavedReplyPayload) => dispatch(putSavedReplyAction(payload)),
    [dispatch],
  );

  const postSavedReply = useCallback(
    (payload: ISavedReplyPayload) => dispatch(postSavedReplyAction(payload)),
    [dispatch],
  );

  const postReplyBBTag = useCallback(
    (tag: ICommentTag) => dispatch(postReplyBBTagAction(tag)),
    [dispatch],
  );

  const deleteReplyBBTag = useCallback(
    (tagId: string) => dispatch(deleteReplyBBTagAction(tagId)),
    [dispatch],
  );

  const getSavedRepliesVariations = useCallback(
    (message: string) => dispatch(getSavedRepliesVariationsAction(message)),
    [dispatch],
  );

  const setSavedReplyCustomTags = useCallback(
    (tags: ICustomTag[]) => dispatch(setSavedReplyCustomTagsAction(tags)),
    [dispatch],
  );

  const setSavedReplyBBTags = useCallback(
    (tags: ICommentTag[]) => dispatch(setSavedReplyBBTagsAction(tags)),
    [dispatch],
  );

  const clearSavedRepliesVariations = useCallback(
    () => dispatch(clearSavedRepliesVariationsAction()),
    [dispatch],
  );

  const deleteSavedReplies = useCallback(
    (id: string, type: SavedReplyType) => dispatch(deleteSavedRepliesAction(id, type)),
    [dispatch],
  );

  const postSavedReplies = useCallback(
    (query?: string, presetQuery?: string, commentId?: string) =>
      dispatch(postSavedRepliesAction(query, presetQuery, commentId)),
    [dispatch],
  );

  const getAccountPosts = useCallback(
    (params: IGetAccountPostsParams) => dispatch(getAccountPostsAction(params)),
    [dispatch],
  );

  const getCommentById = useCallback(
    (commentId: string) => dispatch(getCommentByIdAction(commentId)),
    [dispatch],
  );

  const closeAttachment = useCallback(() => dispatch(closeAttachmentAction()), [dispatch]);

  const openAttachment = useCallback(
    (attachment: IAttachment | IPrivateAttachment) => dispatch(openAttachmentAction(attachment)),
    [dispatch],
  );

  const getAutocompleteOptionsSearch = useCallback(
    (payload: IGetAutocompleteOptionsPayload) =>
      dispatch(getAutocompleteOptionsSearchAction(payload)),
    [dispatch],
  );

  const updateAutocompleteOptions = useCallback(
    (payload: IGetAutocompleteOptionsPayload) => dispatch(updateAutocompleteOptionsAction(payload)),
    [dispatch],
  );

  const getAutocompleteOptions = useCallback(
    (payload: IGetAutocompleteOptionsPayload) => dispatch(getAutocompleteOptionsAction(payload)),
    [dispatch],
  );

  const deleteCustomTagFromReply = useCallback(
    (id: string | null) => dispatch(deleteCustomTagFromReplyAction(id)),
    [dispatch],
  );

  return (
    <ReplyModalProvider
      defaultExpandedKey="details"
      reduxState={{
        replyData,
        isReplyPosting,
        repliesFetching,
        repliesCursorValue,
        repliesFetched,
        threadRepliesFetching,
        isReplyPosted,
        commentsNavigationId,
        comment,
        aIPromptSuggestion,
        fetchingAIPromptSuggestion,
        fetchedAIPromptSuggestion,
        aIPrompts,
        replyImage,
        uploadingReplyImage,
        replyingThreadComment,
        repliedThreadComment,
        fetchedRepliesAttachments,
        fetchingRepliesAttachments,
        savedReplyBBTags,
        createSavedReplyCustomTags,
        fetchingSavedRepliesVariations,
        fetchedSavedRepliesVariations,
        savedRepliesVariations,
        savedReplies,
        searchedSavedReplies,
        accountPosts,
        gettingAccountPostsHasMore,
        gettingAccountPostsPage,
        gettingAccountPosts,
        selectedComment,
        getCommentByIdFetching,
        fetchingCommentsAttachments,
        fetchedCommentsAttachments,
        creatingAIPrompt,
        updatingAIPrompt,
        createdAIPrompt,
        updatedAIPrompt,
        openedAttachment,
        autocompleteOptions,
        canManageBBTags,
        aiPromptsEnabled: me?.client?.ai_generated_reply_enabled,
        bbRepliesEnabled: me?.client?.bb_replies_enabled,
        isBbUser: me?.is_bb_user,
        userId: me?.id,
        draftReply,
        isIGFollowersCountEnabled: me?.client?.ig_followers_count_enabled,
        isTTAuthorMetricsEnabled: me?.client?.tt_author_metrics_enabled,
      }}
      reduxActions={{
        fetchRepliesPage,
        fetchReplies,
        postReply,
        clearReplyComments,
        clearReplyingThreadComment,
        getRepliesAttachments,
        blockUser,
        unblockUser,
        getAIPromptSuggestion,
        fetchAIPrompts,
        deleteAIPrompt,
        setDefaultAIPrompt,
        clearAIPromptSuggestion,
        uploadReplyImage,
        clearReplyImage,
        createPrompt,
        updatePrompt,
        clearPrompt,
        putSavedReply,
        postSavedReply,
        postReplyBBTag,
        deleteReplyBBTag,
        getSavedRepliesVariations,
        setSavedReplyCustomTags,
        setSavedReplyBBTags,
        clearSavedRepliesVariations,
        deleteSavedReplies,
        postSavedReplies,
        getAccountPosts,
        getCommentById,
        closeAttachment,
        openAttachment,
        getAutocompleteOptionsSearch,
        updateAutocompleteOptions,
        getAutocompleteOptions,
        deleteCustomTagFromReply,
        setDraftReply,
      }}
      viewState={viewState}
    >
      <ReplyModalWidthPanels {...props}>
        {{
          CommentsGridContainer,
          MessageList,
        }}
      </ReplyModalWidthPanels>
    </ReplyModalProvider>
  );
};

export default ReplyModal;
