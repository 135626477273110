import React from "react";

import s from "./Stepper.module.less";
import palette from "@bbdevcrew/bb_ui_kit_fe/dist/theme/scheme.module.less";

import { IStepperConfig } from "./Stepper.types";

import { CheckIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const defaultConfig: IStepperConfig = {
  finish: {
    backgroundColor: palette.colorGreen100,
    borderColor: palette.colorGreen100,
    color: palette.colorBlack,
    titleColor: palette.colorGrey700,
    tailColors: [palette.colorGreen100],
    icon: (
      <div className={s.bbStepDefaultFinishIcon}>
        <CheckIcon />
      </div>
    ),
  },
  process: {
    backgroundColor: palette.colorBlue700,
    borderColor: palette.colorBlue700,
    color: palette.colorWhite,
    titleColor: palette.colorBlue700,
    tailColors: [palette.colorBlue700, palette.colorGrey200],
  },
  wait: {
    backgroundColor: palette.colorWhite,
    borderColor: palette.colorGrey200,
    color: palette.colorBlack,
    titleColor: palette.colorBlack,
    tailColors: [palette.colorGrey200],
  },
};

export const simpleConfig: IStepperConfig = {
  finish: {
    backgroundColor: palette.colorBlue700,
    borderColor: palette.colorBlue700,
    color: palette.colorBlue700,
    titleColor: palette.colorGrey700,
    tailColors: [palette.colorBlue700],
  },
  process: {
    backgroundColor: palette.colorWhite,
    borderColor: palette.colorBlue700,
    color: palette.colorWhite,
    titleColor: palette.colorBlue700,
    tailColors: [palette.colorBlue700, palette.colorGrey200],
  },
  wait: {
    backgroundColor: palette.colorWhite,
    borderColor: palette.colorGrey200,
    color: palette.colorGrey700,
    titleColor: palette.colorGrey700,
    tailColors: [palette.colorGrey200],
  },
};
