import { action } from "typesafe-actions";

import {
  POST_WORKFLOW_AI_MODELS,
  POST_WORKFLOW_AI_MODELS_SUCCESS,
  POST_WORKFLOW_AI_MODELS_FAILURE,
  CLEAR_POST_WORKFLOW_AI_MODELS,
  EDIT_WORKFLOW_AI_MODELS_TASK,
  EDIT_WORKFLOW_AI_MODELS_TASK_SUCCESS,
  EDIT_WORKFLOW_AI_MODELS_TASK_FAILURE,
  CREATE_WORKFLOW_AI_MODELS_TASK,
  CREATE_WORKFLOW_AI_MODELS_TASK_SUCCESS,
  CREATE_WORKFLOW_AI_MODELS_TASK_FAILURE,
  GET_WORKFLOW_AI_MODELS,
  GET_WORKFLOW_AI_MODELS_SUCCESS,
  GET_WORKFLOW_AI_MODELS_FAILURE,
  DELETE_WORKFLOW_AI_MODELS_TASK_FAILURE,
  DELETE_WORKFLOW_AI_MODELS_TASK_SUCCESS,
  DELETE_WORKFLOW_AI_MODELS_TASK,
  CREATE_WORKFLOW_AI_MODELS_TASK_EXAMPLES,
  CREATE_WORKFLOW_AI_MODELS_TASK_EXAMPLES_SUCCESS,
  CREATE_WORKFLOW_AI_MODELS_TASK_EXAMPLES_FAILURE,
  EDIT_WORKFLOW_AI_MODELS_TASK_EXAMPLE,
  EDIT_WORKFLOW_AI_MODELS_TASK_EXAMPLE_FAILURE,
  EDIT_WORKFLOW_AI_MODELS_TASK_EXAMPLE_SUCCESS,
  TEST_WORKFLOW_AI_MODEL_FAILURE,
  TEST_WORKFLOW_AI_MODEL_SUCCESS,
  TEST_WORKFLOW_AI_MODEL,
  DELETE_WORKFLOW_AI_MODELS_TASK_EXAMPLE,
  DELETE_WORKFLOW_AI_MODELS_TASK_EXAMPLE_SUCCESS,
  DELETE_WORKFLOW_AI_MODELS_TASK_EXAMPLE_FAILURE,
  CLEAR_CREATE_WORKFLOW_AI_MODELS_TASK_EXAMPLES,
  CLEAR_CREATE_WORKFLOW_AI_MODELS_TASK,
  CLEAR_EDIT_WORKFLOW_AI_MODELS_TASK,
} from "./actionTypes";
import {
  IWorkflowAIModelPayload,
  IWorkflowAIModelResponse,
  IEditWorkflowAIModelTaskPayload,
  ICreateWorkflowAIModelTaskResponse,
  ITaskExampleCreationResponse,
  ITaskExampleCreationPayload,
  IWorkflowAIModelTestingResponse,
} from "./types";

export const postWorkflowsAIModelsAction = (payload: IWorkflowAIModelPayload) =>
  action(POST_WORKFLOW_AI_MODELS, payload);
export const postWorkflowsAIModelsSuccessAction = (response: IWorkflowAIModelResponse) =>
  action(POST_WORKFLOW_AI_MODELS_SUCCESS, response);
export const postWorkflowsAIModelsFailureAction = (error: string) =>
  action(POST_WORKFLOW_AI_MODELS_FAILURE, error);
export const clearPostWorkflowsAIModelsAction = () => action(CLEAR_POST_WORKFLOW_AI_MODELS);

export const getWorkflowsAIModelsAction = (id: string) => action(GET_WORKFLOW_AI_MODELS, id);
export const getWorkflowsAIModelsSuccessAction = (response: IWorkflowAIModelResponse) =>
  action(GET_WORKFLOW_AI_MODELS_SUCCESS, response);
export const getWorkflowsAIModelsFailureAction = (error: string) =>
  action(GET_WORKFLOW_AI_MODELS_FAILURE, error);

export const createWorkflowsAIModelTaskAction = (
  workflowId: string,
  payload: IEditWorkflowAIModelTaskPayload,
) => action(CREATE_WORKFLOW_AI_MODELS_TASK, { workflowId, payload });
export const createWorkflowsAIModelTaskSuccessAction = (
  response: ICreateWorkflowAIModelTaskResponse,
) => action(CREATE_WORKFLOW_AI_MODELS_TASK_SUCCESS, response);
export const createWorkflowsAIModelTaskFailureAction = (error: string) =>
  action(CREATE_WORKFLOW_AI_MODELS_TASK_FAILURE, error);
export const clearCreateWorkflowsAIModelTaskAction = () =>
  action(CLEAR_CREATE_WORKFLOW_AI_MODELS_TASK);

export const editWorkflowsAIModelsTaskAction = (
  workflowId: string,
  taskId: string,
  payload: IEditWorkflowAIModelTaskPayload,
) => action(EDIT_WORKFLOW_AI_MODELS_TASK, { workflowId, taskId, payload });
export const editWorkflowsAIModelsTaskSuccessAction = (response: IWorkflowAIModelResponse) =>
  action(EDIT_WORKFLOW_AI_MODELS_TASK_SUCCESS, response);
export const editWorkflowsAIModelsTaskFailureAction = (error: string) =>
  action(EDIT_WORKFLOW_AI_MODELS_TASK_FAILURE, error);
export const clearEditWorkflowAIModelsTaskAction = () => action(CLEAR_EDIT_WORKFLOW_AI_MODELS_TASK);

export const deleteWorkflowsAIModelsTaskAction = (workflowId: string, taskId: string) =>
  action(DELETE_WORKFLOW_AI_MODELS_TASK, { workflowId, taskId });
export const deleteWorkflowsAIModelsTaskSuccessAction = () =>
  action(DELETE_WORKFLOW_AI_MODELS_TASK_SUCCESS);
export const deleteWorkflowsAIModelsTaskFailureAction = (error: string) =>
  action(DELETE_WORKFLOW_AI_MODELS_TASK_FAILURE, error);

export const createWorkflowAIModelsTaskExamplesAction = (
  workflowId: string,
  taskId: string,
  payload: ITaskExampleCreationPayload,
) => action(CREATE_WORKFLOW_AI_MODELS_TASK_EXAMPLES, { workflowId, taskId, payload });
export const createWorkflowAIModelsTaskExamplesSuccessAction = (
  response: ITaskExampleCreationResponse,
) => action(CREATE_WORKFLOW_AI_MODELS_TASK_EXAMPLES_SUCCESS, response);
export const createWorkflowAIModelsTaskExamplesFailureAction = (error: string) =>
  action(CREATE_WORKFLOW_AI_MODELS_TASK_EXAMPLES_FAILURE, error);
export const clearCreateWorkflowAIModelsTaskExamplesAction = () =>
  action(CLEAR_CREATE_WORKFLOW_AI_MODELS_TASK_EXAMPLES);

export const updateWorkflowAIModelsTaskExamplesAction = (
  workflowId: string,
  taskId: string,
  exampleId: string,
  payload: ITaskExampleCreationPayload,
) => action(EDIT_WORKFLOW_AI_MODELS_TASK_EXAMPLE, { workflowId, taskId, exampleId, payload });
export const updateWorkflowAIModelsTaskExamplesSuccessAction = (
  response: ITaskExampleCreationResponse,
) => action(EDIT_WORKFLOW_AI_MODELS_TASK_EXAMPLE_SUCCESS, response);
export const updateWorkflowAIModelsTaskExamplesFailureAction = (error: string) =>
  action(EDIT_WORKFLOW_AI_MODELS_TASK_EXAMPLE_FAILURE, error);

export const deleteWorkflowAIModelsTaskExamplesAction = (
  workflowId: string,
  taskId: string,
  exampleId: string,
) => action(DELETE_WORKFLOW_AI_MODELS_TASK_EXAMPLE, { workflowId, taskId, exampleId });
export const deleteWorkflowAIModelsTaskExamplesSuccessAction = (
  response: ITaskExampleCreationResponse,
) => action(DELETE_WORKFLOW_AI_MODELS_TASK_EXAMPLE_SUCCESS, response);
export const deleteWorkflowAIModelsTaskExamplesFailureAction = (error: string) =>
  action(DELETE_WORKFLOW_AI_MODELS_TASK_EXAMPLE_FAILURE, error);

export const testWorkflowAIModelAction = (workflowId: string, content: string) =>
  action(TEST_WORKFLOW_AI_MODEL, { workflowId, content });
export const testWorkflowAIModelSuccessAction = (data: IWorkflowAIModelTestingResponse) =>
  action(TEST_WORKFLOW_AI_MODEL_SUCCESS, data);
export const testWorkflowAIModelFailureAction = (error: string) =>
  action(TEST_WORKFLOW_AI_MODEL_FAILURE, error);
