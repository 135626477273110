import React from "react";

import { AiBadge, IModuleFeature } from "@bbdevcrew/bb_ui_kit_fe";
import { DashboardComponentType } from "./types";

import { t } from "../../App";

import s from "./styles.module.less";

const createFeature = ({
  id,
  label,
  customBadge,
  is_visible = true,
  features = [],
}: IModuleFeature<DashboardComponentType>): IModuleFeature<DashboardComponentType> => ({
  id,
  label,
  is_locked: false,
  customBadge,
  is_visible,
  counts: [{ count: 0, id: "total" }],
  ...(features.length > 0 && { features }),
});

const createSection = ({
  id,
  label,
  is_visible = true,
  features = [],
}: {
  id: string;
  label?: string;
  is_visible?: boolean;
  features: IModuleFeature<DashboardComponentType>[];
}): IModuleFeature<DashboardComponentType> => ({
  id,
  label,
  features,
  is_locked: false,
  is_visible,
});

export const getCareModuleStructure = (
  items: IModuleFeature<DashboardComponentType>[],
  inboxType = "inbox",
  isSafetyFullyManaged = false,
): IModuleFeature<DashboardComponentType>[] => [
  createSection({
    id: "inbox",
    label: t("generic:inbox"),
    features: [
      createFeature({ id: "all", label: t("components:inboxMenu:tabs:allLabel") }),
      createFeature({
        id: "review",
        label: t("components:inboxMenu:tabs:reviewLabel"),
        features: [
          createFeature({
            id: "assigned_to_me",
            label: t("components:inboxMenu:tabs:assigned_to_meLabel"),
          }),
          ...(inboxType === "smart-inbox"
            ? [
                createFeature({
                  id: "ai_response_needed",
                  customBadge: <AiBadge className={s.bbAiBadge} />,
                  label: t("components:inboxMenu:tabs:ai_response_neededLabel"),
                }),
                ...(!isSafetyFullyManaged
                  ? [
                      createFeature({
                        id: "ai_potentially_harmful",
                        customBadge: <AiBadge className={s.bbAiBadge} />,
                        label: t("components:inboxMenu:tabs:ai_potentially_harmfulLabel"),
                      }),
                    ]
                  : []),
              ]
            : [
                createFeature({
                  id: "care_highlights",
                  label: t("components:inboxMenu:tabs:care_highlightsLabel"),
                }),
              ]),
        ],
      }),
    ],
  }),
  // TODO: Uncomment this when the masonry grid is ready
  // ...items,
  createSection({
    id: "inbox",
    label: t("components:inboxMenu:tabs:resolvedLabel"),
    features: [
      createFeature({
        id: "done",
        label: t("components:inboxMenu:tabs:doneLabel"),
        features: [
          createFeature({ id: "replied", label: t("components:inboxMenu:tabs:repliedLabel") }),
          ...(inboxType === "smart-inbox"
            ? [
                createFeature({
                  id: "ignored",
                  label: t("components:inboxMenu:tabs:ignoredLabel"),
                  features: [
                    createFeature({
                      id: "hidden",
                      label: t("components:inboxMenu:tabs:hiddenLabel"),
                    }),
                  ],
                }),
              ]
            : [
                createFeature({
                  id: "archived",
                  label: t("components:inboxMenu:tabs:ignoredLabel"),
                }),
              ]),
        ],
      }),
    ],
  }),
  createSection({
    id: "inbox",
    features: [
      createFeature({ id: "starred", label: t("components:inboxMenu:tabs:starredLabel") }),
    ],
  }),
  createSection({
    id: "inbox",
    is_visible: false,
    features: [
      createFeature({
        id: "errors",
        label: t("components:inboxMenu:tabs:errorsLabel"),
      }),
    ],
  }),
];
