import { IWorkflowItem, WorkflowItemCategoryType } from "@store/workflows/types";

export const DESCRIPTION_MAX_LENGTH = 100;

// Only enable the workflow context menu for categories we support both editing/deleting
export const SUPPORTED_WORKFLOW_CATEGORIES: WorkflowItemCategoryType[] = [
  "sentiment",
  "tag",
  "post_tag",
  "archive",
  "hide",
  "reply",
  "email_alert",
  "zendesk",
  "assign",
  "nlp_tag",
];

export type WorkflowActionFnType = (closeActionMenu: () => void) => void;

export interface IWorkflowItemProps {
  item: IWorkflowItem;
  onEditClick: WorkflowActionFnType;
  onDeleteClick: WorkflowActionFnType;
  onWorkflowToggle: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface IWorkflowItemCardProps {
  title: string;
  icon: string;
  className?: string;
  pointArrow?: boolean;
  pointAdditionalAction?: boolean;
  hasAdditionalAction?: boolean;
  onAdditionalActionClick?: () => void;
}

export interface IWorkflowActionsProps {
  onEditClick: () => void;
  onDeleteClick: () => void;
  onExploreClick: () => void;
  isActionsDropdownOpen: boolean;
  setIsActionsDropdownOpen: (open: boolean) => void;
  showExploreAction: boolean;
  className?: string;
}
