import { Observable } from "rxjs";
import { ajax } from "rxjs/ajax";
import { isOfType } from "typesafe-actions";
import { StateObservable } from "redux-observable";
import { catchError, switchMap, filter, map } from "rxjs/operators";

import {
  getWorkflowsSuccessAction,
  getWorkflowsFailureAction,
  createWorkflowSuccessAction,
  createWorkflowFailureAction,
  editWorkflowSuccessAction,
  editWorkflowFailureAction,
  deleteWorkflowSuccessAction,
  deleteWorkflowFailureAction,
  enableWorkflowSuccessAction,
  enableWorkflowFailureAction,
  disableWorkflowSuccessAction,
  disableWorkflowFailureAction,
  getWorkflowsSavedRepliesSuccessAction,
  getWorkflowsSavedRepliesFailureAction,
  getMatchingCommentsFailureAction,
  getMatchingCommentsSuccessAction,
  getWorkflowsAccessUsersFailureAction,
  getWorkflowsAccessUsersSuccessAction,
} from "./actions";
import {
  GET_WORKFLOWS,
  CREATE_WORKFLOW,
  EDIT_WORKFLOW,
  DELETE_WORKFLOW,
  ENABLE_WORKFLOW,
  DISABLE_WORKFLOW,
  GET_WORKFLOWS_SAVED_REPLIES,
  GET_MATCHING_COMMENTS,
  GET_WORKFLOWS_ACCESS_USERS,
} from "./actionTypes";

import { RootState } from "../index";
import { commentsSearch, workflows } from "@utils/paths";

import {
  WorkflowsActions,
  IGetWorkflowsResponse,
  IWorkflowItem,
  IWorkflowItemPayload,
  IWorkflowEmailAlertAction,
  IWorkflowAccessUserResponse,
} from "./types";
import { getHeaders } from "@utils/headers";
import { handleError } from "@bbdevcrew/bb_ui_kit_fe";
import { ISavedReply } from "@store/replies/types";
import { IComment } from "@bbdevcrew/bb_ui_kit_fe";

const fromAPIWorkflowItemMapper = (item: IWorkflowItemPayload): IWorkflowItem => {
  const additionalActions: IWorkflowEmailAlertAction[] = [];

  if (item.additional_actions?.items?.length) {
    item.additional_actions?.items?.forEach(action => {
      const actionCategory = action.category;
      additionalActions.push({
        ...(action[actionCategory as keyof typeof action] as IWorkflowEmailAlertAction),
        category: actionCategory,
        notification_id: action.notificationId,
      });
    });
  }

  const normalizedItem = { ...item, additional_actions: additionalActions };

  if (normalizedItem.category === "sentiment")
    return {
      ...normalizedItem,
      trigger: {
        keyword_query: normalizedItem.trigger?.filters?.keyword_query || [],
        comment_tags: normalizedItem.trigger?.filters?.comment_tags || [],
      },
      action: normalizedItem.sentiment,
    } as IWorkflowItem;

  if (normalizedItem.category === "tag") {
    return {
      ...normalizedItem,
      trigger: {
        filters: normalizedItem.trigger?.filters,
        keyword_query: normalizedItem.trigger?.filters?.keyword_query,
        time_frame: normalizedItem.trigger?.filters?.time_frame,
      },
      action: normalizedItem.tags,
    } as IWorkflowItem;
  }

  if (normalizedItem.category === "post_tag") {
    return {
      ...normalizedItem,
      trigger: {
        filters: normalizedItem.trigger?.filters,
      },
      action: normalizedItem.post_tags,
    } as IWorkflowItem;
  }

  if (normalizedItem.category === "archive") {
    return {
      ...normalizedItem,
      trigger: {
        keyword_query: normalizedItem.trigger?.filters?.keyword_query || [],
        comment_tags: normalizedItem.trigger?.filters?.comment_tags || [],
      },
    } as IWorkflowItem;
  }

  if (normalizedItem.category === "email_alert") {
    return {
      ...normalizedItem,
      email_alert: {
        ...normalizedItem.email_alert,
        schedule: {
          ...normalizedItem.email_alert?.schedule,
          hour:
            normalizedItem.email_alert && normalizedItem.email_alert.schedule
              ? normalizedItem.email_alert.schedule?.hour?.toString()
              : "10",
        },
      },
    } as IWorkflowItem;
  }

  if (normalizedItem.category === "hide") {
    const [first_additional_actions, ...restAdditional_actions] = normalizedItem.additional_actions;

    if (!first_additional_actions) {
      return normalizedItem as unknown as IWorkflowItem;
    }

    return {
      ...normalizedItem,
      additional_actions: [
        {
          ...first_additional_actions,
          schedule: {
            ...first_additional_actions?.schedule,
            hour:
              first_additional_actions && first_additional_actions.schedule
                ? first_additional_actions.schedule?.hour?.toString()
                : "10",
          },
        },
        ...restAdditional_actions,
      ],
    } as IWorkflowItem;
  }

  return normalizedItem as unknown as IWorkflowItem;
};

const toAPIWorkflowItemMapper = (item: IWorkflowItemPayload): IWorkflowItemPayload => {
  if (item.category === "email_alert") {
    return {
      ...item,
      email_alert: {
        ...item.email_alert,
        schedule: {
          ...item.email_alert?.schedule,
          hour:
            item.email_alert && item.email_alert.schedule
              ? Number(item.email_alert.schedule.hour)
              : 10,
        },
      },
    } as IWorkflowItemPayload;
  }

  if (item.category === "hide") {
    const additional_actions = item.additional_actions?.items || [];

    if (!additional_actions || additional_actions?.length === 0) return item;

    const [first_additional_actions, ...restAdditional_actions] = additional_actions;
    const email_alert = first_additional_actions?.email_alert;
    const schedule = email_alert?.schedule;

    if (!email_alert || !schedule) return item;

    return {
      ...item,
      additional_actions: {
        items: [
          {
            ...first_additional_actions,
            email_alert: {
              ...email_alert,
              schedule: {
                ...schedule,
                hour: schedule.hour ? Number(schedule.hour) : 10,
              },
            },
          },
          ...restAdditional_actions,
        ],
      },
    } as IWorkflowItemPayload;
  }

  return item as IWorkflowItemPayload;
};

export const getWorkflowsEpic = (
  action$: Observable<WorkflowsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_WORKFLOWS)),
    switchMap(() => {
      return ajax
        .get<IGetWorkflowsResponse>(
          workflows,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => ({ ...data, items: data.items.map(fromAPIWorkflowItemMapper) })),
          map(data => getWorkflowsSuccessAction(data)),
          catchError(e => handleError(e, getWorkflowsFailureAction)),
        );
    }),
  );

export const createWorkflowEpic = (
  action$: Observable<WorkflowsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(CREATE_WORKFLOW)),
    switchMap(a => {
      return ajax
        .post(
          workflows,
          toAPIWorkflowItemMapper(a.payload),
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(() => createWorkflowSuccessAction(a.payload)),
          catchError(e => handleError(e, createWorkflowFailureAction)),
        );
    }),
  );

export const editWorkflowEpic = (
  action$: Observable<WorkflowsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(EDIT_WORKFLOW)),
    switchMap(a => {
      const { id, ...payloadExcludingId } = toAPIWorkflowItemMapper(a.payload);
      return ajax
        .put(
          `${workflows}/${id}`,
          payloadExcludingId,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(() => editWorkflowSuccessAction()),
          catchError(e => handleError(e, editWorkflowFailureAction)),
        );
    }),
  );

export const deleteWorkflowEpic = (
  action$: Observable<WorkflowsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(DELETE_WORKFLOW)),
    switchMap(a => {
      return ajax
        .delete<{ id: string }>(
          `${workflows}/${a.payload}`,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(() => deleteWorkflowSuccessAction()),
          catchError(e => handleError(e, deleteWorkflowFailureAction)),
        );
    }),
  );

export const enableWorkflowEpic = (
  action$: Observable<WorkflowsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(ENABLE_WORKFLOW)),
    switchMap(a => {
      return ajax
        .patch<{ id: string }>(
          `${workflows}/${a.payload}/enable`,
          {
            id: a.payload,
          },
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(() => enableWorkflowSuccessAction()),
          catchError(e => handleError(e, () => enableWorkflowFailureAction(a.payload))),
        );
    }),
  );

export const disableWorkflowEpic = (
  action$: Observable<WorkflowsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(DISABLE_WORKFLOW)),
    switchMap(a => {
      return ajax
        .patch<{ id: string }>(
          `${workflows}/${a.payload}/disable`,
          {
            id: a.payload,
          },
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(() => disableWorkflowSuccessAction()),
          catchError(e => handleError(e, () => disableWorkflowFailureAction(a.payload))),
        );
    }),
  );

export const getWorkflowsAccessUsers = (
  action$: Observable<WorkflowsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_WORKFLOWS_ACCESS_USERS)),
    switchMap(() => {
      return ajax
        .get<IWorkflowAccessUserResponse[]>(
          `${workflows}/access/users`,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => getWorkflowsAccessUsersSuccessAction(data)),
          catchError(e => handleError(e, getWorkflowsAccessUsersFailureAction)),
        );
    }),
  );

export const getWorkflowsSavedReplies = (
  action$: Observable<WorkflowsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_WORKFLOWS_SAVED_REPLIES)),
    switchMap(a => {
      return ajax
        .post<ISavedReply[]>(
          `${workflows}/templates-list`,
          {
            query: a.payload?.query,
            preset_query: a.payload?.preset_query,
          },
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => getWorkflowsSavedRepliesSuccessAction(data)),
          catchError(e => handleError(e, getWorkflowsSavedRepliesFailureAction)),
        );
    }),
  );

export const getMatchingCommentsEpic = (
  action$: Observable<WorkflowsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_MATCHING_COMMENTS)),
    switchMap(a => {
      return ajax
        .post<{ items: IComment[]; total_count: number }>(
          commentsSearch,
          {
            filters: a.payload,
            cursor: "",
            limit: 20,
          },
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => getMatchingCommentsSuccessAction(data.items, data.total_count)),
          catchError(e => handleError(e, getMatchingCommentsFailureAction)),
        );
    }),
  );
