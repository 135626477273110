import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { PostBased } from "@components/_common/PostBased/PostBased";
import { CareReviewTag } from "@components/_common/CareReviewTag/CareReviewTag";
import ExpandableTextInput from "@components/_common/ExpandableTextInput";

import { meSelector } from "@store/me/selectors";

import { IFilters, KeywordsFilter } from "@bbdevcrew/bb_ui_kit_fe";
import { useGetDefaultFiltersConfig } from "@components/_common/AppFilter/config";
import { WorkflowItemCategoryType } from "@store/workflows/types";

export const useWorkflowTriggerFilters = (
  filterValues: IFilters,
  workflowType: WorkflowItemCategoryType,
  hideCareReviewTag?: boolean,
  hiddenFilters?: string[],
  hiddenPlatformTypes?: string[],
  additionalFilters?: string[],
  selectFieldsPlaceholder?: string,
) => {
  const { t } = useTranslation();
  const me = useSelector(meSelector);

  const filters = useGetDefaultFiltersConfig(
    t,
    true,
    undefined,
    hiddenPlatformTypes,
    selectFieldsPlaceholder,
  );

  const [careReviewTag, setCareReviewTag] = useState(false);

  const keywordsIdx = filters.findIndex(obj => obj.name === "keyword_query");
  const postIdsIdx = filters.findIndex(obj => obj.name === "post_ids");
  const platformTypesIdx = filters.findIndex(obj => obj.name === "platform_types");
  const messageTypesIdx = filters.findIndex(obj => obj.name === "message_types");

  const messageTypesFilteredAllOptions = me?.message_types?.items?.filter(
    ({ id }) => id !== "owned_private" && id !== "earned",
  );

  if (keywordsIdx > -1) {
    filters[keywordsIdx].props = {
      ...filters[keywordsIdx].props,
      maxChipCount: 2,
    };
  }

  if (messageTypesIdx > -1) {
    filters[messageTypesIdx].props = {
      ...filters[messageTypesIdx].props,
      options: workflowType === "hide" ? messageTypesFilteredAllOptions : me?.message_types?.items,
    };
  }

  if (postIdsIdx > -1 && !hiddenFilters?.includes("is_post_based")) {
    filters.splice(postIdsIdx + 1, 0, {
      name: "is_post_based",
      type: "asset",
      component: PostBased,
      label: "",
      props: {
        checked: !!filterValues?.is_post_based,
      },
    });
  }

  if (platformTypesIdx > -1) {
    filters[platformTypesIdx].props = {
      ...filters[platformTypesIdx].props,
      isDisabled: () => false,
    };
  }

  if (!hideCareReviewTag && !!me?.client_data.care_review_tag_options?.length) {
    filters.unshift({
      name: "care_review_tags",
      type: "comment",
      component: CareReviewTag,
      label: "",
      props: {
        onCareReviewTagChange: setCareReviewTag,
      },
    });
  }

  if (additionalFilters?.length) {
    additionalFilters.forEach(filterName => {
      if (filterName === "campaigns_keyword_query") {
        filters.push({
          name: "campaigns_keyword_query",
          type: "asset",
          component: KeywordsFilter,
          groupLabel: t("components:filters:groups:campaigns_keyword_matching"),
          label: t("pages:workflows:form:triggers:post_tag:campaigns_keyword_query"),
          props: {
            options: [],
            limited: true,
            showTranslationSwitch: false,
            dropdownMatchSelectWidth: true,
            selectedLabel: t("components:filters:specifiedTag"),
          },
        });
      }

      if (filterName === "ad_sets_keyword_query") {
        filters.push({
          name: "ad_sets_keyword_query",
          type: "asset",
          component: KeywordsFilter,
          label: t("pages:workflows:form:triggers:post_tag:ad_sets_keyword_query"),
          props: {
            options: [],
            limited: true,
            showTranslationSwitch: false,
            dropdownMatchSelectWidth: true,
            selectedLabel: t("components:filters:specifiedTag"),
          },
        });
      }

      if (filterName === "regex_keyword") {
        filters.push({
          name: "regex",
          type: "comment",
          component: ExpandableTextInput,
          label: t("components:filters:advanced:regex"),
          props: {},
          groupLabel: t("components:filters:groups:advanced"),
        });
      }

      if (filterName === "ads_keyword_query") {
        filters.push({
          name: "ads_keyword_query",
          type: "asset",
          component: KeywordsFilter,
          label: t("pages:workflows:form:triggers:post_tag:ads_keyword_query"),
          props: {
            options: [],
            limited: true,
            showTranslationSwitch: false,
            dropdownMatchSelectWidth: true,
            selectedLabel: t("components:filters:specifiedTag"),
          },
        });
      }

      if (filterName === "regex") {
        filters.push({
          name: "regex",
          type: "comment",
          component: ExpandableTextInput,
          label: t("components:filters:advanced:regex"),
          props: {},
          groupLabel: t("components:filters:groups:advanced"),
        });
      }
    });
  }

  useEffect(() => {
    if (hideCareReviewTag) return;
    if (!!filterValues?.care_review_tags?.length) setCareReviewTag(true);
    // eslint-disable-next-line
  }, [!!filterValues?.care_review_tags?.length]);

  return {
    careReviewTagChecked: careReviewTag,
    workflowTriggerFilters: filters.filter(({ name }) => !hiddenFilters?.includes(name)),
  };
};

export function filterHasNonEmptyValue(obj: unknown): boolean {
  if (typeof obj !== "object" || obj === null) return false;

  if (Array.isArray(obj)) return obj.some(filterHasNonEmptyValue);

  for (const value of Object.values(obj)) {
    if (
      value !== undefined &&
      value !== "" &&
      !(Array.isArray(value) && value.length === 0) &&
      !(typeof value === "object" && value !== null && Object.keys(value).length === 0)
    ) {
      return true;
    }

    if (typeof value === "object" && value !== null && filterHasNonEmptyValue(value)) {
      return true;
    }
  }

  return false;
}
