import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Button } from "@bbdevcrew/bb_ui_kit_fe";
import { CommentsGridContainer } from "@containers/Comments/Comments";

import { meSelector } from "@store/me/selectors";

import { getInboxRepliesAction } from "@store/comments/actions";

import s from "./Engage.module.less";

import { IProtectCommentsProps } from "../Transparency.types";
import { COMMENTS_LIMIT } from "../Transparency.helpers";
import { BASE_URL_API } from "@utils/paths";
import { IGetInboxRepliesPayload, IPostCommentBody } from "@store/comments/types";

import ArrowUpIcon from "@assets/ArrowUp.svg";

export const getProtectCommentsEndpoint = (is_care_managed?: boolean) => {
  return `${BASE_URL_API}/care/${is_care_managed ? "inbox" : "smart-inbox"}/list`;
};

export const EngageComments = ({ filters, filterToSpecificItem }: IProtectCommentsProps) => {
  const { t } = useTranslation();

  const queryParams = localStorage.getItem("query") || "";

  const me = useSelector(meSelector);

  const fetchReplies = useCallback((body: IPostCommentBody | IGetInboxRepliesPayload) => {
    return getInboxRepliesAction(
      {
        ...body,
        list_type: "replied",
        reply_platform_type: "brandbastion",
        limit: COMMENTS_LIMIT,
        sort: "desc",
      },
      getProtectCommentsEndpoint(me?.client?.is_care_managed),
    );

    // eslint-disable-next-line
  }, []);

  const handleViewAllClick = () => {
    const queryFilters = !!queryParams ? `/${queryParams}&` : "?";
    window.location.href = `${window.location.origin}/care${queryFilters}section=replied`;
  };

  return (
    <>
      <div className={s.bbEngageCommentsHeader}>
        <h5>{t("components:managed:engage:comments:title")}</h5>
        <Button onClick={handleViewAllClick} className={s.bbEngageCommentsHeaderButton}>
          <span>{t("components:managed:protect:comments:viewAll")}</span>
          <ArrowUpIcon />
        </Button>
      </div>
      <div className={s.bbEngageCommentsWrapper}>
        <CommentsGridContainer
          showStarAction
          showHideAction
          showDeleteAction
          showLikeAction
          showDetailsAction
          showLinkedInReactionAction
          showArchiveAction
          filters={filters}
          loadMoreOnScroll={false}
          providedFetchAction={fetchReplies}
          providedFilterAuthorIdAction={authorIds =>
            filterToSpecificItem({ author_ids: authorIds })
          }
        />
      </div>
    </>
  );
};
