import classNames from "classnames/bind";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { FC, useEffect, useRef, useState } from "react";

import { Form } from "antd";
import ReplyAction from "./actions/replyWorkflowAction";
import { ReplyTrigger } from "./triggers/ReplyTrigger";
import WorkflowItemCard from "../item/card/WorkflowItemCard";
import { TagWorkflowTrigger } from "./triggers/tagWorkflowTrigger";
import ZendeskWorkflowAction from "./actions/zendeskWorkflowAction";
import WorkflowFormUserPermissions from "./WorkflowFormUserPermissions";
import { EmailAlertWorkflowAction } from "./actions/emailAlertWorkflowAction";
import {
  FormErrorMessage,
  IFilters,
  InformationIcon,
  InputWhiteBordered,
} from "@bbdevcrew/bb_ui_kit_fe";
import { ArchiveAction, SentimentAction, TagAction, HideAction } from "./actions";
import { AllFiltersWorkflowTrigger } from "./triggers/allFiltersWorkflowTrigger";
import HideWFEmailAlertAdditionalAction from "./actions/hideWorkflowEmailAlertAdditionalAction";
import { EmailAlertWorkflowThreshold } from "./actions/emailAlertWorkflowAction/emailAlertWorkflowThreshold"; // eslint-disable-line max-len
import { KeywordTagTrigger } from "./triggers/KeywordTagTrigger";
import { AssignWorkflowTrigger } from "./triggers/assignWorkflowTrigger";
import { AssignWorkflowAction } from "./actions/assignWorkflowAction";

import { setWorkflowFormDirtyAction } from "@store/workflows/actions";

import s from "./WorkflowForm.module.less";

import { IWorkflowItem, IWorkflowEmailAlertAction } from "@store/workflows/types";
import { IWorkflowFormProps, IWorkflowTriggerActionMapping } from "./WorkflowForm.types";
import { getInitialValues, WORKFLOW_DESCRIPTION_REGEX } from "./WorkflowForm.helpers";
import SanitizedInputValidation from "@components/_common/SanitizedInputValidation";

import { PlayOutlineIcon } from "@assets/index";
import { NotificationsIcon, ChartIcon } from "@bbdevcrew/bb_ui_kit_fe";

const WorkflowForm: FC<IWorkflowFormProps> = ({ item, form, enabled, workflowType, onFinish }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const initialEnabledValue = useRef<boolean>(enabled);
  const isHideWorkflowType = workflowType === "hide";

  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [showFiltersErrorMessage, setShowFiltersErrorMessage] = useState(false);
  const [additionalActions, setAdditionalActions] = useState<JSX.Element[]>([]);

  useEffect(() => {
    form.setFieldsValue({ enabled });
    dispatch(setWorkflowFormDirtyAction(enabled !== initialEnabledValue.current));
  }, [form, enabled, dispatch]);

  useEffect(
    () => () => {
      dispatch(setWorkflowFormDirtyAction(false));
    },
    [dispatch],
  );

  const onRemoveAdditionalAction = (actionIndex: number) => {
    const _additionalActions: IWorkflowEmailAlertAction[] =
      form.getFieldValue("additional_actions") || [];
    form.setFieldsValue({
      additional_actions: _additionalActions.filter((_, index) => index !== actionIndex),
    });
    setAdditionalActions(additionalActions?.filter((_, index) => index !== actionIndex));
  };

  useEffect(() => {
    if (item.id && item.additional_actions?.length) {
      setAdditionalActions([
        <HideWFEmailAlertAdditionalAction
          form={form}
          key="additional_actions"
          additional_actions={additionalActions || []}
          onRemoveAdditionalAction={onRemoveAdditionalAction}
        />,
      ]);
    }
    // eslint-disable-next-line
  }, []);

  const onInternalFinish = (values: IWorkflowItem) => {
    if (!showFiltersErrorMessage) onFinish?.(values);
  };

  const validateFiltersValue = (value: IFilters) => {
    if (JSON.stringify(value) === "{}" || JSON.stringify(value) === '{"exclusion_filters":{}}') {
      setShowFiltersErrorMessage(true);
    } else setShowFiltersErrorMessage(false);
  };

  const workflowTriggerActionMapping: IWorkflowTriggerActionMapping = {
    sentiment: {
      trigger: <KeywordTagTrigger form={form} />,
      action: (
        <Form.Item
          name="action"
          rules={[
            {
              required: true,
              validateTrigger: "onBlur",
              message: (
                <FormErrorMessage>
                  {t("pages:workflows:form:actions:sentiment:required")}
                </FormErrorMessage>
              ),
            },
          ]}
          className={s.bbWorkflowItemTrigger}
        >
          <SentimentAction />
        </Form.Item>
      ),
    },
    tag: {
      trigger: (
        <Form.Item name="trigger" className={s.bbWorkflowItemTrigger}>
          <TagWorkflowTrigger
            form={form}
            hiddenFilters={["data_range_option", "is_post_based"]}
            showFiltersErrorMessage={showFiltersErrorMessage}
            setShowFiltersErrorMessage={setShowFiltersErrorMessage}
            validateFiltersValue={validateFiltersValue}
          />
        </Form.Item>
      ),
      action: (
        <Form.Item
          name="action"
          rules={[
            {
              required: true,
              message: (
                <FormErrorMessage>
                  {t("pages:workflows:form:actions:tag:required")}
                </FormErrorMessage>
              ),
            },
          ]}
          className={s.bbWorkflowItemTrigger}
        >
          <TagAction type="tag" />
        </Form.Item>
      ),
    },
    post_tag: {
      trigger: (
        <Form.Item
          name={["trigger", "filters"]}
          rules={[
            {
              validator: (_, value) => {
                validateFiltersValue(value);
                return Promise.resolve();
              },
            },
          ]}
          className={s.bbWorkflowItemTrigger}
        >
          {showFiltersErrorMessage && (
            <FormErrorMessage>
              {t("pages:workflows:form:triggers:keywords:required")}
            </FormErrorMessage>
          )}
          <AllFiltersWorkflowTrigger
            form={form}
            hideExclusionFilters
            workflowType={workflowType}
            filtersPrefix={
              <div className={s.bbPostTagsFiltersPrefix}>
                <InformationIcon />
                {t("pages:workflows:form:triggers:post_tag:filtersPrefix")}
              </div>
            }
            filtersSuffix={
              <div className={s.bbPostTagsFiltersSuffix}>
                <InformationIcon />
                {t("pages:workflows:form:triggers:post_tag:filtersSuffix")}
              </div>
            }
            hiddenFilters={[
              "data_range_option",
              "sentiments",
              "keyword_query",
              "comment_tags",
              "message_types",
              "languages",
              "visibility_type",
              "review_types",
              "assigned_users",
              "comment_ids",
              "message_statuses",
              "listen_sources",
              "author_followers",
              "author_names",
              "author_ids",
              "is_post_based",
            ]}
            additionalFilters={[
              "campaigns_keyword_query",
              "ad_sets_keyword_query",
              "ads_keyword_query",
            ]}
            hiddenPlatformTypes={["trustpilot", "google_business"]}
          />
        </Form.Item>
      ),
      action: (
        <Form.Item
          name="action"
          rules={[
            {
              required: true,
              message: (
                <FormErrorMessage>
                  {t("pages:workflows:form:actions:tag:required")}
                </FormErrorMessage>
              ),
            },
          ]}
          className={s.bbWorkflowItemTrigger}
        >
          <TagAction type="post_tag" />
        </Form.Item>
      ),
    },
    archive: {
      trigger: <KeywordTagTrigger form={form} />,
      action: <ArchiveAction />,
    },
    email_alert: {
      trigger: (
        <Form.Item
          name={["trigger", "filters"]}
          rules={[
            {
              validator: (_, value) => {
                validateFiltersValue(value);
                return Promise.resolve();
              },
            },
          ]}
          className={s.bbWorkflowItemTrigger}
        >
          {showFiltersErrorMessage && (
            <FormErrorMessage>
              {t("pages:workflows:form:triggers:keywords:required")}
            </FormErrorMessage>
          )}
          <AllFiltersWorkflowTrigger
            form={form}
            workflowType={workflowType}
            hideCareReviewTag={false}
            hiddenFilters={["data_range_option"]}
          />
        </Form.Item>
      ),
      threshold: (
        <Form.Item name="action" className={s.bbWorkflowItemTrigger}>
          <EmailAlertWorkflowThreshold form={form} />
        </Form.Item>
      ),
      action: (
        <Form.Item name="action" className={s.bbWorkflowItemTrigger}>
          <EmailAlertWorkflowAction form={form} />
        </Form.Item>
      ),
    },
    hide: {
      trigger: (
        <Form.Item
          name={["trigger", "filters"]}
          rules={[
            {
              validator: (_, value) => {
                validateFiltersValue(value);
                return Promise.resolve();
              },
            },
          ]}
          className={s.bbWorkflowItemTrigger}
        >
          {showFiltersErrorMessage && (
            <FormErrorMessage>
              {t("pages:workflows:form:triggers:keywords:required")}
            </FormErrorMessage>
          )}
          <AllFiltersWorkflowTrigger
            form={form}
            hiddenFilters={[
              "data_range_option",
              "visibility_type",
              "review_types",
              "assigned_users",
              "comment_ids",
              "message_statuses",
              "listen_sources",
              "author_followers",
              "is_post_based",
            ]}
            workflowType={workflowType}
            hiddenPlatformTypes={["linkedin", "trustpilot", "google_business"]}
          />
        </Form.Item>
      ),
      action: <HideAction form={form} />,
    },
    zendesk: {
      trigger: (
        <Form.Item
          name={["trigger", "filters"]}
          rules={[
            {
              validator: (_, value) => {
                validateFiltersValue(value);
                return Promise.resolve();
              },
            },
          ]}
          className={s.bbWorkflowItemTrigger}
        >
          {showFiltersErrorMessage && (
            <FormErrorMessage>
              {t("pages:workflows:form:triggers:keywords:required")}
            </FormErrorMessage>
          )}
          <AllFiltersWorkflowTrigger
            form={form}
            workflowType={workflowType}
            hiddenFilters={["data_range_option", "is_post_based"]}
            title={t("pages:workflows:form:triggers:zendesk:filterMsg")}
          />
        </Form.Item>
      ),
      action: <ZendeskWorkflowAction form={form} />,
    },
    reply: {
      trigger: <ReplyTrigger form={form} errors={formErrors} item={item} />,
      action: <ReplyAction form={form} item={item} />,
    },
    nlp: {
      trigger: null,
      action: null,
    },
    nlp_tag: {
      trigger: null,
      action: null,
    },
    assign: {
      trigger: <AssignWorkflowTrigger form={form} />,
      action: (
        <Form.Item
          name={["action", "assigned_users"]}
          rules={[
            {
              required: true,
              message: (
                <FormErrorMessage>
                  {t("pages:workflows:form:actions:assign:required")}
                </FormErrorMessage>
              ),
            },
          ]}
          className={s.bbWorkflowItemTrigger}
        >
          <AssignWorkflowAction />
        </Form.Item>
      ),
    },
  };

  const { trigger, action, threshold } = workflowTriggerActionMapping[workflowType] || {};

  const createHideAdditionalActions = () => {
    setAdditionalActions([
      ...additionalActions,
      <HideWFEmailAlertAdditionalAction
        form={form}
        key="additional_actions"
        additional_actions={[]}
        onRemoveAdditionalAction={onRemoveAdditionalAction}
      />,
    ]);
  };

  const hasAdditionalHideAction = isHideWorkflowType && !!additionalActions?.length;

  return (
    <Form
      form={form}
      layout="vertical"
      requiredMark="optional"
      initialValues={getInitialValues(item)}
      onFinish={onInternalFinish}
      className={s.bbWorkflowItemFormWrapper}
      onFieldsChange={(_, fields) => {
        const isFormDirty = fields.some(field => field.touched && field.errors?.length === 0);
        dispatch(setWorkflowFormDirtyAction(isFormDirty));
        setFormErrors(
          fields.filter(field => field.errors?.length).map(field => field.name.join(".")),
        );
      }}
    >
      <div className={s.bbWorkflowItemFormItems}>
        <Form.Item name="id" className={s.bbWorkflowItemFormHidden}>
          <input type="hidden" name="id" />
        </Form.Item>
        <Form.Item name="enabled" className={s.bbWorkflowItemFormHidden}>
          <input type="hidden" name="enabled" />
        </Form.Item>
        <Form.Item name="category" className={s.bbWorkflowItemFormHidden}>
          <input type="hidden" name="category" />
        </Form.Item>
        <Form.Item
          name="title"
          label={t("pages:workflows:form:title:label")}
          rules={[
            {
              required: true,
              validateTrigger: "onBlur",
              message: (
                <FormErrorMessage>{t("pages:workflows:form:title:required")}</FormErrorMessage>
              ),
            },
            {
              max: 60,
              validateTrigger: "onBlur",
              message: <FormErrorMessage>{t("generic:validation:max")}</FormErrorMessage>,
            },
            {
              validator: (_, value) => {
                if (value && !value.trim()) {
                  return Promise.reject(
                    <FormErrorMessage>{t("pages:workflows:form:title:required")}</FormErrorMessage>,
                  );
                }
                return Promise.resolve();
              },
              validateTrigger: "onBlur",
            },
            { ...SanitizedInputValidation },
          ]}
        >
          <InputWhiteBordered data-cy="workflow-form-title" maxLength={60} />
        </Form.Item>
        <Form.Item
          name="description"
          label={t("pages:workflows:form:description:label")}
          rules={[
            {
              max: 300,
              message: <FormErrorMessage>{t("generic:validation:max")}</FormErrorMessage>,
            },
            {
              pattern: WORKFLOW_DESCRIPTION_REGEX,
              message: (
                <div className={s.bbWorkflowItemDescriptionErrorMessage}>
                  <FormErrorMessage>
                    {t("pages:workflows:form:description:pattern")}
                  </FormErrorMessage>
                </div>
              ),
            },
          ]}
        >
          <InputWhiteBordered maxLength={300} />
        </Form.Item>
        <WorkflowFormUserPermissions form={form} workflow={item} />
      </div>
      <WorkflowItemCard title={t("generic:trigger")} icon={NotificationsIcon} pointArrow>
        {trigger}
      </WorkflowItemCard>
      {threshold && (
        <WorkflowItemCard title={t("generic:threshold")} icon={ChartIcon} pointArrow>
          {threshold}
        </WorkflowItemCard>
      )}
      <WorkflowItemCard
        icon={PlayOutlineIcon}
        title={t("generic:action") + (additionalActions?.length ? "1" : "")}
        hasAdditionalAction={hasAdditionalHideAction}
        pointAdditionalAction={isHideWorkflowType && !additionalActions?.length}
        onAdditionalActionClick={createHideAdditionalActions}
      >
        {action}
      </WorkflowItemCard>
      {!!additionalActions?.length && (
        <>
          {additionalActions.map((additionalAction, index) => (
            <WorkflowItemCard
              key={index}
              title={t("generic:action") + (index + 2)}
              icon={PlayOutlineIcon}
              className={classNames({
                [s.bbWorkflowItemCardAdditionalAction]: hasAdditionalHideAction,
              })}
            >
              {additionalAction}
            </WorkflowItemCard>
          ))}
        </>
      )}
    </Form>
  );
};

export default WorkflowForm;
