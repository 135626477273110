import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import s from "./AINLPModal.module.less";

import { IAINLPTaggingModalLeftPanelProps } from "./AINLPModal.types";

import Step1Illustration from "@assets/aiTaggingWorkflow/Step1.svg";
import Step2Illustration from "@assets/aiTaggingWorkflow/Step2.svg";
import Step3Illustration from "@assets/aiTaggingWorkflow/Step3.svg";
import Step4Illustration from "@assets/aiTaggingWorkflow/Step4.svg";
import Step5Illustration from "@assets/aiTaggingWorkflow/Step5.svg";
import Step6Illustration from "@assets/aiTaggingWorkflow/Step6.svg";

const AiBrainstormingIllustration = {
  0: <Step1Illustration />,
  1: <Step2Illustration />,
  2: <Step3Illustration />,
  3: <Step4Illustration />,
  4: <Step5Illustration />,
  5: <Step6Illustration />,
};

const AINLPTaggingModalLeftPanel: FC<IAINLPTaggingModalLeftPanelProps> = ({ currentStep }) => {
  const { t } = useTranslation();
  const leftPanelTransBase = "pages:workflows:workflowCreation:taggingFlow:leftPanel";

  return (
    <div className={s.bbAIWorkflowCreationLeftPanel}>
      {AiBrainstormingIllustration[currentStep as keyof typeof AiBrainstormingIllustration]}
      <div className={s.bbAIWorkflowCreationLeftPanelStep}>
        <div className={s.bbAIWorkflowCreationLeftPanelStepText}>
          {t(`${leftPanelTransBase}:step`)} {currentStep + 1}
        </div>
      </div>
      <div className={s.bbAIWorkflowCreationLeftPanelTitle}>
        {t(`${leftPanelTransBase}:step${currentStep + 1}:title`)}
      </div>
      <div className={s.bbAIWorkflowCreationLeftPanelSubTitle}>
        {t(`${leftPanelTransBase}:step${currentStep + 1}:subTitle`)}
      </div>
    </div>
  );
};

export default AINLPTaggingModalLeftPanel;
