import { ajax } from "rxjs/ajax";
import { EMPTY, mergeMap, map, Observable, of, timer, take } from "rxjs";
import { StateObservable } from "redux-observable";
import { catchError, filter, switchMap, expand, takeWhile } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";

import { RootState } from "../";
import * as actions from "./actions";
import { aiHighlights } from "@utils/paths";
import { handleError } from "@bbdevcrew/bb_ui_kit_fe";
import { getAuthAPIHeaders } from "@utils/headers";
import { IAIHighlightsActions, IAIHighlightsResponse } from "./types";
import { orderObjectProperties } from "@utils/orderObjectProperties";

const MAX_ATTEMPTS = 60;

export const getAiHighlights = (
  action$: Observable<IAIHighlightsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.getAiHighlightsAction)),
    switchMap(({ payload }) => {
      let count = 0;
      const { type, refresh: refreshPayload = false, promptId, filters } = payload;

      const apiUrl = `${aiHighlights}/${type}`;

      const makeRequest = (refresh: boolean) =>
        ajax<IAIHighlightsResponse>({
          url: apiUrl,
          method: "POST",
          headers: getAuthAPIHeaders(state$),
          body: {
            filters: filters
              ? orderObjectProperties(filters as Record<string, unknown>)
              : undefined,
            refresh,
            prompt_id: promptId === "default" ? undefined : promptId,
          },
        });

      return makeRequest(refreshPayload || false).pipe(
        expand(response => {
          if (response.status === 202) {
            return timer(5000).pipe(
              map(() => makeRequest(false)),
              mergeMap(observable => observable),
            );
          }

          return EMPTY;
        }),
        take(MAX_ATTEMPTS),
        takeWhile(response => response.status === 202, true),
        map(response => {
          if (response.status === 200) {
            const data = response.response;
            return actions.getAiHighlightsSuccess({ data, type });
          } else if (response.status === 202) {
            if (count === MAX_ATTEMPTS) {
              return actions.getAiHighlightsFailure({ type });
            }
            count++;
            return EMPTY;
          }
          return actions.getAiHighlightsFailure({ type });
        }),
        catchError(e => {
          if (e.status === 403) {
            return of(actions.getAiHighlightsFailure({ type }));
          }
          return handleError(e, () => actions.getAiHighlightsFailure({ type }));
        }),
      );
    }),
  );
