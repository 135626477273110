import { Observable } from "rxjs";
import { ajax } from "rxjs/ajax";
import { isOfType } from "typesafe-actions";
import { StateObservable } from "redux-observable";
import { catchError, switchMap, filter, map } from "rxjs/operators";

import {
  postWorkflowsAIModelsSuccessAction,
  postWorkflowsAIModelsFailureAction,
  editWorkflowsAIModelsTaskSuccessAction,
  editWorkflowsAIModelsTaskFailureAction,
  createWorkflowsAIModelTaskSuccessAction,
  createWorkflowsAIModelTaskFailureAction,
  deleteWorkflowsAIModelsTaskSuccessAction,
  deleteWorkflowsAIModelsTaskFailureAction,
  createWorkflowAIModelsTaskExamplesFailureAction,
  createWorkflowAIModelsTaskExamplesSuccessAction,
  getWorkflowsAIModelsSuccessAction,
  getWorkflowsAIModelsFailureAction,
  testWorkflowAIModelSuccessAction,
  testWorkflowAIModelFailureAction,
  deleteWorkflowAIModelsTaskExamplesSuccessAction,
  deleteWorkflowAIModelsTaskExamplesFailureAction,
} from "./actions";
import {
  POST_WORKFLOW_AI_MODELS,
  EDIT_WORKFLOW_AI_MODELS_TASK,
  CREATE_WORKFLOW_AI_MODELS_TASK,
  DELETE_WORKFLOW_AI_MODELS_TASK,
  CREATE_WORKFLOW_AI_MODELS_TASK_EXAMPLES,
  GET_WORKFLOW_AI_MODELS,
  EDIT_WORKFLOW_AI_MODELS_TASK_EXAMPLE,
  TEST_WORKFLOW_AI_MODEL,
  DELETE_WORKFLOW_AI_MODELS_TASK_EXAMPLE,
} from "./actionTypes";

import { RootState } from "../index";
import { workflows } from "@utils/paths";

import {
  WorkflowsNLPActions,
  IWorkflowAIModelResponse,
  ICreateWorkflowAIModelTaskResponse,
  ITaskExampleCreationResponse,
  IWorkflowAIModelTestingResponse,
} from "./types";
import { getHeaders } from "@utils/headers";
import { handleError } from "@bbdevcrew/bb_ui_kit_fe";

export const getWorkflowsAIModelsEpic = (
  action$: Observable<WorkflowsNLPActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_WORKFLOW_AI_MODELS)),
    switchMap(a => {
      return ajax
        .get<IWorkflowAIModelResponse>(
          `${workflows}/ai-models/${a.payload}`,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => getWorkflowsAIModelsSuccessAction(data)),
          catchError(e => handleError(e, getWorkflowsAIModelsFailureAction)),
        );
    }),
  );

export const postWorkflowsAIModelsEpic = (
  action$: Observable<WorkflowsNLPActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(POST_WORKFLOW_AI_MODELS)),
    switchMap(a => {
      return ajax
        .post<IWorkflowAIModelResponse>(
          `${workflows}/ai-models`,
          a.payload,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => postWorkflowsAIModelsSuccessAction(data)),
          catchError(e => handleError(e, postWorkflowsAIModelsFailureAction)),
        );
    }),
  );

export const createWorkflowsAIModelTaskEpic = (
  action$: Observable<WorkflowsNLPActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(CREATE_WORKFLOW_AI_MODELS_TASK)),
    switchMap(action => {
      return ajax
        .post<ICreateWorkflowAIModelTaskResponse>(
          `${workflows}/ai-models/${action.payload.workflowId}/tasks`,
          { ...action.payload.payload },
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => createWorkflowsAIModelTaskSuccessAction(data)),
          catchError(e => handleError(e, createWorkflowsAIModelTaskFailureAction)),
        );
    }),
  );

export const updateWorkflowsAIModelsTaskEpic = (
  action$: Observable<WorkflowsNLPActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(EDIT_WORKFLOW_AI_MODELS_TASK)),
    switchMap(action => {
      return ajax
        .patch<IWorkflowAIModelResponse>(
          `${workflows}/ai-models/${action.payload.workflowId}/tasks/${action.payload.taskId}`,
          { ...action.payload.payload },
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => editWorkflowsAIModelsTaskSuccessAction(data)),
          catchError(e => handleError(e, editWorkflowsAIModelsTaskFailureAction)),
        );
    }),
  );

export const deleteWorkflowsAIModelsTaskEpic = (
  action$: Observable<WorkflowsNLPActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(DELETE_WORKFLOW_AI_MODELS_TASK)),
    switchMap(action => {
      const { workflowId, taskId } = action.payload;
      return ajax
        .delete(
          `${workflows}/ai-models/${workflowId}/tasks/${taskId}`,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(() => deleteWorkflowsAIModelsTaskSuccessAction()),
          catchError(e => handleError(e, deleteWorkflowsAIModelsTaskFailureAction)),
        );
    }),
  );

export const createWorkflowAIModelsTaskExamplesEpic = (
  action$: Observable<WorkflowsNLPActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(CREATE_WORKFLOW_AI_MODELS_TASK_EXAMPLES)),
    switchMap(action => {
      const { workflowId, taskId, payload } = action.payload;
      return ajax
        .post<ITaskExampleCreationResponse>(
          `${workflows}/ai-models/${workflowId}/tasks/${taskId}/examples`,
          payload,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => createWorkflowAIModelsTaskExamplesSuccessAction(data)),
          catchError(e => handleError(e, createWorkflowAIModelsTaskExamplesFailureAction)),
        );
    }),
  );

export const updateWorkflowAIModelsTaskExamplesEpic = (
  action$: Observable<WorkflowsNLPActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(EDIT_WORKFLOW_AI_MODELS_TASK_EXAMPLE)),
    switchMap(action => {
      const { workflowId, taskId, exampleId, payload } = action.payload;
      return ajax
        .put<ITaskExampleCreationResponse>(
          `${workflows}/ai-models/${workflowId}/tasks/${taskId}/examples/${exampleId}`,
          payload,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => createWorkflowAIModelsTaskExamplesSuccessAction(data)),
          catchError(e => handleError(e, createWorkflowAIModelsTaskExamplesFailureAction)),
        );
    }),
  );

export const deleteWorkflowAIModelsTaskExamplesEpic = (
  action$: Observable<WorkflowsNLPActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(DELETE_WORKFLOW_AI_MODELS_TASK_EXAMPLE)),
    switchMap(action => {
      const { workflowId, taskId, exampleId } = action.payload;
      return ajax
        .delete<ITaskExampleCreationResponse>(
          `${workflows}/ai-models/${workflowId}/tasks/${taskId}/examples/${exampleId}`,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => deleteWorkflowAIModelsTaskExamplesSuccessAction(data)),
          catchError(e => handleError(e, deleteWorkflowAIModelsTaskExamplesFailureAction)),
        );
    }),
  );

export const testWorkflowAIModelEpic = (
  action$: Observable<WorkflowsNLPActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(TEST_WORKFLOW_AI_MODEL)),
    switchMap(action => {
      const { workflowId, content } = action.payload;
      return ajax
        .post<IWorkflowAIModelTestingResponse>(
          `${workflows}/ai-models/${workflowId}/testing`,
          { content },
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => testWorkflowAIModelSuccessAction(data)),
          catchError(e => handleError(e, testWorkflowAIModelFailureAction)),
        );
    }),
  );
