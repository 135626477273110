import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { resetWorkflowsAction, getWorkflowsAction } from "@store/workflows/actions";
import { workflowEditedSelector, workflowCreatedSelector } from "@store/workflows/selectors";

import { addToast } from "@bbdevcrew/bb_ui_kit_fe";
import { IWorkflowItem } from "@store/workflows/types";
import { WORKFLOW_TOASTS_DURATION } from "@components/control-panel/workflows/WorkflowsContent";

export const useWorkflowModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [workflowModalOpen, setWorkflowModalOpen] = useState(false);
  const [selectedWorkflow, setSelectedWorkflow] = useState<IWorkflowItem>();

  const workflowEdited = useSelector(workflowEditedSelector);
  const workflowCreated = useSelector(workflowCreatedSelector);

  useEffect(() => {
    dispatch(getWorkflowsAction());

    return () => {
      dispatch(resetWorkflowsAction());
    };
  }, [dispatch, workflowEdited, workflowCreated]);

  useEffect(() => {
    if (workflowCreated) {
      setWorkflowModalOpen(false);
      setSelectedWorkflow(undefined);

      addToast({
        type: "success_accent",
        title: t("pages:workflows:workflowCreation:createSuccess"),
        duration: WORKFLOW_TOASTS_DURATION,
      });
    }
  }, [workflowCreated, t]);

  useEffect(() => {
    if (workflowEdited) {
      setWorkflowModalOpen(false);
      setSelectedWorkflow(undefined);

      addToast({
        type: "success_accent",
        title: t("pages:workflows:workflowCreation:editSuccess"),
        duration: WORKFLOW_TOASTS_DURATION,
      });
    }
  }, [workflowEdited, t]);

  const openCreateModal = useCallback(() => {
    setSelectedWorkflow(undefined);
    setWorkflowModalOpen(true);
  }, []);

  const openEditModal = useCallback((workflow: IWorkflowItem) => {
    setSelectedWorkflow(workflow);
    setWorkflowModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setWorkflowModalOpen(false);
    setSelectedWorkflow(undefined);
  }, []);

  return {
    workflowModalOpen,
    selectedWorkflow,
    openCreateModal,
    openEditModal,
    closeModal,
  };
};
