import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import {
  Chip,
  IItemAction,
  ItemCard,
  ModalHeaderActions,
  PlusIcon,
  ScrollView,
  Tooltip,
} from "@bbdevcrew/bb_ui_kit_fe";

import s from "./CustomPrompts.module.less";

import { sortPromptsList } from "./CustomPrompts.helpers";
import { ICustomPromptsListProps } from "./CustomPrompts.types";
import { AIInsightsPromptType } from "@store/aiInsights/types";

import { HelpIcon } from "@bbdevcrew/bb_ui_kit_fe";
import { IAIHighlightPrompt } from "@store/aiHighlightsPrompts/types";

export const CustomPromptsList: React.FC<ICustomPromptsListProps> = ({
  prompts,
  onAddClick,
  onEditClick,
  onDeleteClick,
  onSelectClick,
}) => {
  const { t } = useTranslation();

  const promptActions = useCallback(
    (prompt: IAIHighlightPrompt): IItemAction[] => {
      return !prompt.id.endsWith("-default")
        ? [
            {
              key: "edit",
              label: "generic:edit",
              onActionClick: () => onEditClick(prompt),
            },
            {
              key: "delete",
              label: "generic:delete",
              onActionClick: () => onDeleteClick(prompt),
            },
          ]
        : [];
    },
    [onEditClick, onDeleteClick],
  );

  return (
    <div className={s.bbCustomPromptsList}>
      <ModalHeaderActions
        title={
          <div className={s.bbCustomPromptsListHeaderTitle}>
            {t("components:customPrompts:title")}
            <i
              className={s.bbCustomPromptsListHeaderTitleHelpIcon}
              data-stonly-target="app-AI-Insights__prompts--tooltip"
            >
              <HelpIcon />
            </i>
          </div>
        }
        onAddOptions={Object.values(AIInsightsPromptType).map(promptType => ({
          label: t("components:customPrompts:labels:newPrompt", {
            promptType: t(`components:customPrompts:labels:${promptType}`),
          }),
          onClick() {
            onAddClick(promptType);
          },
          icon: <PlusIcon />,
        }))}
        hideSearch
        className={s.bbCustomPromptsListHeader}
      />
      <ScrollView className={s.bbCustomPromptsListScrollView}>
        <>
          {prompts
            .slice()
            .sort(sortPromptsList)
            .map(prompt => {
              const { id, name } = prompt;

              return (
                <Tooltip key={id} title={t("components:customPrompts:tooltips:generateInsights")}>
                  <div>
                    <ItemCard
                      name={
                        <div className={s.bbCustomPromptsListName}>
                          {name}
                          {prompt.default_for?.map(promptType => (
                            <Chip
                              _type="primary"
                              _size="xxs"
                              key={promptType}
                              text={t("components:customPrompts:labels:defaultChip", {
                                promptType: t(`components:customPrompts:labels:${promptType}`),
                              })}
                            />
                          ))}
                        </div>
                      }
                      onItemClick={() => onSelectClick(prompt)}
                      actions={promptActions(prompt)}
                    />
                  </div>
                </Tooltip>
              );
            })}
        </>
      </ScrollView>
    </div>
  );
};

export default CustomPromptsList;
