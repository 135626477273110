export const GET_COMPANY_SETTINGS = "SETTINGS/GET_COMPANY_SETTINGS";
export const GET_COMPANY_SETTINGS_SUCCESS = "SETTINGS/GET_COMPANY_SETTINGS_SUCCESS";
export const GET_COMPANY_SETTINGS_FAILURE = "SETTINGS/GET_COMPANY_SETTINGS_FAILURE";

export const UPDATE_FEATURE_VISIBILITY_FLAGS = "SETTINGS/UPDATE_FEATURE_VISIBILITY_FLAGS";
export const UPDATE_FEATURE_VISIBILITY_FLAGS_SUCCESS =
  "SETTINGS/UPDATE_FEATURE_VISIBILITY_FLAGS_SUCCESS";
export const UPDATE_FEATURE_VISIBILITY_FLAGS_FAILURE =
  "SETTINGS/UPDATE_FEATURE_VISIBILITY_FLAGS_FAILURE";

export const UPDATE_INTEGRATIONS_FLAGS = "SETTINGS/UPDATE_INTEGRATIONS_FLAGS";
export const UPDATE_INTEGRATIONS_FLAGS_SUCCESS = "SETTINGS/UPDATE_INTEGRATIONS_FLAGS_SUCCESS";
export const UPDATE_INTEGRATIONS_FLAGS_FAILURE = "SETTINGS/UPDATE_INTEGRATIONS_FLAGS_FAILURE";

export const UPDATE_NUMERIC_FLAGS = "SETTINGS/UPDATE_NUMERIC_FLAGS";
export const UPDATE_NUMERIC_FLAGS_SUCCESS = "SETTINGS/UPDATE_NUMERIC_FLAGS_SUCCESS";
export const UPDATE_NUMERIC_FLAGS_FAILURE = "SETTINGS/UPDATE_NUMERIC_FLAGS_FAILURE";

export const RESET_UPDATED_DYNAMIC_FLAGS = "SETTINGS/RESET_UPDATED_DYNAMIC_FLAGS";
