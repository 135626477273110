import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import { Button, Tooltip, TagsDropdown } from "@bbdevcrew/bb_ui_kit_fe";

import {
  getPostTagsAction,
  createPostTagAction,
  deletePostTagAction,
} from "@store/postTags/actions";
import {
  postTagsSelector,
  addingPostTagSelector,
  fetchingPostTagsSelector,
  addPostTagSuccessfulSelector,
  getPostTagsSuccessfulSelector,
} from "@store/postTags/selectors";

import s from "./PostTagsDropdown.module.less";

import { IPostTag } from "@bbdevcrew/bb_ui_kit_fe";
import { IPostTagsDropdownProps } from "./PostTags.type";
import { ICreatePostTagPayload } from "@store/postTags/types";

import { PlusIcon } from "@bbdevcrew/bb_ui_kit_fe";

const PostTagsDropdown: FC<IPostTagsDropdownProps> = ({
  postId,
  postTags = [],
  trigger: TriggerComponent,
  onSelectTag,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const initialPostTags = useSelector(postTagsSelector);
  const postTagsFetching = useSelector(fetchingPostTagsSelector);
  const postTagsFetched = useSelector(getPostTagsSuccessfulSelector);
  const postTagCreating = useSelector(addingPostTagSelector);
  const postTagCreated = useSelector(addPostTagSuccessfulSelector);

  const fetchPostTags = useCallback(() => dispatch(getPostTagsAction()), [dispatch]);
  const addPostTag = useCallback(
    (data: ICreatePostTagPayload) => dispatch(createPostTagAction(data)),
    [dispatch],
  );
  const deleteTagFromDropdown = useCallback(
    (id: string) => dispatch(deletePostTagAction(id)),
    [dispatch],
  );

  const _onSelectTag = (tag: IPostTag) => {
    onSelectTag?.(tag);
    postId &&
      addPostTag({
        label: tag.label,
        post_id: postId,
        color: tag.color,
      });
  };

  const onAddNewTag = (tagLabel: string) => {
    onSelectTag?.({ label: tagLabel });
    addPostTag({
      label: tagLabel,
      post_id: postId,
    });
  };

  const getTriggerButton = () => {
    return (
      <Tooltip title={t("components:postTags:addTitle")}>
        {TriggerComponent ? (
          TriggerComponent
        ) : (
          <Button _size="sm" _type="secondary" className={s.bbTriggerBtn}>
            <PlusIcon />
          </Button>
        )}
      </Tooltip>
    );
  };

  const tagsState = {
    tagsLoading: postTagsFetching,
    tagsLoaded: postTagsFetched,
    tagCreating: postTagCreating,
    tagCreated: postTagCreated,
  };

  return (
    <TagsDropdown
      type="postTags"
      tags={postTags}
      tagsStateData={tagsState}
      initialTags={initialPostTags}
      onCreateTag={onAddNewTag}
      onSelectTag={_onSelectTag}
      fetchTags={fetchPostTags}
      trigger={getTriggerButton()}
      deleteTagFromDropdown={deleteTagFromDropdown}
    />
  );
};

export default PostTagsDropdown;
