import React from "react";
import cn from "classnames";

import { Tooltip } from "@bbdevcrew/bb_ui_kit_fe";

import { IStepProps } from "./Stepper.types";

import s from "./Step.module.less";

const cx = cn.bind(s);

export const Step: React.FC<IStepProps> = ({
  id,
  size,
  type,
  title,
  content,
  hasTail,
  tooltip,
  config,
  status,
  onClick,
}) => {
  const { color, borderColor, backgroundColor, tailColors, titleColor } = config;

  return (
    <Tooltip title={onClick ? tooltip : ""} placement="top">
      <div className={cx(s.bbStep, s[`bbStep-${size}`], s[`bbStep-${type}`])}>
        <div
          style={{
            backgroundColor,
            borderColor,
            color,
          }}
          className={cx(s.bbStepContent, {
            [s.bbStepContentClickable]: !!onClick,
          })}
          onClick={() => onClick?.(id)}
        >
          {type !== "simple" && content}
          {hasTail && (
            <div className={s.bbStepContentTail}>
              {tailColors.map(tailColor => (
                <div
                  key={tailColor}
                  style={{
                    backgroundColor: tailColor,
                    width: `${100 / tailColors.length}%`,
                  }}
                  className={s.bbStepContentTailPart}
                ></div>
              ))}
            </div>
          )}
        </div>

        {title && (
          <div
            style={{ color: titleColor }}
            className={cn(s.bbStepTitle, {
              [s.bbStepTitleBold]: status === "process",
              [s.bbStepTitleClickable]: !!onClick,
            })}
            onClick={() => onClick?.(id)}
          >
            {title}
          </div>
        )}
      </div>
    </Tooltip>
  );
};
