import React from "react";
import { i18nextInstance } from "../../../App";

import { AiBadge } from "@bbdevcrew/bb_ui_kit_fe";

import { GetInboxOptionsType } from "./menu/types";
import { HideMeSelectorType, IInboxTypeItem, ISmartInboxType } from "./types";

import {
  CheckIcon,
  StarIcon,
  ReplyIcon,
  EyeOffIcon,
  HourGlassIcon,
  InboxIcon,
  ArchiveIcon,
  AlertTriangleIcon,
  CommentIcon,
  ProcessorIcon,
  SafetyIcon,
  UserPlusIcon,
} from "@bbdevcrew/bb_ui_kit_fe";

export const getSortOptions = () => [
  {
    id: "desc|ai_highlights_score",
    label: i18nextInstance.t("components:inbox:sort:higherIntent"),
  },
  {
    id: "asc|ai_highlights_score",
    label: i18nextInstance.t("components:inbox:sort:lowerIntent"),
  },
  {
    id: "desc|created_time",
    label: i18nextInstance.t("components:inbox:sort:newest"),
  },
  {
    id: "asc|created_time",
    label: i18nextInstance.t("components:inbox:sort:oldest"),
  },
];

export const InboxTypeIconMap: { [key in ISmartInboxType]?: string } = {
  all: InboxIcon,
  review: HourGlassIcon,
  starred: StarIcon,
  done: CheckIcon,
  replied: ReplyIcon,
  ignored: ArchiveIcon,
  hidden: EyeOffIcon,
  ai_highlights: ProcessorIcon,
  assigned_to_me: UserPlusIcon,
  errors: AlertTriangleIcon,
  ai_response_needed: CommentIcon,
  ai_potentially_harmful: SafetyIcon,
};

export const isSafetyFullyManaged: HideMeSelectorType = me => !!me?.client?.is_moderation_managed;

export const getSmartInboxOptions: GetInboxOptionsType = (
  getOptions = key => ({
    key,
  }),
) => {
  return [
    {
      ...getOptions("all"),
    },
    {
      ...getOptions("review"),
      children: [
        {
          ...getOptions("assigned_to_me"),
          showUnread: true,
        },
        {
          ...getOptions("ai_response_needed"),
          label: (
            <>
              {i18nextInstance.t("components:inboxMenu:tabs:ai_response_neededLabel")} <AiBadge />
            </>
          ),
        },
        {
          ...getOptions("ai_potentially_harmful"),
          label: (
            <>
              {i18nextInstance.t("components:inboxMenu:tabs:ai_potentially_harmfulLabel")}{" "}
              <AiBadge />
            </>
          ),
          hide: isSafetyFullyManaged,
        },
      ],
    },
    {
      ...getOptions("starred"),
    },
    {
      ...getOptions("done"),
      children: [
        {
          ...getOptions("replied"),
        },
        {
          ...getOptions("ignored"),
          children: [
            {
              ...getOptions("hidden"),
            },
          ],
        },
      ],
    },
  ];
};

export const getSmartInboxErrorOptions: GetInboxOptionsType = (
  getOptions = key => ({
    key,
  }),
) => {
  return [
    {
      ...getOptions("errors"),
    },
  ];
};

export function getParentKeys(
  nodes: IInboxTypeItem[],
  targetKey: string,
  parents: string[] = [],
): string[] | null {
  for (const node of nodes) {
    if (node.key === targetKey) {
      return parents;
    }
    if (node.children) {
      const result = getParentKeys(node.children, targetKey, [...parents, node.key]);
      if (result) {
        return result;
      }
    }
  }
  return null;
}
