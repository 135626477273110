import { Observable } from "rxjs";
import { ajax } from "rxjs/ajax";
import { isActionOf } from "typesafe-actions";
import { StateObservable } from "redux-observable";
import { switchMap, map, filter, catchError } from "rxjs/operators";

import { RootState } from "..";
import * as actions from "./actions";
import { AIHighlightPromptsAction, IAIHighlightPrompt, IAIHighlightPromptResponse } from "./types";
import { getAuthAPIHeaders } from "@utils/headers";
import { handleError } from "@bbdevcrew/bb_ui_kit_fe";
import { aiHighlightsPrompts } from "@utils/paths";

export const getPromptsEpic = (
  action$: Observable<AIHighlightPromptsAction>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.getPromptsAction)),
    switchMap(() => {
      return ajax.get(aiHighlightsPrompts, getAuthAPIHeaders(state$)).pipe(
        map(e => e.response),
        map(data => actions.getPromptsSuccessAction(data as IAIHighlightPromptResponse)),
        catchError(e => handleError(e, actions.getPromptsFailureAction)),
      );
    }),
  );

export const getPromptByIdEpic = (
  action$: Observable<AIHighlightPromptsAction>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.getPromptByIdAction)),
    switchMap(action => {
      return ajax.get(`${aiHighlightsPrompts}/${action.payload}`, getAuthAPIHeaders(state$)).pipe(
        map(e => e.response),
        map(data => actions.getPromptByIdSuccessAction(data as IAIHighlightPrompt)),
        catchError(e => handleError(e, actions.getPromptByIdFailureAction)),
      );
    }),
  );

export const createPromptEpic = (
  action$: Observable<AIHighlightPromptsAction>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.createPromptAction)),
    switchMap(action => {
      return ajax
        .post<{ id: string }>(aiHighlightsPrompts, action.payload, getAuthAPIHeaders(state$))
        .pipe(
          map(e => e.response),
          map(() => actions.createPromptSuccessAction()),
          catchError(e => handleError(e, actions.createPromptFailureAction)),
        );
    }),
  );

export const updatePromptEpic = (
  action$: Observable<AIHighlightPromptsAction>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.updatePromptAction)),
    switchMap(action => {
      const { id, ...body } = action.payload;
      return ajax.patch(`${aiHighlightsPrompts}/${id}`, body, getAuthAPIHeaders(state$)).pipe(
        map(() => {
          const updatedPrompt: IAIHighlightPrompt = {
            ...action.payload,
            task: undefined,
          };
          return actions.updatePromptSuccessAction(updatedPrompt);
        }),
        catchError(e => handleError(e, actions.updatePromptFailureAction)),
      );
    }),
  );

export const deletePromptEpic = (
  action$: Observable<AIHighlightPromptsAction>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.deletePromptAction)),
    switchMap(action => {
      return ajax
        .delete(`${aiHighlightsPrompts}/${action.payload}`, getAuthAPIHeaders(state$))
        .pipe(
          map(() => actions.deletePromptSuccessAction(action.payload)),
          catchError(e => handleError(e, actions.deletePromptFailureAction)),
        );
    }),
  );
