import { RootState } from "..";
import { HighlightTypeEnum } from "./types";

export const fromAiHighlights = (state: RootState) => state.aiHighlights;

export const aiHighlightsDataSelector = (type: HighlightTypeEnum) => (state: RootState) =>
  fromAiHighlights(state).data[type];

export const aiHighlightsFetchingSelector = (type: HighlightTypeEnum) => (state: RootState) =>
  fromAiHighlights(state).getAiHighlights[type].fetching;

export const aiHighlightsFetchedSelector = (type: HighlightTypeEnum) => (state: RootState) =>
  fromAiHighlights(state).getAiHighlights[type].fetched;

export const aiHighlightsFetchFailSelector = (type: HighlightTypeEnum) => (state: RootState) =>
  fromAiHighlights(state).getAiHighlights[type].fetchFail;

export const aiHighlightsLimitSelector = (type: HighlightTypeEnum) => (state: RootState) =>
  fromAiHighlights(state).data[type]?.limit;

export const aiHighlightsConsumedSelector = (type: HighlightTypeEnum) => (state: RootState) =>
  fromAiHighlights(state).data[type]?.consumed;

export const aiHighlightsStartDateSelector = (type: HighlightTypeEnum) => (state: RootState) =>
  fromAiHighlights(state).data[type]?.start_date;

export const aiHighlightsEndDateSelector = (type: HighlightTypeEnum) => (state: RootState) =>
  fromAiHighlights(state).data[type]?.end_date;

export const aiHighlightsMessageSelector = (type: HighlightTypeEnum) => (state: RootState) =>
  fromAiHighlights(state).data[type]?.message;

export const aiHighlightsLastUpdatedSelector = (type: HighlightTypeEnum) => (state: RootState) =>
  fromAiHighlights(state).data[type]?.last_updated;

export const aiHighlightsInitialFetchDoneSelector =
  (type: HighlightTypeEnum) => (state: RootState) =>
    fromAiHighlights(state).initialFetchDone[type];

export const aiHighlightsWidgetStateSelector = (type: HighlightTypeEnum) => (state: RootState) =>
  fromAiHighlights(state).widgetState[type];
