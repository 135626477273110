import React from "react";

import { IOnboardingIndustry } from "@store/me/types";
import { IConfettiProps } from "../Confetti/Confetti";

import { CalendarIcon, CommentIcon, WorkflowIcon, ChartIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const getIndustryOptions = (industries: IOnboardingIndustry[]) => {
  return industries.map(industry => {
    return {
      label: industry.name,
      id: industry.id,
    };
  });
};

export const getCategoryOptions = (industries: IOnboardingIndustry[], industryValue: string) => {
  const selectedIndustry = industries.find(industry => industry.id === industryValue);
  return selectedIndustry
    ? selectedIndustry.categories.map(category => {
        return {
          label: category.name,
          id: category.id,
        };
      })
    : [];
};

export const HIDING_CONFIGS = ["spam", "offensive", "against_brand", "inappropriate"];

export const LAST_STEP_LINKS = [
  {
    name: "analyze",
    icon: <ChartIcon />,
    link: "insights/?section=listen-overview",
  },
  {
    name: "engage",
    icon: <CommentIcon />,
    link: "care/?section=care_highlights",
  },
  {
    name: "publish",
    icon: <CalendarIcon />,
    link: "publish/calendar",
  },
  {
    name: "workflows",
    icon: <WorkflowIcon />,
    link: "control_panel/?section=control_panel-workflows",
  },
];

export const DEFAULT_CONFETTI_CONFIG: IConfettiProps = {
  colors: ["#ECFCFF", "#B2FCFF", "#5EDFFF", "#3E64FF"],
  numberOfPieces: 600,
  width: 270,
  gravity: 0.06,
  initialVelocityX: 5,
  initialVelocityY: -5,
  drawShape: ctx => {
    ctx.fillRect(-5, -3, 10, 6);
  },
};
